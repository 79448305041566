import React from "react";
import { Container, Button } from "shards-react";
import NoticePage from '../components/common/NoticePage';

const Reports = () => (
    <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
            <div className="error__content">
                <h2>401</h2>
                <h3>Future Feature</h3>
                <p className='larger'>This page is not available for prototype</p>
            </div>
        </div>
    </Container>
);

export default NoticePage;
