import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormSelect,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormInput,
    FormTextarea,
    DatePicker,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { colors } from '../../utils/colors';
import { fetchPost } from '../../utils/api';
import { fetch_agents } from '../../store/action';
import * as moment from 'moment-timezone';

class ManualClosed extends React.Component {
    state = {
        agent: '',
        state: '',
        user: null,
        agents: [],
        appointmentDate: null,
        serviceArea: '',
        sponsorArea: '',
        notes: [],
        areas: [],
        rate: '',
        serial: '',
        serviceRate: '',
        additionalLine: '',
        description: '',
        options: [],
        job: null
    }

    componentDidMount() {
        this.props.fetch_agents()
        this.fetchAgentOptions()
        this.setState({
            job: this.props.job || {},
            user: this.props.user
        })
    }

    static getDerivedStateFromProps(props) {
        return {
            agents: props.agents || []
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.job && this.props.job !== prevProps.job) {
            this.setState({
                job: this.props.job
            })
        }
    }

    fetchAgentOptions() {
        var agents = []
        var serviceAreas = {}
        const areas = this.props.job ? this.props.job['areas'] : []
        this.state.agents.forEach(agent => {
            var agentServiceAreas = []
            if (!agent['deleted']) {
                var inArea = false
                agent['areas'].forEach(agentInArea => {
                    if (agentInArea['sponsor'].toUpperCase() === areas[0].sponsor.toUpperCase()) {
                        inArea = true
                    }
                })
                if (inArea) {
                    agents.push({
                        label: `${agent['full_name']}`,
                        value: agent['uuid']
                    })
                    agent['areas'].forEach(agentInArea => {
                        if (areas[0].sponsor.toUpperCase() === agentInArea['sponsor'].toUpperCase()) {
                            agentServiceAreas.push({
                                code: agentInArea['code'],
                                label: `${agentInArea['code'].toUpperCase()} ${agentInArea['description']}`,
                                value: JSON.stringify({ uuid: agent['uuid'], rate: agentInArea['rate_code'], amount: agentInArea['amount'], code: agentInArea['code'], description: agentInArea['description'] })
                            })
                        }
                    })
                    serviceAreas[agent['uuid']] = agentServiceAreas
                }
            }
        })

        if (agents.length === 0) {
            agents.push({ label: 'None Available', value: null })
        }

        this.setState({
            options: agents,
            areas: serviceAreas
        })
    }

    onAddNote() {
        fetchPost(`/jobs/add_note/${this.props.job.job_no}`,
            {
                "note": 'Call completed outside of the system, S4Biz system platform used to Invoice during transition period',
                "user": this.state.user.uuid,
                "agent": null
            },
            (response) => {
            },
            (response) => {
            }
        )
    }

    handleStartDateChange(value) {
        this.setState({
            startDate: value
        });
    }

    handleStartTimeChange(value) {
        this.setState({
            startTime: moment(this.state.startDate).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format()
        });
    }

    renderAgentOptions() {
        return this.state.options.map((agent, i) => {
            const value = agent['value']
            const label = agent['label']

            return <option key={i} value={value}>{label}</option>
        })
    }

    renderAgentAreaOptions() {
        var agent = this.props.job ? this.props.job['agent'] : []
        var serviceArea = this.props.job ? this.props.job['service_area'] : []
        if (agent && typeof agent === 'object') {
            agent = agent['uuid']
        }
        if (this.state.agent) {
            agent = this.state.agent
        }
        if (this.state.areas && agent && this.state.areas[agent]) {
            const areas = this.state.areas[agent].map((area, i) => {
                const value = area['value']
                const label = area['label']
                return <option key={i} value={value}>{label}</option>
            })

            if (serviceArea) {
                this.state.areas[agent].forEach((area, i) => {
                    const sa = JSON.parse(area.value).code
                    if (sa === serviceArea) {
                        areas.unshift(<option key={i} value={area['value']}>{area['label']}</option>)
                    }
                })
            } else {
                areas.unshift(<option value=''>---</option>)
            }

            return areas
        }
        return <option value=''>---</option>
    }

    submit() {
        const data = this.props.job
        if ((!this.state.agent && !data['agent'])) {
            this.setState({
                warn: true,
                message: 'Select an agent'
            })
        } else if (!this.state.serviceArea && !data['service_area']) {
            this.setState({
                warn: true,
                message: 'Select a service area'
            })
        } else if (this.state.state === 'in_hand_by_agent' && !this.state.startTime) {
            this.setState({
                warn: true,
                message: 'Changing the state to "In Hand" requires an appointment date. Please enter an appointment date and time'
            })
        } else if (this.state.additionalLine === 'spaces' || !this.state.additionalLine) {
            this.setState({
                warn: true,
                message: 'To complete this call you must select yes or no for Additional Line Items'
            })
        } else if (!this.state.description) {
            this.setState({
                warn: true,
                message: 'To complete this call you must add a Job Completion Description'
            })
        } else if (this.state.startDate && !this.state.startTime) {
            this.setState({
                warn: true,
                message: 'Select a date AND time'
            })
        } else {
            this.setState({ warn: true, message: 'Updating...' })
            var api = `/jobs/close/${data['uuid']}`
            var agent = this.state.agent || null
            if (data['agent'] && typeof data['agent'] === 'object') {
                agent = data['agent']['uuid']
            }

            var rate = this.state.rate || null
            if (data['rate'] && typeof data['rate'] === 'object') {
                rate = data['rate']['uuid']
            }

            var oldAppointment = data['appointment_at']

            fetchPost(api,
                {
                    agent: agent,
                    rate: rate,
                    "appointment": this.state.startTime,
                    "old_appointment": oldAppointment,
                    "contact_mobile": data['customer']['contact_mobile'] || data['customer']['contact_home'] || data['customer']['contact_work'],
                    "job_no": data['job_no'],
                    "job_type": data['job_type'],
                    "state_from": data['state'],
                    "original_job_no": this.state.original_job_no || data['original_job_no'],
                    "serial": this.state.serial || data['serial_no'],
                    "service_area": this.state.serviceArea || data['service_area'],
                    "additional_line_item": this.state.additionalLine,
                    "completion_description": this.state.description
                },
                (response) => {
                    this.setState({ message: 'Successfully updated', completed: true })
                },
                (response) => {
                    this.setState({ message: response.message, loading: false })
                }
            )
        }
    }

    render() {
        const data = this.props.job || {}

        var agentUUID = ''
        if (data && data['agent'] && data['agent']['uuid']) {
            agentUUID = data['agent']['uuid']
        }

        var selectedArea = ''
        if (this.state.areas && this.state.areas.length > 0 && data['service_area']) {
            selectedArea = this.state.areas.find(option => {
                return option['code'] === data['service_area']
            })
            selectedArea = selectedArea.value
        }

        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }

        var appointmentTime = data['appointment_at'] ? moment(data['appointment_at']).format('HH:mm') : ''
        if (this.state.startTime) {
            appointmentTime = moment(this.state.startTime).format('HH:mm')
        }

        const isRepeatCall = data['job_type'] === 'repeat_call'

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>Manually Close Job No.{data['job_no']}</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        this.submit()
                                                    }}
                                                >
                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='called_at'>Agent</label>
                                                            <FormSelect
                                                                id='agent1'
                                                                value={this.state.agent || agentUUID}
                                                                required
                                                                onChange={(e) => {
                                                                    const update = {
                                                                        agent: e.target.value,
                                                                    }
                                                                    this.setState(update)
                                                                }}
                                                            >
                                                                <option value=''>---</option>
                                                                {this.renderAgentOptions()}
                                                            </FormSelect>
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='called_at'>Service Area</label>
                                                            <FormSelect
                                                                id='agent'
                                                                value={this.state.selected || selectedArea}
                                                                required
                                                                onChange={(e) => {
                                                                    const value = JSON.parse(e.target.value)
                                                                    if (value) {
                                                                        const update = {
                                                                            rate: value.rate,
                                                                            selected: e.target.value,
                                                                            serviceRate: `${value.rate.toUpperCase()}: R ${value.amount}`,
                                                                            serviceArea: value.code,
                                                                            sponsorArea: `${value.code} - ${value.description}`
                                                                        }

                                                                        if (data['state'] === 'allocated_to_asc' && !this.state.state && !data['agent']) {
                                                                            update['state'] = 'in_hand_by_agent'
                                                                        }
                                                                        this.setState(update)
                                                                    }
                                                                }}
                                                            >
                                                                {this.renderAgentAreaOptions()}
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='appointment_at'>Appointment Date</label>
                                                            <InputGroup className='d-flex my-auto date-range'>
                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    required
                                                                    selected={this.state.appointmentDate}
                                                                    minDate={new Date()}
                                                                    onChange={(value) => this.handleStartDateChange(value)}
                                                                    placeholderText={this.state.startDate || 'Appointment Date'}
                                                                    dropdownMode='select'
                                                                    value={appointmentDate}
                                                                    className='text-center'
                                                                />
                                                                <InputGroupAddon type='append'>
                                                                    <InputGroupText>
                                                                        <i className='material-icons'>&#xE916;</i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='appointment_at'>Appointment Time</label>
                                                            <InputGroup className='d-flex my-auto date-range'>
                                                                <DatePicker
                                                                    timeFormat='HH:mm'
                                                                    required
                                                                    selected={this.state.appointmentDate}
                                                                    onChange={(value) => this.handleStartTimeChange(value)}
                                                                    placeholderText={this.state.startTime || 'Appointment Time'}
                                                                    dropdownMode='select'
                                                                    timeIntervals={30}
                                                                    minTime={new Date().setHours(7, 0, 0)}
                                                                    maxTime={new Date().setHours(19, 0, 0)}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    disabled={!this.state.startDate}
                                                                    value={appointmentTime}
                                                                    className='text-center'
                                                                />
                                                                <InputGroupAddon type='append'>
                                                                    <InputGroupText>
                                                                        <i className='material-icons'>&#xE916;</i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Additional Line Items</label>
                                                            <FormSelect
                                                                id='additional_line_item'
                                                                required
                                                                value={this.state.additionalLine || data['additional_line_item']}
                                                                onChange={(e) => this.setState({ additionalLine: e.target.value })}
                                                            >
                                                                <option value='spaces'>Spaces</option>
                                                                <option value='no'>No</option>
                                                                <option value='yes'>Yes</option>
                                                            </FormSelect>
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Original Job No.</label>
                                                            <FormInput
                                                                placeholder={isRepeatCall ? 'Enter Job No if repeater' : 'Not repeat call'}
                                                                required={isRepeatCall}
                                                                disabled={!isRepeatCall}
                                                                value={this.state.original_job_no}
                                                                onChange={(e) => this.setState({
                                                                    original_job_no: e.target.value
                                                                })}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='feAddress'>Serial No.</label>
                                                            <FormInput
                                                                id='model'
                                                                required
                                                                placeholder='Enter serial no'
                                                                maxlength={20}
                                                                pattern="^[a-zA-Z0-9]{1,20}$"
                                                                title="No more than 20 alphanumeric characters"
                                                                value={this.state.serial || data['serial_no'] || ''}
                                                                onChange={(e) => this.setState({
                                                                    serial: e.target.value
                                                                })}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='notes'>Job Completion Description</label>
                                                            <FormTextarea id='notes' required placeholder='Add Completion Description' value={this.state.description} rows='4' onChange={(e) => this.setState({ description: e.target.value })} />
                                                        </Col>
                                                    </Row>

                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: this.state.completed ? colors.GREEN : this.state.warn ? colors.ORANGE : colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type={this.state.completed ? 'button' : 'submit'}
                                                        onClick={() => {
                                                            if (this.state.completed) {
                                                                this.props.onComplete()
                                                            }
                                                        }}
                                                    >
                                                        {this.state.completed ? 'Done' : 'Manually Close'}
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        agents: state.agents || []
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_agents: () => dispatch(fetch_agents())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualClosed);
