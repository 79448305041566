import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormSelect,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    DatePicker,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { colors } from '../../utils/colors';
import { fetchPost } from '../../utils/api';
import * as moment from 'moment-timezone';

class MultiUpdatePopup extends React.Component {
    state = {
        agent: '',
        state: '',
        agents: [],
        areas: [],
        appointmentDate: null,
        serviceRate: '',
        options: []
    }

    componentDidMount() {
        this.fetchAgentOptions()
    }

    static getDerivedStateFromProps(props) {
        return {
            agents: props.agents || []
        }
    }

    fetchAgentOptions() {
        var agents = []
        var serviceAreas = {}
        const areas = this.props.selected ? this.props.selected[0]['areas'] : []

        this.state.agents.forEach(agent => {
            var agentServiceAreas = []
            if (!agent['deleted']) {
                var inArea = false
                agent['areas'].forEach(agentInArea => {
                    if (agentInArea && agentInArea['sponsor'].toUpperCase() === areas[0].sponsor.toUpperCase()) {
                        inArea = true
                    }
                })
                if (inArea) {
                    agents.push({
                        label: `${agent['full_name']}`,
                        value: agent['uuid']
                    })
                    agent['areas'].forEach(agentInArea => {
                        if (areas[0].sponsor.toUpperCase() === agentInArea['sponsor'].toUpperCase()) {
                            agentServiceAreas.push({
                                code: agentInArea['code'],
                                label: `${agentInArea['code'].toUpperCase()} ${agentInArea['description']}`,
                                value: JSON.stringify({ uuid: agent['uuid'], rate: agentInArea['rate_code'], amount: agentInArea['amount'], code: agentInArea['code'], description: agentInArea['description'] })
                            })
                        }
                    })
                    serviceAreas[agent['uuid']] = agentServiceAreas
                }
            }
        })

        if (agents.length === 0) {
            agents.push({ label: 'None Available', value: null })
        }

        this.setState({
            options: agents,
            areas: serviceAreas
        })
    }

    handleStartDateChange(value) {
        this.setState({
            startDate: value
        });
    }

    handleStartTimeChange(value) {
        this.setState({
            startTime: moment(this.state.startDate).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format()
        });
    }

    renderAgentOptions() {
        return this.state.options.map((agent, i) => {
            const value = agent['value']
            const label = agent['label']

            return <option key={i} value={value}>{label}</option>
        })
    }

    renderAgentAreaOptions() {
        var agent = this.props.data ? this.props.data['agent'] : []
        var serviceArea = this.props.data ? this.props.data['service_area'] : []
        if (agent && typeof agent === 'object') {
            agent = agent['uuid']
        }
        if (this.state.agent) {
            agent = this.state.agent
        }
        if (this.state.areas && agent && this.state.areas[agent]) {
            const areas = this.state.areas[agent].map((area, i) => {
                const value = area['value']
                const label = area['label']
                return <option key={i} value={value}>{label}</option>
            })

            if (serviceArea) {
                this.state.areas[agent].forEach((area, i) => {
                    const sa = JSON.parse(area.value).code
                    if (sa === serviceArea) {
                        areas.unshift(<option key={i} value={area['value']}>{area['label']}</option>)
                    }
                })
            } else {
                areas.unshift(<option value=''>---</option>)
            }

            return areas
        }
        return <option value=''>---</option>
    }

    submit() {
        const selected = this.props.selected
        if (this.props.selected && this.props.selected.length > 0) {
            var hasCompleted = selected.filter(option => option['state_from'] === 'completed')
            if (hasCompleted && hasCompleted.length > 0) {
                this.setState({
                    warn: true,
                    message: 'Cannot update a complete call'
                })
            } else {
                var jobs = selected.map(job => {
                    return {
                        job: job['uuid'],
                        job_no: job['job_no'],
                        state_from: job['state_from'],
                        contact_mobile: job['contact_customer']
                    }
                })
                if (!this.state.state) {
                    this.setState({
                        warn: true,
                        message: 'Please update state when updating multiple service calls'
                    })
                } else if (this.state.state === 'in_hand_by_agent' && !this.state.startTime) {
                    this.setState({
                        warn: true,
                        message: 'Changing the state to "In Hand" requires an appointment date. Please enter an appointment date and time'
                    })
                } else {
                    var api = `/jobs/multi_update`

                    if (this.state.state) {
                        jobs = jobs.map(job => {
                            return {
                                ...job,
                                state: this.state.state
                            }
                        })
                    }

                    if (this.state.agent) {
                        jobs = jobs.map(job => {
                            return {
                                ...job,
                                rate: this.state.rate,
                                agent: this.state.agent,
                                service_area: this.state.serviceArea
                            }
                        })
                    }

                    if (this.state.startTime) {
                        jobs = jobs.map(job => {
                            return {
                                ...job,
                                appointment: this.state.startTime
                            }
                        })
                    }

                    fetchPost(api,
                        {
                            jobs: jobs
                        },
                        (response) => {
                            this.props.onSuccess()
                        },
                        (response) => {
                            this.setState({ message: response.message, loading: false })
                        }
                    )
                }
            }
        }
    }

    render() {
        const data = this.props.data || {}

        var agentUUID = ''
        if (data && data['agent'] && data['agent']['uuid']) {
            agentUUID = data['agent']['uuid']
        }

        var selectedArea = ''
        if (this.state.areas && this.state.areas.length > 0 && data['service_area']) {
            selectedArea = this.state.areas.find(option => {
                return option['code'] === data['service_area']
            })
            selectedArea = selectedArea.value
        }

        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }

        var appointmentTime = data['appointment_at'] ? moment(data['appointment_at']).format('HH:mm') : ''
        if (this.state.startTime) {
            appointmentTime = moment(this.state.startTime).format('HH:mm')
        }

        const isDisabled = data['state'] === 'completed'

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>Update Multiple</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        this.submit()
                                                    }}
                                                >
                                                    <Row form>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='status'>Status</label>
                                                            <FormSelect id='state' disabled={isDisabled} required value={this.state.state || data['state']} onChange={(e) => this.setState({ state: e.target.value })}>
                                                                <option value='allocated_to_asc'>Allocated to ASC</option>
                                                                <option value='in_hand_by_agent'>In Hand</option>
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>

                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='called_at'>Agent</label>
                                                            <FormSelect
                                                                id='agent1'
                                                                value={this.state.agent || agentUUID}
                                                                required
                                                                disabled={isDisabled}
                                                                onChange={(e) => {
                                                                    if (e.target.value) {
                                                                        const update = {
                                                                            agent: e.target.value,
                                                                        }

                                                                        if (data['state'] === 'allocated_to_asc' && !this.state.state && !data['agent']) {
                                                                            update['state'] = 'in_hand_by_agent'
                                                                        }
                                                                        this.setState(update)
                                                                    }
                                                                }}
                                                            >
                                                                <option value=''>---</option>
                                                                {this.renderAgentOptions()}
                                                            </FormSelect>
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='called_at'>Service Area</label>
                                                            <FormSelect
                                                                id='agent'
                                                                value={this.state.selected || selectedArea}
                                                                required
                                                                disabled={!this.state.agent || isDisabled}
                                                                onChange={(e) => {
                                                                    const value = JSON.parse(e.target.value)
                                                                    if (value) {
                                                                        const update = {
                                                                            rate: value.rate,
                                                                            selected: e.target.value,
                                                                            serviceRate: `${value.rate.toUpperCase()}: R ${value.amount}`,
                                                                            serviceArea: value.code,
                                                                            sponsorArea: `${value.code} - ${value.description}`
                                                                        }

                                                                        if (data['state'] === 'allocated_to_asc' && !this.state.state && !data['agent']) {
                                                                            update['state'] = 'in_hand_by_agent'
                                                                        }
                                                                        this.setState(update)
                                                                    }
                                                                }}
                                                            >
                                                                <option value=''>---</option>
                                                                {this.renderAgentAreaOptions()}
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='appointment_at'>Appointment Date</label>
                                                            <InputGroup className='d-flex my-auto date-range'>
                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    required
                                                                    disabled={isDisabled}
                                                                    selected={this.state.appointmentDate}
                                                                    minDate={new Date()}
                                                                    onChange={(value) => this.handleStartDateChange(value)}
                                                                    placeholderText={this.state.startDate || 'Appointment Date'}
                                                                    dropdownMode='select'
                                                                    value={appointmentDate}
                                                                    className='text-center'
                                                                />
                                                                <InputGroupAddon type='append'>
                                                                    <InputGroupText>
                                                                        <i className='material-icons'>&#xE916;</i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='appointment_at'>Appointment Time</label>
                                                            <InputGroup className='d-flex my-auto date-range'>
                                                                <DatePicker
                                                                    timeFormat='HH:mm'
                                                                    required
                                                                    selected={this.state.appointmentDate}
                                                                    onChange={(value) => this.handleStartTimeChange(value)}
                                                                    placeholderText={this.state.startTime || 'Appointment Time'}
                                                                    dropdownMode='select'
                                                                    timeIntervals={30}
                                                                    minTime={new Date().setHours(7, 0, 0)}
                                                                    maxTime={new Date().setHours(19, 0, 0)}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    disabled={!this.state.startDate || isDisabled}
                                                                    value={appointmentTime}
                                                                    className='text-center'
                                                                />
                                                                <InputGroupAddon type='append'>
                                                                    <InputGroupText>
                                                                        <i className='material-icons'>&#xE916;</i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type='submit'
                                                    >
                                                        Update
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        agents: state.agents
    }
}

export default connect(mapStateToProps)(MultiUpdatePopup);
