import React from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    ButtonGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button,
    Container, CardBody, Badge, FormCheckbox
} from 'shards-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import PageTitle from '../components/common/PageTitle';
import { fetchGet, fetchPost } from '../utils/api';


class JobEnquire extends React.Component {
    
    state = {
        is_loading: false,
        customer_full_name: null,
        agent_full_name : null,
        customer_mobile_number : null,
        serial_no: null,
        areas : [],
        jobs: null,
        customer_full_name_search_warning: null,
        selected: [],
        no_data: null
    }

    componentDidUpdate(props) {
        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({ selected: [] })
        }
        if (this.props.count !== props.count) {
            this.setState({ count: this.props.count })
        }
    }

    renderState(state) {
        if (state) {
            switch (state) {
                case 'assigned_to_agent':
                case 'invoice_generated':
                case 'awaiting_parts_ordered':
                    return <Badge theme="info" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'in_hand_by_agent':
                    return <Badge theme="warning" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'awaiting_parts':
                case 'awaiting_parts_not_ordered':
                case 'refer_to_defy':
                case 'awaiting_parts_refer_to_defy':
                    return <Badge theme="secondary" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'closed':
                case 'completed':
                case 'invoice_submitted':
                    return <Badge theme="success" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'cancelled':
                case 'unable_to_contact':
                    return <Badge theme="danger" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                default:
                    return <Badge theme="light" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
            }
        } return undefined
    }

    renderOrigin(origin) {
        switch (origin) {
            case 'walk_in':
                return <Badge theme="danger" className='mr-2' style={{ background: '#A9B0BC', fontSize: '8px' }}>Walk In</Badge>
            default:
                return <Badge theme="light" className='mr-2' style={{ fontSize: '8px' }}>Referral</Badge>
        }
    }

    renderPriority(priority) {
        switch (priority) {
            case 'urgent':
                return <Badge theme="danger">{priority}</Badge>
            case 'high':
                return <Badge className='badge-high'>{priority}</Badge>
            case 'medium':
                return <Badge theme="warning">{priority}</Badge>
            default:
                return <Badge theme="light">{priority}</Badge>
        }
    }

 
    multipleSearchCriteria(){
        console.log(this.state)

        var api = '/jobs?'
        var isValid = false;

        if(this.state.customer_full_name){
            if(this.state.customer_full_name.length < 3){
                this.setState({
                    customer_full_name_search_warning: '[Morethan 3 characters required]'
                })           
            }else{
                this.setState({
                    customer_full_name_search_warning: null
                }) 
                api = api.indexOf('?') > -1 ? api + '&full_name=' + this.state.customer_full_name  : api + '?full_name=' + this.state.customer_full_name
                isValid = true;
            }
        }

        if(this.state.model){
            if(this.state.model.length < 3){
                this.setState({
                    model_warning: '[Morethan 3 characters required]'
                })           
            }else{
                this.setState({
                    model_warning:  null
                })  
                api = api.indexOf('?') > -1 ? api + '&model=' + this.state.model  : api + '?model=' + this.state.model 
                isValid = true;
            }
        }

        if(this.state.customer_mobile_number){
            if(this.state.customer_mobile_number.length < 3){
                this.setState({
                    customer_mobile_number_warning: '[Morethan 3 characters required]'
                })           
            }else{
                this.setState({
                    customer_mobile_number_warning:  null
                })  
                api = api.indexOf('?') > -1 ? api + '&contact_mobile=' + this.state.customer_mobile_number  : api + '?contact_mobile=' +this.state.customer_mobile_number 
                isValid = true;
            }
        }


        if(isValid){
            fetchGet(api,
                (response) => {
                    if(response.length === 0){
                        this.setState({
                            empty: true
                        })
                    }else{
                        this.setState({
                            empty: false
                        })
                    }
                    this.setState({
                        jobs: response
                    })
                },
                (response) => {
                    console.log(response)
                }
            )
        }else{
            this.setState({
                jobs: null
            })
        }
    }

    clear(){

    }

    render() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        return (
            <div>
            <Container fluid className="main-content-container" style={{ position: 'relative' }}>
            <Row noGutters className="page-header py-4">
                    <PageTitle title="Job" subtitle='Enquire'md="12"
                        className="ml-sm-auto mr-sm-auto"
                    />
                </Row>
                <Card small className='mb-4'>
                    <CardHeader className='border-bottom'>
                        
                        <Row>
                            <Col md='6'><h6 className='m-0'>Search Job</h6></Col>
                            <Col md='6'>
                                <ButtonGroup style={{ float: 'right' }}>
                                <Button theme='danger' type='button' onClick={() => this.props.history.push('/overview')}> Cancel</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className='p-3'>
                            <Row>
                                <Col>
                                    <Form
                                        onSubmit={async (e) => {
                                            e.preventDefault()
                                            
                                        }}
                                    >
                                        <Row form>
                                            <Col md='4' className='form-group'>
                                                <label>Customer Full Names <span style={{color:"darkorange"}}>{this.state.customer_full_name_search_warning}</span></label>
                                                <FormInput
                                                    id='customer'
                                                    placeholder='Customer Name'
                                                    autocomplete="off"
                                                    value={this.state.customer_full_name}
                                                    onChange={(e) => {
                                                        this.multipleSearchCriteria()
                                                        this.setState({
                                                            customer_full_name: e.target.value
                                                        }) 
                                                    }}
                                                />
                                            </Col>
                                            <Col md='4' className='form-group'>
                                                <label >Customer Mobile No. <span style={{color:"darkorange"}}>{this.state.customer_mobile_number_warning}</span></label>
                                                <FormInput
                                                    id='contact_mobile'
                                                    placeholder='Customer Mobile No.'
                                                    type='number'
                                                    min="0"
                                                    value={this.state.customer_mobile_number}
                                                    onChange={(e) => {
                                                        this.multipleSearchCriteria()
                                                            this.setState({
                                                                customer_mobile_number: e.target.value
                                                            })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='4' className='form-group'>
                                                <label >Modal No.  <span style={{color:"darkorange"}}>{this.state.model_warning}</span></label>
                                                <FormInput
                                                    id='model_no'
                                                    type='text'
                                                    placeholder='Model Number'
                                                    value={this.state.model}
                                                    onChange={(e) => {
                                                        this.multipleSearchCriteria()
                                                        this.setState({
                                                            model: e.target.value
                                                        }) 
                                                       
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                        <Col md='6'>
                                            <ButtonGroup style={{ float: 'center' }}>
                                            <Button theme='warning' type='button' onClick={() => {
                                                        this.setState({
                                                            jobs: [],
                                                            customer_full_name: '',
                                                            customer_mobile_number: '',
                                                            customer_full_name_search_warning:'',
                                                            customer_mobile_number_warning: '',
                                                            model_warning: '',
                                                            model: ''
                                                        })
                                            }}> Clear</Button>
                                            </ButtonGroup>
                                        </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>


                        </ListGroupItem>
                    </ListGroup>
                </Card >


            <CardBody className="p-0 pb-3 " style={{ background: 'transparent' }}>
                {this.state.message && (
                    <Row className='py-3 px-4 mx-0' >
                        <div className="text-center text-danger">{this.state.message}</div>
                    </Row>
                )}
                {this.state.jobs && this.state.jobs.map((row, idx) => {
                    const selected = this.state.selected.filter(item => item.uuid === row['uuid']).length > 0
                    const customer = row['customer'] || {}
                    const customerFullName = customer['full_name'] || 'A'
                    const customerName = customerFullName.slice(0, 1).toLowerCase()

                    var dueDate = 'Done'
                    if (row['due_in_days'] !== undefined) {
                        if (row['due_in_days'] > 0) {
                            dueDate = 'Due in ' + Math.abs(row['due_in_days']) + ' days'
                        } else if (row['due_in_days'] === 0) {
                            dueDate = 'Due Today'
                        } else {
                            dueDate = 'Due ' + Math.abs(row['due_in_days']) + ' days ago'
                        }
                    }

                    if (row['is_archived']) {
                        dueDate = 'Done'
                    }

                    var appointmentDate = ''
                    if (row['appointment_at']) {
                        appointmentDate = moment(row['appointment_at']).tz('Africa/Johannesburg').calendar()
                    }

                    var sponsorArea = ''
                    if (row['service_area']) {
                        const area = row['areas'].find(x => x['code'] === row['service_area'])
                        sponsorArea = `${area['code']} - ${area['description']}`
                    } else {
                        sponsorArea = `${row['sponsor_area']} ${row['areas'].map(area => area['code'])}`
                    }

                    const highlight = this.props.scroll === row['uuid'] ? { backgroundColor: '#E8EDF1' } : {}

                    const diff = Math.abs(moment.duration(moment().diff(moment(row['awaiting_parts_at']))).asDays())
                    const timer = <Badge theme="secondary" className='mr-2'>Day {parseInt(diff)}</Badge>
                    const showAwaitingPartsTimer = row['state'] === 'awaiting_parts_ordered' || row['state'] === 'awaiting_parts_refer_to_defy'

                    return (
                        <div id={row['uuid']} key={row['uuid']}>
                            <Row className='job-list__item d-flex pr-2 pl-4 px-2 mx-0' style={highlight}>
                                <Col sm='1' md='1' lg='1' className='hide-on-tablet'>
                                    <div className='mx-3 my-3 px-2 py-2'>
                                        {!row['is_archived'] && (<FormCheckbox
                                            checked={selected}
                                            style={{ width: '4rem' }}
                                            onChange={() => {
                                                var list = this.state.selected
                                                if (selected) {
                                                    list = list.filter(item => item.uuid !== row['uuid'])
                                                    this.setState({
                                                        selected: list,
                                                        message: null
                                                    })
                                                    this.props.onSelected(list)
                                                } else {
                                                    list.push({
                                                        uuid: row['uuid'],
                                                        sponsor: row['sponsor_area'],
                                                        service: row['service_area'],
                                                        areas: row['areas'],
                                                        job_no: row['job_no'],
                                                        state_from: row['state'],
                                                        agent: row['agent'],
                                                        contact_customer: row['customer'] ? row['customer']['contact_mobile'] : null
                                                    })
                                                    this.setState({
                                                        selected: list,
                                                        message: null
                                                    })
                                                    this.props.onSelected(list)
                                                }
                                            }}
                                        />
                                        )}
                                    </div>
                                </Col>
                                <Col sm='12' md='8' lg='7' className='py-2 px-0'>
                                    <div
                                        onClick={() => this.props.history.push('/jobs/details', {
                                            row: row,
                                            currentState: this.props.currentState,
                                            scroll: row['uuid'],
                                            returnTo: row['is_archived'] ? '/archives' : '/jobs'
                                        })}
                                    >
                                        <div className='d-flex-to-block text-uppercase'>
                                            <Badge theme="light" className='mr-2'>JOB NO. {row['job_no']}</Badge>
                                            {row['invoice_no'] && <Badge theme="light" className='mr-2'>INVOICE {row['invoice_no']}</Badge>}
                                            <div>
                                                {this.renderState(row['state'])}
                                                {(row['invoice_state'] && row['invoice_state'] !== 'null') && this.renderState(row['invoice_state'])}
                                                {(row['state'] !== 'completed' && row['state'] !== 'closed') && this.renderPriority(row['priority'])}
                                            </div>
                                        </div>
                                        <h6 className='job-title my-2'>{row['model'] || 'Unknown Model'}</h6>
                                        <div className='my-1' style={{}}>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">notes</i>' }}
                                            />
                                            <span>{row['issue']}</span>
                                        </div>
                                        <div className='my-1' style={{}}>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">location_on</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{customer['address']}</span>
                                        </div>
                                        {this.renderOrigin(row['job_origin'])}
                                        <span>&nbsp;{row['customer'] ? row['customer']['full_name'] : ''}</span>
                                        <span>{' • '} {customer['contact_mobile'] || customer['contact_home'] || customer['contact_work']}</span>
                                        <span>{' • '} {moment(row['called_at']).fromNow()}</span>
                                    </div>
                                </Col>
                                <Col sm='12' md='4' lg='4'>
                                    <div
                                        onClick={() => this.props.history.push('/jobs/details', {
                                            row: row,
                                            currentState: this.props.currentState,
                                            scroll: row['uuid'],
                                            returnTo: row['is_archived'] ? '/archives' : '/jobs'
                                        })}
                                    >
                                        <div className='d-flex my-1'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon bolder'
                                                style={{ fontWeight: 600 }}
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">alarm</i>' }}
                                            />
                                            <span style={{ textTransform: 'uppercase', fontWeight: 600 }}>{dueDate}</span>
                                        </div>
                                        <div className='d-flex my-1 text-uppercase'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">location_on</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{sponsorArea}</span>
                                        </div>
                                        <div className='d-flex my-1'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">engineering</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{row['agent'] ? row['agent']['full_name'] : 'Unassigned'}</span>
                                        </div>
                                        <div className='d-flex my-1 text-uppercase'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">event_available</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{appointmentDate}</span>
                                        </div>
                                        {showAwaitingPartsTimer && <div className='d-flex my-1 text-uppercase'>
                                            {timer}
                                        </div>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })
                }

            </CardBody >
            </Container>

            {this.state.empty && (
                <Container fluid className="main-content-container px-4 pb-4 job-table">
                <div className="error__content mt-4">
                    <h3>No Service Calls Found</h3>
                </div>
                </Container>
            )}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps) (withRouter(JobEnquire));

