import React from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, InputGroup, FormInput, Alert } from 'shards-react';

import RangeDatePicker from '../../common/RangeDatePicker';
import InvoiceStateFilter from './InvoiceStateFilter';
import ServiceAreaFilter from '../ServiceAreaFilter';
import SponsorAreaFilter from '../SponsorAreaFilter';
import AdditionalLinesFilter from '../AdditionalLinesFilter';
import JobTypeFilter from '../jobs/JobTypeFilter';
import AgentFilter from '../agents/AgentFilter';
import { fetchDownloadMulti, fetchGet, fetchPost } from '../../../utils/api';
import { colors } from '../../../utils/colors';
import moment from 'moment-timezone';

class InvoiceActions extends React.Component {
    state = {
        open: false,
        title: '',
        showStatusFilter: false,
        showServiceFilter: false,
        showAgentFilter: false,
        showSponsorFilter: false,
        showPriorityFilter: false,
        showAdditionalFilter: false,
        showInvoiceStatus: false,
        showJobTypeFilter: false,
        selected: [],
        searchJob: '',
        searchInvoice: '',
        startAt: null,
        endAt: null,
        areas: [],
        sponsors: [],
        jobType: null,
        selectAll: false,
        clear: false,
        area: null,
        jobStatus: null,
        status: 'invoice_generated',
        agent: null,
        priority: null,
        length: 0
    }

    componentDidUpdate(props) {
        if (this.props.selected !== props.selected) {
            this.setState({
                selected: this.props.selected,
                selectedDates: this.props.selectedDates,
                selectAll: this.props.selectAll
            })
        }

        if (this.props.sponsors !== props.sponsors) {
            this.setState({
                sponsors: this.props.sponsors
            })
        }

        if (this.props.areas !== props.areas) {
            this.setState({
                areas: this.props.areas
            })
        }

        if (this.props.length !== props.length) {
            this.setState({
                length: this.props.length
            })
        }

        if (this.props.status !== props.status) {
            this.setState({
                status: this.props.status
            })
        }

        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({
                area: null,
                status: 'invoice_generated',
                agent: null,
                priority: null,
                startAt: null,
                endAt: null,
                searchJob: '',
                searchInvoice: '',
                serviceArea: null,
                sponsorArea: null,
                jobType: null,
                selected: [],
                selectedDates: [],
                line: null
            })
        }
    }

    downloadInvoice() {
        var api = '/utils/export/invoice_excel/multi'

        var sortedList = this.props.selectedDates.sort((a, b) => {
            if (moment(a).diff(moment(b)) > 0)
                return 1
            if (moment(a).diff(moment(b)) < 0)
                return -1
            return 0
        })

        var startAt = moment(sortedList[0]).format('YYYY-MM-DD')
        var endAt = moment(sortedList[sortedList.length - 1]).format('YYYY-MM-DD')

        fetchDownloadMulti(api,
            {
                invoices: this.props.selected,
                startAt: this.state.startAt || startAt,
                endAt: this.state.endAt || endAt,
                filters: {
                    serviceArea: this.state.serviceArea,
                    sponsorArea: this.state.sponsorArea,
                    state: this.state.status,
                    additionalLines: this.state.line
                }
            },
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                this.setState({ downloadURL: url })
                this.fetchName(startAt, endAt)
            },
            (response) => {
            }
        );
    }

    archiveInvoices() {
        var api = '/invoices/archive_multi'

        fetchPost(api,
            {
                invoices: this.props.selected
            },
            (response) => {
                this.toggle('archived')
            },
            (response) => {
                this.setState({
                    message: response.message
                })
            }
        );
    }

    fetchName(startAt, endAt) {
        var api = '/invoices/fetch_batch_name'

        fetchGet(api,
            (response) => {
                let a = document.getElementById('download');
                a.href = this.state.downloadURL;
                const details = response.details
                // const name = `${details.area}_${this.state.startAt || startAt}_${stateEndAt || endAt}_${details.batch}.xlsx`
                const name = response.name
                a.download = name;
                a.click();
                this.toggle('submitted')
            },
            () => {
            }
        );
    }

    fetchStatus(status) {
        return status === 'invoice_submitted' ? 'Submitted' : 'Generated'
    }

    toggle(title) {
        if (title === 'showUpdate') {
            this.props.onUpdateMultiple()
        } else if (title === 'selection') {
            this.setState({ selectAll: !this.state.selectAll })
            this.props.onSelection(!this.state.selectAll)
            if (this.state.selectAll) {
                this.props.onDone()
            }
        } else if (title === 'submitted') {
            this.props.onDone(1)
        } else {
            const state = {
                showStatusFilter: false,
                showServiceFilter: false,
                showAgentFilter: false,
                showPriorityFilter: false,
                showSponsorFilter: false,
                showJobTypeFilter: false,
                clear: false
            }
            if (title) {
                state[title] = !this.state[title]
            }
            this.setState(state);
        }
    }

    render() {
        const hasSelected = this.state.selected.length > 0

        return (
            <div className='job-actions'>
                <a id='download' href='#' style={{ display: 'none' }} />
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='6' lg='5' className='d-flex'>
                                <RangeDatePicker
                                    clear={this.state.clear}
                                    onStartAt={(startAt) => {
                                        this.setState({ startAt, clear: false })
                                        this.props.onStartAt(startAt)
                                    }}
                                    onEndAt={(endAt) => {
                                        this.setState({ endAt, clear: false })
                                        this.props.onEndAt(endAt)
                                    }}
                                />
                            </Col>
                            <Col sm='12' md='6' lg='5'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search invoice no." style={{ width: '40%' }} value={this.state.searchInvoice} onChange={e => {
                                        this.setState({ searchInvoice: e.target.value })
                                        this.props.onSearchInvoice(e.target.value)
                                    }} />
                                    <FormInput placeholder="or job no." style={{ width: '40%' }} value={this.state.searchJob} onChange={e => {
                                        this.setState({ searchJob: e.target.value })
                                        this.props.onSearchJob(e.target.value)
                                    }} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='5' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    <Button
                                        size='sm'
                                        className='btn-white ml-auto ml-sm-auto  mt-3 mt-sm-0'
                                        onClick={() => this.toggle('selection')}
                                        style={{ position: 'relative', marginRight: '8px' }}
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>{hasSelected ? 'Unselect All' : 'Select All'}</span>
                                        {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                    </Button>
                                    {/* Agent Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showAgentFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{hasSelected ? 'Assign Agent' : `${this.state.agent || `Agent Filter`} `}</span>
                                            {this.state.agent ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <AgentFilter visible={this.state.showAgentFilter} selected={this.state.selected} onSelect={(filter) => {
                                            this.props.onFilterAgent(filter)
                                            this.setState({ showAgentFilter: false, agent: filter.value ? filter.label : null })
                                        }} />
                                    </div>)}

                                    {/* Sponsor Area Filter */}
                                    {(!hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showSponsorFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.sponsorArea || 'Sponsor Area Filter'}</span>
                                            {this.state.sponsorArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <SponsorAreaFilter visible={this.state.showSponsorFilter} sponsors={this.state.sponsors} onSelect={(filter) => {
                                            this.props.onFilterSponsor(filter.value)
                                            this.setState({ showSponsorFilter: false, sponsorArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}
                                    {/* Service Area Filter */}
                                    {(!hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showServiceFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.serviceArea || 'Service Area Filter'}</span>
                                            {this.state.serviceArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <ServiceAreaFilter visible={this.state.showServiceFilter} areas={this.state.areas} onSelect={(filter) => {
                                            this.props.onFilterService(filter.value)
                                            this.setState({ showServiceFilter: false, serviceArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}

                                </ButtonGroup>
                            </Col>
                            <Col sm='12' md='12' lg='6' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Invoice Status Filter */}
                                    {(!hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showInvoiceStatus')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.fetchStatus(this.state.status) || 'Status Filter'}</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <InvoiceStateFilter visible={this.state.showInvoiceStatus} onSelect={(filter) => {
                                            this.props.onFilterStatus(filter.value)
                                            this.setState({ showInvoiceStatus: false, status: filter.value ? filter.label : null })
                                        }} />
                                    </div>)}

                                    {/* Job Type Filter */}
                                    {(!hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showJobTypeFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.jobType || 'Job Type Filter'}</span>
                                            {this.state.jobType ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <JobTypeFilter visible={this.state.showJobTypeFilter} onSelect={(filter) => {
                                            this.props.onFilterJobType(filter.value)
                                            this.setState({ showJobTypeFilter: false, jobType: filter.value })
                                        }} />
                                    </div>)}

                                    {/* Additional Line Filter */}
                                    {(!hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showAdditionalFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.line || 'Additional Lines Filter'}</span>
                                            {this.state.line ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <AdditionalLinesFilter visible={this.state.showAdditionalFilter} onSelect={(filter) => {
                                            this.props.onFilterLines(filter.value)
                                            this.setState({ showAdditionalFilter: false, line: filter.value ? filter.label : null })
                                        }} />
                                    </div>)}

                                    {/* Export Invoice */}
                                    {(this.state.status !== 'invoice_submitted' && hasSelected) && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            className='btn-green'
                                            onClick={() => {
                                                this.downloadInvoice()
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </div>)}

                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.toggle()
                                                this.setState({
                                                    searchInvoice: null,
                                                    searchJob: null,
                                                    area: null,
                                                    status: null,
                                                    agent: null,
                                                    priority: null,
                                                    startAt: null,
                                                    clear: true,
                                                    endAt: null,
                                                    serviceArea: null,
                                                    sponsorArea: null,
                                                    selected: [],
                                                    selectedDates: [],
                                                    line: null
                                                })
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                        </Button>
                                    </div>)}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {this.state.message && (
                            <Row>
                                <Col sm='12' md='12' lg='12' className='d-flex w-100'>
                                    <Alert style={{ backgroundColor: this.state.error ? colors.RED : colors.GREEN, color: '#ffffff', width: '100%' }}>
                                        {this.state.message}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default InvoiceActions;
