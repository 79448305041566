import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import InvoiceTable from '../components/libs/invoices/InvoiceTable';
import InvoiceActions from '../components/libs/invoices/InvoiceActions';
import InvoicePopup from '../components/libs/invoices/InvoicePopup';
import RevertPopup from '../components/libs/RevertPopup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_invoices, fetch_agents } from '../store/action';

class Invoices extends React.Component {
    state = {
        invoices: [],
        pagination: {},
        page: 1,
        status: 'invoice_generated',
        refresh: false,
        showModal: false,
        selectAll: false,
        showRevert: false
    }

    async componentDidMount() {
        if (this.props.fetch_agents) {
            this.props.fetch_agents()
        }
        if (this.props.location.state && this.props.location.state.tab) {
            this.setState({ tab: this.props.location.state.tab })
            this.props.fetch_invoices({}, this.props.location.state.tab)
        } else if (this.props.fetch_invoices) {
            this.props.fetch_invoices({ page: 1 })
            this.setState({ loading: false })
        }
        if (this.props.location.search) {
            const params = this.props.location.search.substring(1).split('&')
            const state = {}

            params.forEach(param => {
                state[param.split('=')[0]] = param.split('=')[1]
            })
        }
        if (this.props.location.state) {
            if (this.props.location.state.scroll !== null) {
                if (window.document.getElementById(this.props.location.state.scroll)) {
                    setTimeout(() => {
                        const element = window.document.getElementById(this.props.location.state.scroll)
                        const top = element ? element.offsetTop - 170 : 0
                        window.scrollTo({ top: top, behavior: 'smooth' });
                    }, 1200)
                }
            }
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            invoices: props.invoices || [],
            pagination: props.pagination || {}
        }
    }

    onPage(next = false, prev = false) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const state = this.state
        if (next) {
            state['page'] = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
        }
        this.setState(state)
        delete state['invoices']
        state['isArchived'] = false
        this.props.fetch_invoices(state, state['page'])
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onFilter(key, value) {
        const state = this.state
        state[key] = value
        state['page'] = 1

        this.setState(state)
        delete state['invoices']
        state['isArchived'] = false
        this.props.fetch_invoices(state, 1)
    }

    render() {
        const currentState = {
            ...this.state
        }
        delete currentState['invoices']
        delete currentState['loading']

        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                {this.state.showRevert && (
                    <RevertPopup
                        job={this.state.job}
                        onComplete={() => {
                            this.setState({ showRevert: false })
                            this.toggle(this.state.tab || 0)
                        }}
                        onCancel={() => {
                            this.setState({ showRevert: false })
                        }}
                    />
                )}
                <InvoiceActions
                    onSearchJob={search => this.onFilter('searchJob', search)}
                    onSearchInvoice={search => this.onFilter('searchInvoice', search)}
                    onStartAt={startAt => this.onFilter('startAt', startAt)}
                    onEndAt={endAt => this.onFilter('endAt', endAt)}
                    selected={this.state.selected}
                    status={this.state.status}
                    selectedDates={this.state.selectedDates}
                    onDone={(tab) => {
                        const state = {
                            ...this.state,
                            selected: [],
                            selectedDates: null,
                            searchJob: null,
                            searchInvoice: null,
                            selectAll: false,
                            refresh: true,
                            invoices: []
                        }
                        state['isArchived'] = false
                        this.setState(state)
                        this.props.fetch_invoices(state, state['page'])
                    }}
                    onClearAll={(tab) => {
                        const state = {
                            searchInvoice: null,
                            searchJob: null,
                            startAt: null,
                            endAt: null,
                            sponsor: null,
                            service: null,
                            agent: null,
                            additionalLines: null,
                            selected: [],
                            selectedDates: null,
                            selectAll: false,
                            refresh: true,
                            jobType: null,
                            status: 'invoice_generated',
                            invoices: []
                        }
                        state['isArchived'] = false
                        this.setState(state)

                        this.props.fetch_invoices({
                            state: this.state.status,
                            isArchived: false
                        }, state['page'])
                    }}
                    onFilterAgent={agent => {
                        if (agent && agent.value) {
                            this.setState({ areas: agent.value['areas'], sponsors: agent.value['sponsors'] })
                            this.onFilter('agent', agent.value['uuid'])
                        } else {
                            this.onFilter('agent', null)
                        }
                    }}
                    onFilterJobType={jobType => this.onFilter('jobType', jobType)}
                    onFilterStatus={status => this.onFilter('status', status)}
                    onFilterService={area => this.onFilter('service', area)}
                    onFilterSponsor={area => this.onFilter('sponsor', area)}
                    onFilterLines={line => this.onFilter('additionalLines', line)}
                    onSelection={(selectAll) => {
                        this.setState({ selectAll: selectAll })
                    }}
                    refresh={this.state.refresh}
                />
                {this.state.showModal && <InvoicePopup
                    data={this.state.selected}
                    onCancel={() => this.setState({ showModal: false })}
                    onSuccess={() => {
                        this.setState({ showModal: false })
                        this.props.fetch_invoices({}, 1)
                    }}
                />}
                <Row className="mx-0">
                    <Col>
                        <div className='job-table'>
                            <Card small className="mb-4" style={{ position: 'relative' }}>
                                <InvoiceTable
                                    onEdit={(selected) => {
                                        this.setState({ selected: selected, showModal: true })
                                    }}
                                    onSelected={(selected, dates) => this.setState({ selected: selected, selectedDates: dates })}
                                    selectAll={this.state.selectAll}
                                    data={this.state.invoices}
                                    onPrev={() => this.onPage(false, true)}
                                    onNext={() => this.onPage(true)}
                                    page={this.state.page}
                                    onShowRevertPopup={(job) => this.setState({ showRevert: true, job })}
                                    count={(this.state.pagination || {}).count}
                                    status={this.state.status}
                                    onReverted={(tab) => this.toggle(tab || 0)}
                                    refresh={this.state.refresh}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>

        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        invoices: state.invoices,
        pagination: state.pagination || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_invoices: (params, page) => dispatch(fetch_invoices(params, page)),
        fetch_agents: () => dispatch(fetch_agents())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invoices));
