import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';

const list = [
    {
        label: 'Enabled',
        value: true
    },
    {
        label: 'Disabled',
        value: false
    },
    {
        title: '',
        label: 'Remove Filter',
        key: 'remove',
        value: null
    }
]

class EnabledFilter extends React.Component {
    state = {
        visible: false
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }
    }

    render() {
        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {list.map((item, idx) => {
                    if (item.value) {
                        return (
                            <div key={idx}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect({
                                        key: item.key,
                                        value: item.value
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {item.label.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }

                    return (
                        <div key={idx}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    key: item.key,
                                    value: item.value,
                                    title: item.title
                                })}>
                                    <span className='notification__category stats-small__label text-uppercase'>{item.title}</span>
                                    <p className='mb-0 text-capitalize'>
                                        {item.label.replace(/_/g, ' ')}
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}

export default EnabledFilter;