import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../utils/colors';

class ExportPopup extends React.Component {
    state = {
        message: null,
        startDate: null,
        endDate: null
    }

    componentDidMount() {
        if (this.props.exportMessage) {
            this.setState({ message: this.props.exportMessage,
                startDate : this.props.startDate,
                endDate : this.props.endDate
             })
        }
    }

    componentDidUpdate(props) {
        if (this.props.exportMessage !== props.exportMessage) {
            this.setState({ message: this.props.exportMessage,
                startDate : this.props.startDate,
                endDate : this.props.endDate
             })
        }
    }

    render() {
        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <a id='download' href='#' style={{ display: 'none' }} />
                        <div className='center-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>Export Service Calls</strong></h4>
                                                <p className='text-muted d-block mb-4'>Export .xlsx file of service calls</p>

                                                <hr />
                                                <FormGroup>
                                                    <p className='text-muted d-block mb-2'>Download Admin Export (.xlsx)</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                                        <Button
                                                            size='sm'
                                                            type='button'
                                                            onClick={() => {
                                                                this.setState({
                                                                    message: null,
                                                                    result: null
                                                                })
                                                                this.props.onExportAdminReport()
                                                            }}
                                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        >
                                                            <span style={{ fontSize: '0.75rem' }}>Download</span>
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                                <hr />
                                                <FormGroup>
                                                    <p className='text-muted d-block mb-2'>Download Simple Export (.csv)</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                                        <Button
                                                            size='sm'
                                                            type='button'
                                                            disabled={false}
                                                            onClick={() => {
                                                                this.setState({
                                                                    message: null,
                                                                    result: null
                                                                })
                                                                this.props.onExportSimpleReport()
                                                            }}
                                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        >
                                                            <span style={{ fontSize: '0.75rem' }}>Download</span>
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                                <hr />
                                                <FormGroup>
                                                    <p className='text-muted d-block mb-2'>Download Historic Export (.csv)</p>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                                        <Button
                                                            size='sm'
                                                            type='button'
                                                            disabled={false}
                                                            onClick={() => {
                                                                this.setState({
                                                                    message: null,
                                                                    result: null
                                                                })
                                                                if(this.props.startDate === null){
                                                                    this.setState({message : 'Please provide value for the start date'})
                                                                    return
                                                                }
                                                                if(this.props.endDate === null){
                                                                    this.setState({message : 'Please provide value for the end date'})
                                                                    return
                                                                }
                                                                this.props.onExportHistoricReport()
                                                            }}
                                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        >
                                                            <span style={{ fontSize: '0.75rem' }}>Download</span>
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                                {this.state.loading && <p>Exporting service calls...</p>}
                                                {(this.state.message || this.state.result) && (
                                                    <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                                        {this.state.result}<br />
                                                        {this.state.message}
                                                    </Alert>
                                                )}
                                                <Button
                                                    size='md'
                                                    className='btn-white mr-3 mt-3 mt-sm-0'
                                                    onClick={() => {
                                                        this.setState({
                                                            message: null,
                                                            result: null
                                                        })
                                                        this.props.onCancel()
                                                    }}
                                                    type='button'
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect()(withRouter(ExportPopup));
