import React from 'react';
import {
    Container, Button, ButtonGroup, CardBody, Alert, Badge
} from "shards-react";
import { withRouter } from 'react-router-dom';
import { fetchPost } from '../../../utils/api';
import { colors } from '../../../utils/colors';
import * as moment from 'moment-timezone';

class UserTable extends React.Component {
    state = {
        selected: [],
        page: null,
        count: 0
    }

    componentDidMount() {
        this.setState({
            page: this.props.page,
            count: this.props.count
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.page && this.props.page !== prevProps.page) {
            this.setState({
                page: this.props.page
            })
        }
        if (this.props.count !== prevProps.count) {
            this.setState({ count: this.props.count })
        }
    }

    onUpdate(uuid, deleted = true) {
        var api = '/users/delete/' + uuid
        if (deleted) {
            api = '/users/reinstate/' + uuid
        }
        fetchPost(api,
            {},
            (response) => {
                if (response && response.status === 200) {
                    this.setState({ message: deleted ? 'Successfully enabled!' : 'Successfully disabled!', error: false })
                    this.props.onSuccess()
                } else {
                    this.setState({ message: response.message, error: true, })
                }
            },
            (response) => {
                this.setState({ message: response.message, error: true })
            },
            true
        )
    }

    render() {
        const page = this.state.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4 ">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Users Found</h3>
                        <p>You can create new users. </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3">
                {this.state.message && (
                    <Alert style={{ backgroundColor: this.state.error ? colors.RED : colors.GREEN, color: '#ffffff' }}>
                        {this.state.message}
                    </Alert>
                )}
                <table className="table mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">First Name</th>
                            <th scope="col" className="border-0">Last Name</th>
                            <th scope="col" className="border-0">Email</th>
                            <th scope="col" className="border-0 hide-on-mobile">User Type</th>
                            <th scope="col" className="border-0 hide-on-tablet">Created</th>
                            <th scope="col" className="border-0 hide-on-tablet">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(this.props.data || []).map((row, idx) => {
                            const hideSuper = row['user_type'] === 'super' ? 'admin' : row['user_type']
                            return (
                                <tr key={idx} style={row['deleted'] ? { background: '#EEF3F6' } : {}}>
                                    <td>{row['first_name']}</td>
                                    <td>{row['last_name']}</td>
                                    <td>{row['email']}</td>
                                    <td className='hide-on-mobile'>{hideSuper}</td>
                                    <td className='hide-on-tablet'>{moment(row['created_at']).format('YYYY-MM-DD')}</td>
                                    {row['deleted'] && (<td className='hide-on-tablet'>
                                        <ButtonGroup size="sm" className="mr-2 ">
                                            <Button className='btn-green' onClick={() => this.props.onSelect(row)}>Edit</Button>
                                            <Button className='btn-yellow' onClick={() => this.props.onSelectView(row)}>View</Button>
                                            <Button className='btn-primary' onClick={() => this.onUpdate(row['uuid'], true)}>In Active</Button>
                                        </ButtonGroup>
                                    </td>)}
                                    {!row['deleted'] && (<td className='hide-on-tablet'>
                                        <ButtonGroup size="sm" className="mr-2 ">
                                            <Button className='btn-green' onClick={() => this.props.onSelect(row)}>Edit</Button>
                                            <Button className='btn-yellow' onClick={() => this.props.onSelectView(row)}>View</Button>
                                            <Button className='btn-danger' onClick={() => this.onUpdate(row['uuid'], false)}>Active</Button>
                                        </ButtonGroup>
                                    </td>)}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody>
        )
    }
}

export default withRouter(UserTable)
