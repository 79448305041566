import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import InvoiceTable from '../components/libs/invoices/InvoiceTable';
import JobTable from '../components/libs/jobs/JobTable';
import ArchiveActions from '../components/libs/ArchiveActions';
import InvoicePopup from '../components/libs/invoices/InvoicePopup';
import RevertPopup from '../components/libs/RevertPopup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_invoices, fetch_agents, fetch_jobs } from '../store/action';
import { fetchDownload } from '../utils/api';
import { createRequestParams } from "../utils/functions";
import moment from 'moment-timezone';

class Archives extends React.Component {
    state = {
        invoices: [],
        jobs: [],
        pagination: {},
        state: null,
        tabs: ['Jobs', 'Invoices'],
        tab: 0,
        page: 1,
        refresh: false,
        showModal: false,
        showRevert: false,
        scrolled: false
    }

    async componentDidMount() {
        if (this.props.fetch_agents) {
            this.props.fetch_agents()
        }

        if (this.props.location.state && this.props.location.state.clear) {
            this.onClearAll()
        }

        if (this.props.location.search) {
            const params = this.props.location.search.substring(1).split('&')
            const state = this.props.location.state ? this.props.location.state.currentState || {} : {}
            params.forEach(param => {
                if (param.split('=')[0] === 'tab' && param.split('=')[1] === 'invoices') {
                    state['tab'] = 1
                } else {
                    state[param.split('=')[0]] = param.split('=')[1]
                }
            })

            this.setState(state)
            state['isArchived'] = true
            if (state['tab'] === 1) {
                this.props.fetch_invoices(state, state.page || 1)
            } else {
                this.props.fetch_jobs(state, state.page || 1)
            }

        } else {
            const state = this.props.location.state ? this.props.location.state.currentState || {} : {}
            this.setState(state)
            state['isArchived'] = true
            this.props.fetch_jobs(state, state.page || 1)
        }

        if (this.props.location.state) {
            if (this.props.location.state.scroll !== null) {
                if (window.document.getElementById(this.props.location.state.scroll)) {
                    setTimeout(() => {
                        const element = window.document.getElementById(this.props.location.state.scroll)
                        const top = element ? element.offsetTop - 170 : 0
                        window.scrollTo({ top: top, behavior: 'smooth' });
                        this.setState({ scrolled: true })
                    }, 1200)
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({ scrolled: false })
    }

    componentDidUpdate(props, state) {
        if (this.props.location.state && props.location.state && this.state.tab === 1) {
            if (this.props.location.state.scroll !== null) {
                if (this.state.scrolled !== state.scrolled && window.document.getElementById(this.props.location.state.scroll)) {
                    setTimeout(() => {
                        const element = window.document.getElementById(this.props.location.state.scroll)
                        const top = element ? element.offsetTop - 170 : 0
                        window.scrollTo({ top: top, behavior: 'smooth' });
                        this.setState({ scrolled: true })
                    }, 1200)
                }
            }
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            invoices: props.invoices || [],
            jobs: props.jobs || [],
            pagination: props.pagination || {}
        }
    }

    toggle(tab) {
        const params = {
            isArchived: true,
            searchInvoice: this.state.searchInvoice || null,
            searchJob: this.state.searchJob || null,
            search: this.state.searchJob || null,
            startAt: this.state.startAt || null,
            endAt: this.state.endAt || null,
            agent: this.state.agent || null,
            jobState: this.state.jobState || null,
            page: 1
        }
        this.setState({ tab: tab, page: 1, invoices: [], jobs: [] })
        tab === 0 ? this.props.fetch_jobs(params, 1) : this.props.fetch_invoices(params, 1)
    }

    onPage(next = false, prev = false) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const state = {
            ...this.state,
            jobs: [],
            invoices: []
        }
        var page = null
        if (next) {
            page = state.page + 1
        }
        if (prev && state.page !== 0) {
            page = state.page - 1
        }
        state['page'] = page
        state['isArchived'] = true
        this.setState(state)

        state.tab === 0 ? this.props.fetch_jobs(state, page) : this.props.fetch_invoices(state, page)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onFilter(key, value) {
        const state = this.state
        state[key] = value

        if (state.tab === 0 && key === 'searchJob') {
            state['search'] = value
        }
        state['page'] = 1

        state['jobs'] = []
        state['invoices'] = []

        state['isArchived'] = true
        this.setState(state)
        delete state['invoices']

        state.tab === 0 ? this.props.fetch_jobs(state, 1) : this.props.fetch_invoices(state, 1)
    }

    onExport() {
        const params = this.state
        params['isArchived'] = true
        var api = createRequestParams('/utils/export/archived/jobs', params)

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Calls_Archived_${moment().format('DD-MM-YYYY')}.csv`
                if (params.startAt && params.endAt) {
                    name = `Export_Calls_Archived_${params.startAt}-to-${params.endAt}.csv`
                }
                a.download = name;
                a.click();
            },
            (response) => {
                this.setState({ exportMessage: response.message })
            }
        );
    }

    onClearAll() {
        const state = {
            searchInvoice: null,
            searchJob: null,
            jobState: null,
            startAt: null,
            endAt: null,
            agent: null,
            tab: this.state.tab || 0,
            refresh: true
        }

        state['page'] = 1

        state['jobs'] = []
        state['invoices'] = []

        state['isArchived'] = true
        this.setState(state)

        state.tab === 0 ? this.props.fetch_jobs(state, 1) : this.props.fetch_invoices(state, 1)
    }

    renderTable() {
        var currentState = {
            ...this.state
        }
        delete currentState['invoices']
        delete currentState['jobs']
        delete currentState['loading']
        delete currentState['showPopup']
        delete currentState['showUpdatePopup']
        delete currentState['user']

        if (this.state.tab === 1) {
            return (
                <InvoiceTable
                    onEdit={(selected) => {
                        this.setState({ selected: selected, showModal: true })
                    }}
                    onSelected={(selected, dates) => this.setState({ selected: selected, selectedDates: dates })}
                    selectAll={this.state.selectAll}
                    data={this.state.invoices}
                    onPrev={() => this.onPage(false, true)}
                    onNext={() => this.onPage(true)}
                    page={this.state.page}
                    currentState={currentState}
                    onShowRevertPopup={(job) => this.setState({ showRevert: true, job })}
                    count={(this.state.pagination || {}).count}
                    status={'archived'}
                    onReverted={(tab) => this.toggle(tab || 0)}
                    refresh={this.state.refresh}
                    onClearScroll={() => this.setState({ scrolled: false })}
                />
            )
        }

        return (
            <JobTable
                onSelected={(selected) => this.setState({ selected: selected })}
                data={this.state.jobs}
                onPrev={() => this.onPage(false, true)}
                onNext={() => this.onPage(true)}
                page={this.state.page}
                currentState={currentState}
                count={this.state.pagination.count}
                refresh={this.state.refresh}
                scroll={this.props.location.state ? this.props.location.state.scroll : null}
            />
        )
    }

    render() {
        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                {this.state.showRevert && (
                    <RevertPopup
                        job={this.state.job}
                        onComplete={() => {
                            this.setState({ showRevert: false })
                            this.toggle(this.state.tab || 0)
                        }}
                        onCancel={() => {
                            this.setState({ showRevert: false })
                        }}
                    />
                )}
                <ArchiveActions
                    onSearchJob={search => this.onFilter('searchJob', search)}
                    onSearchInvoice={search => this.onFilter('searchInvoice', search)}
                    onStartAt={startAt => this.onFilter('startAt', startAt)}
                    onEndAt={endAt => this.onFilter('endAt', endAt)}
                    tab={this.state.tab}
                    isArchive={true}
                    onClearAll={() => this.onClearAll()}
                    onFilterAgent={agent => {
                        if (agent && agent.value) {
                            this.setState({ areas: agent.value['areas'], sponsors: agent.value['sponsors'] })
                            this.onFilter('agent', agent.value['uuid'])
                        } else {
                            this.onFilter('agent', null)
                        }
                    }}
                    onExport={() => this.onExport()}
                    onFilterJobStatus={status => this.onFilter('jobState', status)}
                    refresh={this.state.refresh}
                />
                {this.state.showModal && <InvoicePopup
                    data={this.state.selected}
                    onCancel={() => this.setState({ showModal: false })}
                    onSuccess={() => {
                        this.setState({ showModal: false })
                        this.props.fetch_invoices({}, 1)
                    }}
                />}
                <Row className="mx-0">
                    <Col>
                        <div className='job-table'>
                            <Card small className="mb-4" style={{ position: 'relative' }}>
                                <div className='tabs d-flex'>
                                    {this.state.tabs.map((tab, index) => {
                                        const className = this.state.tab === index ? 'tab-item active' : 'tab-item'
                                        return (
                                            <div className={className} onClick={() => this.toggle(index)}>
                                                {tab}
                                            </div>
                                        )
                                    })}
                                </div>
                                {this.renderTable()}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>

        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        invoices: state.invoices,
        jobs: state.jobs,
        pagination: state.pagination || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_invoices: (params, page) => dispatch(fetch_invoices(params, page)),
        fetch_jobs: (params, page) => dispatch(fetch_jobs(params, page)),
        fetch_agents: () => dispatch(fetch_agents())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Archives));
