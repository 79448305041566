import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import routes from './routes';
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/shards-dashboards.1.1.0.min.css';
import './assets/main.css';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          {routes.map((route, index) => {
            if (route.path) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={() => {
                    return (
                      <route.layout>
                        <route.component />
                      </route.layout>
                    )
                  }}
                />
              );
            }

            return (
              <Route
                key={index}
                component={() => {
                  return (
                    <route.layout>
                      <route.component />
                    </route.layout>
                  )
                }}
              />
            );
          })}
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
