import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import moment from 'moment-timezone';
import ReviewTable from '../components/libs/reviews/ReviewTable';
import ReviewActions from '../components/libs/reviews/ReviewActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_reviews } from '../store/action';
import { fetchDownload } from '../utils/api';

class Review extends React.Component {
    state = {
        reviews: [],
        pagination: {},
        loading: true,
        page: 1
    }

    async componentDidMount() {
        if (this.props.fetch_reviews) {
            if (this.props.location.state && this.props.location.state.currentState) {
                const state = this.props.location.state.currentState
                this.setState(state)
                this.props.fetch_reviews(state, 1)
            } else {
                this.props.fetch_reviews({}, 1)
                this.setState({ loading: false })
            }
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            reviews: props.reviews || [],
            pagination: props.pagination || {},
        }
    }

    onPage(next = false, prev = false) {
        const state = this.state
        if (next) {
            state['page'] = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
        }
        this.setState(state)
        delete state['reviews']
        this.props.fetch_reviews(state, state['page'])
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onClearAll() {
        this.setState({
            notifications: [],
            loading: true,
            search: null,
            startAt: null,
            endAt: null,
            agent: null,
            service: null,
            area: null,
            page: 1,
            refresh: true,
            message: null
        })
        this.props.fetch_reviews({}, 1)
    }

    onFilter(key, value) {
        console.log(key, value)
        const state = this.state
        state[key] = value

        state['page'] = 1

        this.setState(state)
        delete state['reviews']
        this.props.fetch_reviews(state, 1)
    }

    onExport() {
        var api = '/utils/export/reviews'
        const params = this.state

        if (params.search) {
            api = api + '?search=' + params.search
        }
        if (params.status) {
            api = api.indexOf('?') > -1 ? api + '&status=' + params.status : api + '?status=' + params.status
        }
        if (params.endAt) {
            if (api.indexOf('endAt') > -1) {
                const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
                api = api.replace(replace, 'endAt=' + params.endAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
            }
        }
        if (params.startAt) {
            if (api.indexOf('startAt') > -1) {
                const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
                api = api.replace(replace, 'startAt=' + params.startAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
            }
        }

        if (params.area) {
            api = api.indexOf('?') > -1 ? api + '&area=' + params.area : api + '?area=' + params.area
        }
        if (params.agent) {
            api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
        }
        if (params.isArchived) {
            api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
        }

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Reviews_${moment().format('DD-MM-YYYY')}.csv`
                if (params.startAt && params.endAt) {
                    name = `Export_Reviews_${params.startAt}-to-${params.endAt}.csv`
                }
                a.download = name;
                a.click();
            },
            () => {
            }
        );
    }

    render() {
        const currentState = {
            agent: this.state.agent,
            service: this.state.service,
            dates: { startAt: this.state.startAt, endAt: this.state.endAt }
        }

        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                <ReviewActions
                    onSearch={search => this.onFilter('search', search)}
                    onStartAt={startAt => this.onFilter('startAt', startAt)}
                    onEndAt={endAt => this.onFilter('endAt', endAt)}
                    onFilterService={area => this.onFilter('service', area)}
                    onFilterArchived={isArchived => this.onFilter('isArchived', isArchived)}
                    onFilterAgent={agent => {
                        if (agent && agent.value) {
                            this.setState({ areas: agent.value['areas'], sponsors: agent.value['sponsors'] })
                            this.onFilter('agent', agent.value['uuid'])
                        } else {
                            this.onFilter('agent', null)
                        }
                    }}
                    onExport={() => this.onExport()}
                    onClearAll={() => this.props.history.push('/refresh-reviews', { clear: true })}
                    areas={this.state.areas}
                    currentState={currentState}
                    dates={{ startAt: this.state.startAt, endAt: this.state.endAt }}
                    refresh={this.state.refresh}
                    length={this.state.reviews.length}
                />
                <Row className='mx-0'>
                    <Col>
                        <div className='job-table'>
                            <Card small className="mb-4" style={{ position: 'relative' }}>
                                <ReviewTable
                                    data={this.state.reviews}
                                    onPrev={() => this.onPage(false, true)}
                                    onNext={() => this.onPage(true)}
                                    page={this.state.page}
                                    count={this.state.pagination.count}
                                    onClick={(row) => this.props.history.push('/jobs/details', {
                                        row: row['job'], returnTo: '/reviews', currentState: {
                                            ...this.state,
                                            reviews: []
                                        }
                                    })}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        reviews: state.reviews,
        pagination: state.pagination
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_reviews: (params, page) => dispatch(fetch_reviews(params, page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Review));
