import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { colors } from '../../utils/colors';
import { fetchGet, fetchPost } from '../../utils/api';
import * as moment from 'moment-timezone';

class RegenerateInvoice extends React.Component {
    state = {
        agent: '',
        state: '',
        user: null,
        notes: [],
        options: [],
        job: null
    }

    componentDidMount() {
        this.setState({
            job: this.props.job || {},
            user: this.props.user
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.job && this.props.job !== prevProps.job) {
            this.setState({
                job: this.props.job
            })
        }
    }

    onAddNote() {
        fetchPost(`/jobs/add_note/${this.props.job.uuid}`,
            {
                "note": `Regenerated the invoice`,
                "user": this.state.user.uuid,
                "agent": this.state.agent
            },
            (response) => {
            },
            (response) => {
            }
        )
    }

    submit() {
        const data = this.props.job
        if (!data) {
            this.setState({
                warn: true,
                message: 'Please select a complete service call to regenerate the invoice'
            })
        } else {
            this.setState({ warn: true, message: 'Updating...' })
            var api = `/invoices/regenerate/${data['uuid']}`

            fetchGet(api,
                (response) => {
                    this.onAddNote()
                    this.setState({ message: null })
                    this.props.onComplete()
                },
                (response) => {
                    this.setState({ message: response.message, loading: false })
                }
            )
        }
    }

    render() {
        const data = this.props.job || {}

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>Regenerate Invoice: Job No. {data['job_no']}</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        this.submit()
                                                    }}
                                                >
                                                    <Row form>
                                                        <Col md='12' className='form-group'>
                                                            <div className='bg-light p-3 card-small'>
                                                                <div className='px-2 py-1'>
                                                                    Please confirm:
                                                                </div>
                                                                <div className='px-0'>
                                                                    <div className='py-1 d-flex-to-block' >
                                                                        You are about to regenerate an invoice for this job no: {data['job_no']}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : this.state.warn ? colors.ORANGE : colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type='submit'
                                                    >
                                                        Confirm
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user
    }
}

export default connect(mapStateToProps)(RegenerateInvoice);
