import React from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, InputGroup, FormInput, InputGroupText, InputGroupAddon, DatePicker } from 'shards-react';

import RangeDatePicker from '../../common/RangeDatePicker';
import JobStatusFilter from '../jobs/JobStatusFilter';
import SponsorAreaFilter from '../SponsorAreaFilter';
import ServiceAreaFilter from '../ServiceAreaFilter';
import AgentFilter from '../agents/AgentFilter';
import PriorityFilter from '../PriorityFilter';
import * as moment from 'moment-timezone';
import InsurerTypeFilter from '../InsurerTypeFilter';

class JobActions extends React.Component {
    state = {
        open: false,
        dates: {},
        title: '',
        search: '',
        showStatusFilter: false,
        showServiceFilter: false,
        showAgentFilter: false,
        showSponsorFilter: false,
        showPriorityFilter: false,
        showInsurerType: false,
        appointmentDate: null,
        selected: [],
        areas: [],
        sponsors: [],
        insurerTypes: [],
        area: null,
        status: null,
        agent: null,
        insurerType: null,
        priority: null,
        length: 0,
        isArchived : false
    }

    componentDidUpdate(props) {
        if (this.props.selected !== props.selected) {
            this.setState({
                selected: this.props.selected
            })
        }

        if (this.props.sponsors !== props.sponsors) {
            this.setState({
                sponsors: this.props.sponsors
            })
        }

        if (this.props.areas !== props.areas) {
            this.setState({
                areas: this.props.areas
            })
        }

        if (this.props.length !== props.length) {
            this.setState({
                length: this.props.length
            })
        }

        if (this.props.dates && this.props.dates !== props.dates) {
            this.setState({
                dates: this.props.dates
            })
        }

        if (this.props.currentState && this.props.currentState !== props.currentState) {
            const currentState = this.props.currentState
            this.setState({
                area: currentState.area || null,
                status: currentState.status || null,
                // agent: currentState.agent || null,
                priority: currentState.priority || null,
                search: currentState.search || ''
            })
        }

        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({
                area: null,
                status: null,
                agent: null,
                priority: null,
                search: ''
            })
        }
    }

    toggle(title) {
        if (title === 'showUpdate') {
            this.props.onUpdateMultiple()
        } else {
            const state = {
                showStatusFilter: false,
                showServiceFilter: false,
                showAgentFilter: false,
                showPriorityFilter: false,
                showSponsorFilter: false
            }
            if (title) {
                state[title] = !this.state[title]
            }
            this.setState(state);
        }
    }

    render() {
        const hasSelected = this.state.selected.length > 0

        var appointmentDate = this.state.appointmentDate || ''
        if (this.state.appointmentDate) {
            appointmentDate = moment(this.state.appointmentDate).format('DD MMM YYYY')
        }

        return (
            <div className='job-actions'>
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='4' lg='4' className='d-flex'>
                                <RangeDatePicker
                                    dates={this.state.dates}
                                    onStartAt={(startAt) => {
                                        this.props.onStartAt(startAt)
                                    }}
                                    onEndAt={(endAt) => {
                                        this.props.onEndAt(endAt)
                                    }}
                                />

                            </Col>

                            <Col sm='6' md='3' lg='3'>
                                <InputGroup className='d-flex my-auto date-range'>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={this.state.appointmentDate}
                                        onChange={(value) => {
                                            this.props.onAppointmentAt(moment(value).format('YYYY-MM-DD'))
                                        }}
                                        placeholderText={this.state.startDate || 'Appointment Date'}
                                        dropdownMode='select'
                                        value={appointmentDate}
                                        className='text-center'
                                    />
                                    <InputGroupAddon type='append'>
                                        <InputGroupText>
                                            <i className='material-icons'>&#xE916;</i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col sm='6' md='3' lg='3'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search job no." value={this.state.search} onChange={e => {
                                        this.setState({ search: e.target.value })
                                        this.props.onSearch(e.target.value)
                                    }} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='5' className='mb-2'>
                                <ButtonGroup className='d-inline-flex'>
                                    {hasSelected && (
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showUpdate')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Update Multiple</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                    )}
                                    {/* Job Status */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showStatusFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{hasSelected ? 'Set Status' : this.state.status || 'Status'} &darr;</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <JobStatusFilter visible={this.state.showStatusFilter} onSelect={(filter) => {
                                            this.props.onFilterStatus(filter.value)
                                            this.setState({ showStatusFilter: false, status: filter.value ? filter.label : null })
                                        }} status={this.state.status} />
                                    </div>)}
                                    {/* Agent Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showAgentFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{hasSelected ? 'Assign Agent' : this.state.agent || `Agent`} &darr;</span>
                                            {this.state.agent ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <AgentFilter visible={this.state.showAgentFilter} selected={this.state.selected} onSelect={(filter) => {
                                            this.props.onFilterAgent(filter)
                                            this.setState({ showAgentFilter: false, agent: filter.value ? filter.label : null })
                                        }} />
                                    </div>)}
                                    {/* Sponsor Area Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showSponsorFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.sponsorArea || 'Sponsor Area'} &darr;</span>
                                            {this.state.sponsorArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <SponsorAreaFilter visible={this.state.showSponsorFilter} sponsors={this.state.sponsors} onSelect={(filter) => {
                                            this.props.onFilterSponsor(filter.value)
                                            this.setState({ showSponsorFilter: false, sponsorArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}
                                    {/* Service Area Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showServiceFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.serviceArea || 'Service Area'} &darr;</span>
                                            {this.state.serviceArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <ServiceAreaFilter visible={this.state.showServiceFilter} areas={this.state.areas} onSelect={(filter) => {
                                            this.props.onFilterService(filter.value)
                                            this.setState({ showServiceFilter: false, serviceArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}
                                   {/* Insurer Type */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showInsurerType')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.insurerType || 'Insurer Type'} &darr;</span>
                                            {this.state.insurerType ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <InsurerTypeFilter visible={this.state.showInsurerType} onSelect={(filter) => {
                                            this.props.onFilterInsurerType(filter)
                                            this.setState({ showInsurerType: false, insurerType: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}
                                </ButtonGroup>
                            </Col>

                            <Col sm='12' md='12' lg='5' className='mb-2 mb-sm-0'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Priority Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showPriorityFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.priority || 'Priority'} &darr;</span>
                                            {this.state.priority ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <PriorityFilter visible={this.state.showPriorityFilter} onSelect={(filter) => {
                                            this.props.onFilterPriority(filter)
                                            this.setState({ showPriorityFilter: false, priority: filter.value ? filter.title : null })
                                        }} />
                                    </div>)}
                                    {/* Import CSV */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onImport()}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Import &rarr;</span>
                                        </Button>
                                    </div>)}
                                    {/* Export CSV */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onExport()}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Export &rarr;</span>
                                        </Button>
                                    </div>)}
                                    {/* Clear All Filters */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.toggle()
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear</span>
                                        </Button>
                                    </div>)}
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default JobActions;
