import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class MainSidebar extends React.Component {
  state = {
    menuVisible: false,
    sidebarNavItems: []
  }

  static getDerivedStateFromProps(props) {
    return {
      user: props.user,
      menuVisible: props.menuVisible,
      sidebarNavItems: props.navItems
    }
  }

  render() {

    var background_color = this.props.user && this.props.user.sponsor_background_color ? this.props.user.sponsor_background_color : ''

    const classes = classNames(
      'main-sidebar',
      'px-0',
      'col-12',
      this.state.menuVisible && 'open'
    );

    return (
      <Col
        tag='aside'
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
        style={{backgroundColor: background_color}}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} history={this.props.history} />
        {this.props.user && this.props.user.background_color && (<div style={{ height: '20px', backgroundColor: this.props.user.background_color }} />
)}
        <SidebarNavItems history={this.props.history} />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: true
};

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user,
    menuVisible: state.menuVisible,
    navItems: state.navItems
  }
}

export default connect(mapStateToProps)(withRouter(MainSidebar));
