import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';
import { connect } from 'react-redux';


class SponsorAreaFilter extends React.Component {
    state = {
        visible: false,
        sponsors: []
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }

        if (this.props.sponsors !== props.sponsors) {
            this.setState({ sponsors: this.props.sponsors })
        }
    }

    render() {
        var sponsorAreas = []
        if(this.props.user && this.props.user.sponsor_name === 'ARC'){
             sponsorAreas = [
                {
                    sponsor_area_code: 'k19',
                    description: '01 | 02 | 03 | 04 | 05'
                },
                {
                    sponsor_area_code: 'k20',
                    description: '06'
                },

                {
                    sponsor_area_code: 'k21',
                    description: '08'
                },
                {
                    sponsor_area_code: 'k22',
                    description: '09'
                },
                {
                    sponsor_area_code: 'k23',
                    description: '10'
                },
                {
                    sponsor_area_code: 'k24',
                    description: '07'
                },
                {
                    id: null,
                    sponsor_area_code: null,
                    description: 'Remove Filter'
                }
            ]
        }else {
            sponsorAreas = [
                {
                    sponsor_area_code: 'k19',
                    description: '01 | 02 | 03 | 04 | 05'
                },
                {
                    sponsor_area_code: 'k20',
                    description: '06'
                },
                {
                    sponsor_area_code: 'k24',
                    description: '07'
                },
                {
                    id: null,
                    sponsor_area_code: null,
                    description: 'Remove Filter'
                }
            ]

        }
        var areas = sponsorAreas

        if (sponsorAreas && this.state.sponsors && this.state.sponsors.length > 0) {
            areas = areas.filter(area => {
                if (area['sponsor_area_code']) {
                    return this.state.sponsors.indexOf(area['sponsor_area_code'].toUpperCase()) > -1
                }
                return false
            })
        }

        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {areas.map((area, idx) => {
                    if (area.sponsor_area_code) {
                        return (
                            <div key={idx}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: area.description,
                                        value: area.sponsor_area_code
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {area.sponsor_area_code} - {area.description.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }
                    return (
                        <div key={idx}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    description: area.description,
                                    value: null
                                })}>
                                    <p className='mb-0 text-capitalize'>
                                        Remove Filter
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps) (SponsorAreaFilter);