import React from 'react';
import {
    Container, CardBody, Row, Col, Badge, ButtonGroup, Button, Alert
} from "shards-react";
import * as moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { fetchPost } from '../../../utils/api';
import { colors } from '../../../utils/colors';

class NotificationTable extends React.Component {
    state = {
        selected: [],
        disabled: false,
        count: 0
    }

    componentDidUpdate(props) {
        if (this.props.count !== props.count) {
            this.setState({ count: this.props.count })
        }
    }

    onResend(data) {
        var api = '/notifications/resend/' + data['id']

        fetchPost(api,
            {
                job: data['job']['uuid'],
                contact: data['sent_to'],
                state: data['job_state'],
                type: data['sent_to_type'],
                job_no: data['job_no'],
            },
            (response) => {
                if (response && response.status === 200) {
                    this.setState({ message: 'Successfully resent!', error: false, disabled: false })
                    this.props.onReload()
                } else {
                    this.setState({ message: response.message, error: true, disabled: false })
                }
            },
            (response) => {
                this.setState({ message: response.message, error: true, disabled: false })
            },
            true
        )
    }

    fetchColour(key) {
        switch (key) {
            case 'completed':
                return { background: 'rgb(23,198,113)' }
            case 'in_hand_by_agent':
                return { background: 'rgb(255,180,0)' }
            case 'refer_to_defy':
            case 'pickup_for_workshop':
                return { background: '#5a6169' }
            case '':
                return { background: 'rgb(0,123,255)' }
            case 'awaiting_parts':
            case 'awaiting_parts_not_ordered':
            case 'awaiting_parts_ordered':
            case 'awaiting_parts_refer_to_defy':
            case 'assigned_to_agent':
                return { background: 'rgb(0, 184, 216)' }
            default:
                return { color: '#495057', background: 'white', borderColor: '#e1e5eb' }
        }
    }

    render() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Notifications Found</h3>
                        <p>It could be that there are no service calls with sent notifications. </p>
                    </div>
                </Container>
            );
        }
        return (
            <CardBody className="p-0 pb-3" style={{ background: 'transparent' }}>
                {this.state.message && (
                    <Alert style={{ backgroundColor: this.state.error ? colors.RED : colors.GREEN, color: '#ffffff' }}>
                        {this.state.message}
                    </Alert>
                )}
                <Row className='d-flex py-2 px-2 mx-0 row-header border-bottom'>
                    <Col sm='12' md='3' lg='1' className='py-1 px-1'>
                        Date
                    </Col>
                    <Col sm='12' md='3' lg='1' className='py-1 px-1'>
                        Person
                    </Col>
                    <Col sm='12' md='6' lg='2' className='py-1 px-1'>
                        Number
                    </Col>
                    <Col sm='12' md='12' lg='4' className='py-1 px-1'>
                        Message
                    </Col>
                    <Col sm='12' md='6' lg='2' className='py-1 px-1'>
                        Result
                    </Col>
                    <Col sm='12' md='6' lg='1' className='py-1 px-1'>
                        Action
                    </Col>
                </Row>
                {this.props.data.map((row, idx) => {
                    let success = null;
                    if(row['customer_disabled']){
                        success = <Badge theme='warning'>Disabled</Badge>
                    }else{
                        success = row['successful'] ? <Badge theme='success'>Sent</Badge> : <Badge theme='danger'>Failed</Badge>
                    }
                    return (
                        <div key={idx} className='border-bottom'>
                            <Row className='notification-list__item d-flex py-2 px-2 mx-0 row-hightlight' >
                                <Col sm='12' md='3' lg='1' className='px-0'>
                                    <div className='py-1 px-1'>
                                        {moment(row['created_at']).format('YYYY MMM DD')}<br />
                                        {moment(row['created_at']).format('HH:mm')}<br />
                                    </div>
                                </Col>
                                <Col sm='12' md='3' lg='1' className='px-1'>
                                    <div className='py-1 px-0 text-uppercase'>
                                        <Badge theme='light'>{row['sent_to_type']}</Badge>
                                    </div>
                                </Col>
                                <Col sm='12' md='6' lg='2' className='px-0'>
                                    <div className='py-1 px-1'>
                                        {row['sent_to']}
                                    </div>
                                </Col>
                                <Col sm='12' md='12' lg='4' className='px-0'>
                                    <div className='py-1 px-1'>
                                        {row['message_text']}
                                    </div>
                                </Col>
                                <Col sm='12' md='6' lg='2'>
                                    <div className='py-1 px-1'>
                                        {success}
                                    </div>
                                    <div className='py-1 px-1 text-uppercase'>
                                        <Badge style={{ background: this.fetchColour(row['job_state']).background, color: this.fetchColour(row['job_state']).color || 'white' }}> {(row['job_state'] || '').replace(/_/g, ' ')}</Badge>
                                    </div>
                                </Col>
                                <Col sm='12' md='6' lg='1' className='px-0'>
                                    <ButtonGroup size='md'>
                                        <Button theme='secondary' disabled={this.state.disabled} type='button' onClick={() => this.props.onClick(row)}>View Job</Button>
                                        {(!row['successful'] && row['is_latest']) && <Button theme='warning' type='button' style={{ color: '#FFFFFF' }} onClick={() => {
                                            this.setState({ disabled: true })
                                            this.onResend(row)
                                        }}>Re-send</Button>}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody >
        )
    }
}

export default withRouter(NotificationTable)
