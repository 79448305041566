import { fetchGet } from '../utils/api';
import * as moment from 'moment-timezone';

export const toggle_sidebar = () => {
    return (dispatch) => {
        dispatch({
            type: 'TOGGLE_SIDEBAR'
        })
    }
}

export const clear_data = () => {
    return (dispatch) => {
        dispatch({
            type: 'JOBS_LIST',
            metrics: []
        })
    }
}

export const fetch_metrics = (params = {}) => {
    var api = '/metrics/overview'
    if (params.startAt) {
        if (api.indexOf('startAt') > -1) {
            const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
            api = api.replace(replace, 'startAt=' + params.startAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
        }
    }

    if (params.endAt) {
        if (api.indexOf('endAt') > -1) {
            const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
            api = api.replace(replace, 'endAt=' + params.endAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
        }
    }

    if (params.appointmentAt) {
        api = api.indexOf('?') > -1 ? api + '&appointmentAt=' + params.appointmentAt : api + '?appointmentAt=' + params.appointmentAt
    }

    if (params.dateColumn) {
        api = api.indexOf('?') > -1 ? api + '&dateColumn=' + params.dateColumn : api + '?dateColumn=' + params.dateColumn
    }

    return (dispatch) => {
        dispatch({
            type: 'SET_METRICS',
            metrics: {}
        })
        fetchGet(api,
            (data) => {
                dispatch({
                    type: 'SET_METRICS',
                    metrics: data.metrics
                })
            },
            () => {
                dispatch({
                    type: 'SET_METRICS',
                    metrics: {}
                })
            }
        );
    }
}

export const fetch_jobs = (params = {}, page = null) => {

    return (dispatch) => {
        dispatch({
            type: 'JOBS_LIST',
            jobs: []
        })

        var api = '/jobs'
        if (params.search) {
            api = api + '?search=' + params.search
        }

        if (params.status) {
            api = api.indexOf('?') > -1 ? api + '&status=' + params.status : api + '?status=' + params.status
        }
        if (params.jobState) {
            api = api.indexOf('?') > -1 ? api + '&status=' + params.jobState : api + '?status=' + params.jobState
        }
        if (params.priorityEndAt) {
            api = api.indexOf('?') > -1 ? api + '&endAt=' + params.priorityEndAt : api + '?endAt=' + params.priorityEndAt
        }
        if (params.priorityStartAt) {
            api = api.indexOf('?') > -1 ? api + '&startAt=' + params.priorityStartAt : api + '?startAt=' + params.priorityStartAt
        }
        if (params.retrieveAll) {
            api = api.indexOf('?') > -1 ? api + '&retrieveAll=' + params.retrieveAll : api + '?retrieveAll=' + params.retrieveAll
        }
        if (params.endAt) {
            if (api.indexOf('endAt') > -1) {
                const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
                api = api.replace(replace, 'endAt=' + params.endAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
            }
        }
        if (params.startAt) {
            if (api.indexOf('startAt') > -1) {
                const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
                api = api.replace(replace, 'startAt=' + params.startAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
            }
        }
        if (params.appointmentAt) {
            if (api.indexOf('appointmentAt') > -1) {
                const replace = api.slice(api.indexOf('appointmentAt'), api.indexOf('appointmentAt') + 16)
                api = api.replace(replace, 'appointmentAt=' + params.appointmentAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&appointmentAt=' + params.appointmentAt : api + '?appointmentAt=' + params.appointmentAt
            }
        }
        if (params.insureType) {
            var insurer_type = params.insureType.value
            api = api.indexOf('?') > -1 ? api + '&insure_type_all=' + insurer_type : api + '?insure_type_all=' + insurer_type
            
        }

        if (params.sponsor) {
            api = api.indexOf('?') > -1 ? api + '&sponsor=' + params.sponsor : api + '?sponsor=' + params.sponsor
        }
        if (params.service) {
            api = api.indexOf('?') > -1 ? api + '&service=' + params.service : api + '?service=' + params.service
        }
        if (params.jobType) {
            api = api.indexOf('?') > -1 ? api + '&jobType=' + params.jobType : api + '?jobType=' + params.jobType
        }
        if (params.agent) {
            api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
        }
        if (params.isArchived) {
            api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
        }
        if (params.page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + params.page : api + '?page=' + params.page
        }
        if (params.priority) {
            api = api.indexOf('?') > -1 ? api + '&priority=' + params.priority : api + '?priority=' + params.priority
        }

        if (params.invoiceState) {
            api = api.indexOf('?') > -1 ? api + '&invoiceState=' + params.invoiceState : api + '?invoiceState=' + params.invoiceState
        }

        if (params.internalState) {
            api = api.indexOf('?') > -1 ? api + '&internalState=' + params.internalState : api + '?internalState=' + params.internalState
        }

        if (params.jobOrigin) {
            api = api.indexOf('?') > -1 ? api + '&jobOrigin=' + params.jobOrigin : api + '?jobOrigin=' + params.jobOrigin
        }

        if (params.completedAt) {
            api = api.indexOf('?') > -1 ? api + '&completedAt=today' : api + '?completedAt=today'
        }

        if (params.dateColumn) {
            api = api.indexOf('?') > -1 ? api + '&dateColumn=' + params.dateColumn : api + '?dateColumn=' + params.dateColumn
        }
        if (params.is_repeat) {
            api = api.indexOf('?') > -1 ? api + '&is_repeat=' + params.is_repeat : api + '?is_repeat=' + params.is_repeat
        }
        if (params.insure_type) {
            api = api.indexOf('?') > -1 ? api + '&insure_type=' + params.insure_type : api + '?insure_type=' + params.insure_type
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'JOBS_LIST',
                    jobs: body.data || [],
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'JOBS_LIST',
                    jobs: []
                })
            },
            true
        );
    }
}

export const fetch_customers = () => {
    return (dispatch) => {
        fetchGet('/customers',
            (data) => {
                dispatch({
                    type: 'CUSTOMERS_LIST',
                    customers: data
                })
            },
            () => {
                dispatch({
                    type: 'CUSTOMERS_LIST',
                    customers: []
                })
            }
        );
    }
}

export const fetch_service_areas = () => {
    return (dispatch) => {
        fetchGet('/utils/service_areas',
            (data) => {
                dispatch({
                    type: 'SERVICE_AREAS',
                    areas: data
                })
            },
            () => {
                dispatch({
                    type: 'SERVICE_AREAS',
                    areas: []
                })
            }
        );
    }
}

export const fetch_invoices = (params, page) => {
    var api = '/invoices'
    if (params.searchInvoice) {
        api = api + '?invoiceNo=' + params.searchInvoice
    }

    if (params.searchJob) {
        api = api.indexOf('?') > -1 ? api + '&jobNo=' + params.searchJob : api + '?jobNo=' + params.searchJob
    }

    if (params.status) {
        api = api.indexOf('?') > -1 ? api + '&state=' + params.status : api + '?state=' + params.status
    }

    if (params.jobState) {
        api = api.indexOf('?') > -1 ? api + '&state=' + params.jobState : api + '?state=' + params.jobState
    }

    if (params.page) {
        api = api.indexOf('?') > -1 ? api + '&page=' + params.page : api + '?page=' + params.page
    }

    if (params.priorityEndAt) {
        api = api.indexOf('?') > -1 ? api + '&endAt=' + params.priorityEndAt : api + '?endAt=' + params.priorityEndAt
    }
    if (params.priorityStartAt) {
        api = api.indexOf('?') > -1 ? api + '&startAt=' + params.priorityStartAt : api + '?startAt=' + params.priorityStartAt
    }

    if (params.endAt) {
        api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
    }
    if (params.startAt) {
        api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
    }

    if (params.sponsor) {
        api = api.indexOf('?') > -1 ? api + '&sponsor=' + params.sponsor : api + '?sponsor=' + params.sponsor
    }
    if (params.service) {
        api = api.indexOf('?') > -1 ? api + '&service=' + params.service : api + '?service=' + params.service
    }

    if (params.agent) {
        api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
    }

    if (params.isArchived) {
        api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
    }

    if (params.additionalLines) {
        api = api.indexOf('?') > -1 ? api + '&additionalLines=' + params.additionalLines : api + '?additionalLines=' + params.additionalLines
    }

    if (params.jobType) {
        api = api.indexOf('?') > -1 ? api + '&jobType=' + params.jobType : api + '?jobType=' + params.jobType
    }

    return (dispatch) => {
        dispatch({
            type: 'INVOICES_LIST',
            jobs: [],
            pagination: {}
        })
        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'INVOICES_LIST',
                    invoices: body.data,
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'INVOICES_LIST',
                    invoices: []
                })
            },
            true
        );
    }
}

export const fetch_agents = (page = null, params = {}) => {
    return (dispatch) => {
        dispatch({
            type: 'TECHNICIANS_LIST',
            agents: []
        })

        var api = '/agents/service_areas'
        if (page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + page : api + '?page=' + page
        }

        if (params && params.search) {
            api = api.indexOf('?') > -1 ? api + '&search=' + params.search : api + '?search=' + params.search
        }

        if (params.state !== null && params.state !== undefined) {
            api = api.indexOf('?') > -1 ? api + '&state=' + params.state : api + '?state=' + params.state
        }

        if (params.service) {
            api = api.indexOf('?') > -1 ? api + '&service=' + params.service : api + '?service=' + params.service
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'TECHNICIANS_LIST',
                    agents: body.data || [],
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'TECHNICIANS_LIST',
                    agents: []
                })
            },
            true
        );
    }
}

export const fetch_users = (page = null, params = {}) => {
    return (dispatch) => {
        dispatch({
            type: 'USERS_LIST',
            users: []
        })

        var api = '/users'
        if (page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + page : api + '?page=' + page
        }

        if (params && params.search) {
            api = api.indexOf('?') > -1 ? api + '&search=' + params.search : api + '?search=' + params.search
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'USERS_LIST',
                    users: body.data || [],
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'USERS_LIST',
                    users: []
                })
            },
            true
        );
    }
}

export const fetch_notifications = (params = {}, page = null) => {
    return (dispatch) => {
        dispatch({
            type: 'NOTIFICATIONS_LIST',
            notifications: []
        })

        var api = '/notifications'
        if (page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + page : api + '?page=' + page
        }

        if (params.endAt) {
            if (api.indexOf('endAt') > -1) {
                const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
                api = api.replace(replace, 'endAt=' + params.endAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
            }
        }
        if (params.startAt) {
            if (api.indexOf('startAt') > -1) {
                const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
                api = api.replace(replace, 'startAt=' + params.startAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
            }
        }
        if (params.status) {
            api = api.indexOf('?') > -1 ? api + '&status=' + params.status : api + '?status=' + params.status
        }
        if (params.search) {
            api = api.indexOf('?') > -1 ? api + '&search=' + params.search : api + '?search=' + params.search
        }
        if (params.agent) {
            api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
        }
        if (params.service) {
            api = api.indexOf('?') > -1 ? api + '&service=' + params.service : api + '?service=' + params.service
        }
        if (params.isArchived) {
            api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'NOTIFICATIONS_LIST',
                    notifications: body.data,
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'NOTIFICATIONS_LIST',
                    notifications: []
                })
            },
            true
        );
    }
}

export const fetch_reviews = (params = {}, page = null) => {
    return (dispatch) => {
        dispatch({
            type: 'REVIEWS_LIST',
            reviews: []
        })

        var api = '/reviews'
        if (page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + page : api + '?page=' + page
        }

        if (params.endAt) {
            if (api.indexOf('endAt') > -1) {
                const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
                api = api.replace(replace, 'endAt=' + params.endAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
            }
        }
        if (params.startAt) {
            if (api.indexOf('startAt') > -1) {
                const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
                api = api.replace(replace, 'startAt=' + params.startAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
            }
        }
        if (params.search) {
            api = api.indexOf('?') > -1 ? api + '&search=' + params.search : api + '?search=' + params.search
        }
        if (params.agent) {
            api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
        }
        if (params.service) {
            api = api.indexOf('?') > -1 ? api + '&service=' + params.service : api + '?service=' + params.service
        }
        if (params.isArchived) {
            api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'REVIEWS_LIST',
                    reviews: body.data,
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'REVIEWS_LIST',
                    reviews: []
                })
            },
            true
        );
    }
}
