import moment from 'moment-timezone';

var hourly_rate = ''
var width = window.screen.width
if (width > 1000) {
    width = width - 200
}

function additionalItems(items) {
    return items.map(item => {
        return `
        <tr class="item">
                <td style='text-align: left;max-width: 50px'>
                    ${item['quantity']}
                </td>
                <td style='text-align: left;max-width: ${width / 3}px'>
                    <div>
                    ${item['description']}
                    </div>
                </td>
                <td style='text-align: right'>
                    R ${parseFloat(item['amount']).toFixed(2)}
                </td>
                <td style='text-align: right'>
                    R ${parseFloat(item['amount'] * item['quantity']).toFixed(2)}
                </td>
            </tr>
        `
    })
}

const total = (invoice) => {
    var amount = parseFloat(invoice['amount'])
    if (invoice['invoice_items']) {
        invoice['invoice_items'].forEach(item => {
            const sum = parseFloat(parseFloat(item['amount']) * parseInt(item['quantity'], 10))
            amount = parseFloat(amount) + sum
        })
    }

    if (invoice['no_of_hours']) {
        var hours = invoice['no_of_hours'].split(':')[0];
        var minutes = invoice['no_of_hours'].split(':')[1];

        if(hours >= 1){
            amount += hours * 360
        }
        if(minutes >=1 && minutes <= 30){
            amount += 180
        } 
        if(minutes > 30 ){
            amount += 360
        }
    }
    return amount
}

const calculate = (invoice) => {
    var amount = 0
    if (invoice['no_of_hours']) {
        var hours = invoice['no_of_hours'].split(':')[0];
        var minutes = invoice['no_of_hours'].split(':')[1];
        if(hours >= 1){
            amount += hours * 360
        }
        if(minutes > 0 && minutes <= 30){
            amount += 180
        }  
        if(minutes > 30 ){
            amount += 360
        }
    }
    return amount
}

const invoice = (data, settings = {}) => {
    const notes = data['notes'] || {}
    const customer = data['customer'] || {}
    const rate = data['rate'] || {}

    return (
        `
<html>

<head>
    <meta charset="utf-8">
    <link key='font' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,600,600,700,800' rel='stylesheet' />
    <style>
        table {
            color: #323539;
            font-family: "Poppins", sans-serif;
        }
        .invoice-box {
            max-width: 800px;
            min-width: 600px;
            margin: auto;
            padding: 30px;
            border: 1px solid #eee;
            background: white;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            font-size: 14px;
            line-height: 24px;
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
            color: #FFFFFF !important;
        }

        #text {
            display: none;
        }

        .invoice-box table {
            width: 100%;
            line-height: inherit;
            text-align: left;
        }

        .invoice-box table td {
            padding: 5px;
            vertical-align: top;
        }

        .invoice-box table tr td:nth-child(2) {
            text-align: right;
        }

        .invoice-box table tr.top table td {
            padding-bottom: 20px;
            color: #323539 !important;
        }

        .invoice-box table tr.top table td.title {
            font-size: 45px;
            line-height: 45px;
            color: #323539 !important;
        }

        .invoice-box table tr.top table td.total {
            font-size: 45px;
            line-height: 45px;
            color: #323539 !important;
        }

        .invoice-box table tr.information table td {
            padding-bottom: 20px;
            color: #323539 !important;
        }

        .invoice-box table tr.customer table td {
            padding-bottom: 10px;
            padding-top: 10px;
            padding-left: 10px;
            font-weight: 600;
            background-color: #EEF3F6;
            color: #323539 !important;
        }

        .invoice-box table tr.payment table td {
            padding-bottom: 20px;
            padding-top: 0px;
        }

        .invoice-box table tr.heading td {
            background: #EEF3F6;
            border-bottom: 1px solid #ddd;
            font-weight: 600;
            color: #323539 !important;
        }

        .invoice-box table tr.details td {
            padding-bottom: 20px;
            color: #323539 !important;
        }

        .invoice-box table tr.item td {
            border-bottom: 1px solid #eee;
            color: #323539 !important;
        }

        .invoice-box table tr.item.last td {
            border-bottom: none;
            color: #323539 !important;
        }

        .invoice-box table tr.total td:nth-child(2) {
            border-top: 2px solid #eee;
            font-weight: 600;
            color: #323539 !important;
        }

        @media only screen and (max-width: 600px) {
            .invoice-box table tr.top table td {
                width: 100%;
                display: block;
                text-align: center;
            }

            .invoice-box table tr.information table td {
                width: 100%;
                display: block;
                text-align: center;
            }
        }

        /** RTL **/
        .rtl {
            direction: rtl;
            font-family: "Poppins", sans-serif;
        }

        .rtl table {
            text-align: right;
        }

        .rtl table tr td:nth-child(2) {
            text-align: left;
        }
    </style>
</head>

<body>
    <div class="invoice-box">
    <div style="display:none;"></div>
        <table cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="top">
                    <td colspan="4">
                        <table>
                            <tr>
                                <td class="title">

                                    ${data['job_no'].indexOf('ARC') > -1 ? 'Appliance Repair Center (ARC)' : `<img src=${settings['logo_url']}
                                    style="width:100%; max-width:260px;">`}

                                </td>

                                <td>
                                    <span style='font-size: 20px; font-weight: 600'>Invoice No: ${data['invoice_no']}</span><br>
                                    Created: ${moment(data['created_at'] || data['invoice_date']).format('DD/MM/YYYY')}<br>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr class="information">
                    <td colspan="4">
                        <table>
                            <tr>
                                <td>
                                    ${settings['name']}<br>
                                    ${settings['address']}, ${settings['suburb']}<br>
                                    ${settings['city']}, ${settings['code']}<br><br>
                                    Tel: ${settings['tel']}
                                </td>

                                ${data['job_no'].indexOf('ARC') > -1 ? `` : `<td>
                                <span style='font-weight: 600'>DEFY APPLIANCES (PTY) LTD</span><br><br>
                                5A Marconi Road<br>
                                Montague Gardens<br>
                                021 526 3000<br>
                                </td>` }
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr class="customer">
                    <td colspan="4">
                        <table>
                            <tr>
                                <td>
                                    Customer: ${customer['full_name']}<br>
                                    ${customer['address'] || 'unknown'}<br><br>
                                    Mobile: ${customer['contact_mobile'] || '--'}<br>
                                    Home: ${customer['contact_home'] || '--'}<br>
                                    Work: ${customer['contact_work'] || '--'}<br><br>
                                    Serial No: ${data['serial_no'] || '--'}<br>
                                    Model: ${data['model']}<br>
                                    Job No: ${data['job_no'] || '--'}<br>
                                </td>
                                <td></td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr class="heading">
                    <td>
                        Qty
                    </td>
                    <td style='text-align: left;max-width: ${width / 3}px'>
                        Description
                    </td>
                    <td style='text-align: right'>
                        Unit Price
                    </td>
                    <td style='text-align: right'>
                        Total
                    </td>
                </tr>

                <tr class="item">
                    <td style='text-align: left;max-width: 50px'>
                        1
                    </td>
                    <td style='text-align: left;max-width: ${width / 3}px'>
                        <div>
                        ${notes['complete_note'] || 'No completion description supplied'}<br><br>
                        ${data['is_repeat'] ? 'Repeal call 50% less charge' : ''}<br>
                        ${data['job_type'] === 'cash_call' ? `Labour Rate: CASH CALL OUT` : ``}

                        </td>
                    <td style='text-align: right'>
                        R ${parseFloat(data['amount']).toFixed(2)}
                    </td>
                    <td style='text-align: right'>
                        R ${parseFloat(data['amount']).toFixed(2)}
                    </td>
                </tr>


                ${data['no_of_hours'] && (data['no_of_hours'].split(':')[0] > 0 || data['no_of_hours'].split(':')[1]) > 0 ? `<tr class="item">
                        <td style='text-align: left;max-width: 50px'>
                        ${data['no_of_hours'].split(':')[0] > 0 && data['no_of_hours'].split(':')[1] < 1 ? data['no_of_hours'].split(':')[0]  : data['no_of_hours'].split(':')[0] < 1 && data['no_of_hours'].split(':')[1] <= 30 ? 0.5 : data['no_of_hours'].split(':')[0] > 0 && data['no_of_hours'].split(':')[1] < 30 ? data['no_of_hours'].split(':')[0] + ',5': data['no_of_hours'].split(':')[0] > 0 && data['no_of_hours'].split(':')[1] > 30 ? parseFloat(data['no_of_hours'].split(':')[0]) + 1 : data['no_of_hours'].split(':')[0] < 1 && data['no_of_hours'].split(':')[1] > 30 ? 1: ''}

                        </td>
                        <td style='text-align: left;max-width: ${width / 3}px'>
                            <div>
                            Hours at R360/hr
                            </td>
                        <td style='text-align: right'>
                        </td>
                        <td style='text-align: right'>
                            R ${calculate(data).toFixed(2)}
                        </td>
                </tr>` : ''}


                ${data['invoice_items'] && additionalItems(data['invoice_items'])}  

                <tr class="total">
                    <td colSpan=3></td>

                    <td>
                        Total:  R ${parseFloat(total(data)).toFixed(2)}
                    </td>
                </tr>

                <tr class="payment">
                    <td colspan="4">
                        <table>
                            <tr>
                                <td>
                                    <span style='font-weight: 600'>Payment</span><br><br>
                                    Credit Card<br>
                                    Electronic Fund Transfer<br>
                                    Cheque<br><br>
                                    Account Name: ${settings['name']}]<br>
                                    Company No: ${settings['company']}<br>
                                    Bank: ${settings['bank']}<br>
                                    Account No: ${settings['account']}<br>
                                    Reference: ${data['invoice_no']} (Invoice No)<br>
                                    Branch Code: ${settings['branch']}<br><br>
                                    Please send proof of payments to<br>
                                    <span style='font-weight: 600'> ${settings['proof']}</span><br>
                                </td>
                                <td></td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr class="heading">
                    <td colSpan=4 style='text-align: center'>
                        3 MONTHS UNCONDITIONAL GUARANTEE ON WORKMANSHIP
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</body>
</html>
        `
    )
}

export default invoice