import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';

const lines = [
    {
        value: 'warranty',
        label: 'Warranty'
    },
    {
        value: 'cash',
        label: 'Cash'
    },
    {
        value: null,
        label: 'Remove Filter'
    }
]

class JobTypeFilter extends React.Component {
    state = {
        visible: false,
        jobType: null
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }

        if (this.props.jobType !== props.jobType) {
            this.setState({ jobType: this.props.jobType })
        }
    }

    render() {
        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {lines.map((line, codex) => {
                    if (line.value) {
                        return (
                            <div key={codex}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: line.line,
                                        value: line.value
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {line.label}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }
                    return (
                        <div key={codex}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    description: line.label,
                                    value: null
                                })}>
                                    <p className='mb-0 text-capitalize'>
                                        Remove Filter
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }
}

export default JobTypeFilter;