import React from "react";
import { Container, Row, Col, Button } from "shards-react";
import HTMLInvoice from '../utils/invoice';
import { withRouter } from 'react-router-dom';
import { fetchGet } from "../utils/api";

class ViewInvoice extends React.Component {
    state = {
        data: {},
        settings: {},
        showClose: false,
        currentState: {},
        scroll: null,
        returnTo: null
    }

    componentDidMount() {
        this.fetchInvoice()
        this.fetchSettings()
        if (this.props.location.state) {
            this.setState({
                data: this.props.location.state.row || {},
                currentState: this.props.location.state.currentState || null,
                scroll: this.props.location.state.scroll || null,
                returnTo: this.props.location.state.returnTo || null
            })
        }
    }

    static getDerivedStateFromProps(props) {
        if (!props.location.state) {
            props.history.push('/invoices')
        }
        return {
            data: props.location.state.row || {},
            currentState: props.location.state.currentState || null,
            scroll: props.location.state.scroll || null,
            returnTo: props.location.state.returnTo || null
        }
    }

    fetchInvoice() {
        const invoice = HTMLInvoice(this.state.data, this.state.settings)
        return invoice
    }

    fetchSettings() {
        var api = '/utils/settings'

        fetchGet(api,
            (response) => {
                this.setState({ settings: response })
            },
            (response) => {
                this.setState({ message: response.message, error: true })
            }
        )
    }

    render() {
        const { currentState, scroll } = this.state

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4 px-4">
                    <Col md='10'><h6 className='m-0'>Invoice Details</h6></Col>
                    <Col md='2'>
                        <Button theme='secondary' type='button' style={{ float: 'right' }}
                            onClick={() => {
                                if (this.state.returnTo) {
                                    this.props.history.push(this.state.returnTo, {
                                        currentState: currentState,
                                        scroll: scroll
                                    })
                                } else {
                                    this.props.history.goBack()
                                }
                            }}

                        > Go Back
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div dangerouslySetInnerHTML={{ __html: this.fetchInvoice() }} />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(ViewInvoice)
