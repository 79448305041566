import React from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    ButtonGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button,
    Container
} from 'shards-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { fetchPost, fetchGet } from '../../../utils/api';
import { colors } from '../../../utils/colors';
import PageTitle from '../../common/PageTitle';
import { withRouter } from 'react-router-dom';


class JobCapture extends React.Component {
    
    state = {
        customer_full_name: '',
        customer_home_number: '',
        customer_mobile_number: '',
        customer_work_number: '',
        model_no: '',
        fault_note: '',
        special_instructions: '',
        service_area: null,
        retailer: '',
        address: '',
        retailer_contact: '',
        disabled : true,
        message : '',
        job_origin : '',
        sponsor_org: '',
        sponsor: '',
        job_type: '',
        is_loading: false,
        areas : [],
        insure: ''
    }

    captureJob() {
        this.setState({
            is_loading : true
        })
        var api = '/utils/capture/job'

        this.state.service_org = this.props.user.service_org
        this.state.sponsor = this.props.user.sponsor
        
        if(this.state.service_area === ''){
            this.setState({service_area_error : 'Please select service area below'})
            return
        }

        if(this.state.job_type === ''){
            this.setState({call_type_error : 'Please select call type'})
            return
        }


        fetchPost(api,
            {
                job: this.state
            },
            (data) => {
                this.setState({
                    message: 'Job Successfully Captured. Job No. (' + data.job_no + ')',
                    is_loading: false
                })
            },
            (response) => {
                console.log('failed')
                console.log(response)
                this.setState({
                    message: response.message
                })
            }
        );

        this.setState({
            customer_full_name: '',
            customer_home_number: '',
            customer_mobile_number: '',
            customer_work_number: '',
            model_no: '',
            fault_note: '',
            special_instructions: '',
            service_area: '',
            retailer: '',
            address: '',
            retailer_contact: '',
            disabled : true,
            message : '',
            job_origin : '',
            job_type: '',
            sponsor_org: '',
            sponsor: '',
            is_loading: false,
            areas : [],
            insure_type : ''
        })
    }

    fetchUpdateStates() {
        this.state.areas = [{'sponsor_area_code':'','service_area_code':'','description':''}].concat(this.props.areas);
        return this.state.areas.map(i => {
            return <option value={i.sponsor_area_code}>{i.description}</option>
        })
    }

    fetchJobTypes() {
        const serviceAreas = [{'jobType':'','description':''},
        {'jobType':'','description':''}].concat(this.props.areas);
        return serviceAreas.map(i => {
            return <option value={i.sponsor_area_code}>{i.service_area_code} - {i.description}</option>
        })
    }

    render() {

        return (
            <div>
            <Container fluid className="main-content-container" style={{ position: 'relative' }}>
            <Row noGutters className="page-header py-4">
                    <PageTitle title="Capture" subtitle='Job'md="12"
                        className="ml-sm-auto mr-sm-auto"
                    />
                </Row>
                <Card small className='mb-4'>
                    <CardHeader className='border-bottom'>
                        
                        <Row>
                            <Col md='6'><h6 className='m-0'>Job Details</h6></Col>
                            <Col md='6'>
                                <ButtonGroup style={{ float: 'right' }}>
                                <Button theme='danger' type='button' onClick={() => this.props.history.push('/overview')}> Cancel</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className='p-3'>
                            <Row>
                                <Col>
                                    <Form
                                        onSubmit={async (e) => {
                                            e.preventDefault()
                                            this.captureJob()
                                        }}
                                    >


                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='customer'>Customer Full Names<span style={{color:'red'}}>* </span>{this.state.customer_full_name_error && (<span style={{color:'red'}}>{this.state.customer_full_name_error}</span>)}</label>
                                                <FormInput
                                                    id='customer'
                                                    placeholder='Customer Name'
                                                    required
                                                    autocomplete="off"
                                                    value={this.state.customer_full_name}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            disabled : true,
                                                            message : null
                                                        }) 
                                                        if (e.target.value) {
                                                            this.setState({
                                                                customer_full_name: e.target.value
                                                            })
                                                        }
                                                        if(e.target.value.length < 5){
                                                           this.setState({
                                                            customer_full_name_error : 'Minimum of 5 characters allowed.'
                                                           })          
                                                        }else{
                                                            this.setState({
                                                                customer_full_name_error : null
                                                            })
                                                            this.setState({
                                                                 disabled : false
                                                            })   
                                                        }

                                                        if(e.target.value === ''){
                                                            this.setState({
                                                                customer_full_name_error : null,
                                                                customer_full_name: null
                                                            })
                                                         }
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Mobile No. <span style={{color:'red'}}>* </span>{this.state.customer_mobile_number_error && (<span style={{color:'red'}}>{this.state.customer_mobile_number_error}</span>)}</label>
                                                <FormInput
                                                    id='contact_mobile'
                                                    required
                                                    value={this.state.customer_mobile_number}
                                                    type='number'
                                                    min="0"
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({
                                                                customer_mobile_number: e.target.value,
                                                                message : null
                                                            })
                                                        }
                                                        this.setState({
                                                            disabled : true
                                                        }) 
                                                        if(e.target.value.length != 10){
                                                            this.setState({
                                                             customer_mobile_number_error : 'Invalid phone number.'
                                                            })          
                                                         }else{
                                                             this.setState({
                                                                customer_mobile_number_error : null
                                                             })
                                                             this.setState({
                                                                  disabled : false
                                                             })   
                                                         }
                                                         if(e.target.value === ''){
                                                            this.setState({
                                                                customer_mobile_number_error : null,
                                                                customer_mobile_number: null
                                                            })
                                                         }
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Home No. <span>(Optional) </span>{this.state.customer_home_number_error && (<span style={{color:'red'}}>{this.state.customer_home_number_error}</span>)}</label>
                                                <FormInput
                                                    id='contact_home'
                                                    type='number'
                                                    value={this.state.customer_home_number}
                                                    min="0"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            disabled : true,
                                                            message : null
                                                        }) 
                                                        if (e.target.value) {
                                                            this.setState({
                                                                customer_home_number: e.target.value
                                                            })
                                                        }
                                                        if(e.target.value.length != 10){
                                                           this.setState({
                                                            customer_home_number_error : 'Invalid phone number.'
                                                           })          
                                                        }else{
                                                            this.setState({
                                                                customer_home_number_error : null
                                                            })
                                                            this.setState({
                                                                 disabled : false
                                                            })   
                                                        }
                                                        if(e.target.value === ''){
                                                            this.setState({
                                                                customer_home_number : null,
                                                                customer_home_number_error: null,
                                                                disabled: false

                                                            })
                                                         }
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Work No. <span>(Optional)</span> {this.state.customer_work_number_error && (<span style={{color:'red'}}>{this.state.customer_work_number_error}</span>)}</label>
                                                <FormInput
                                                    id='contact_work'
                                                    type='number'
                                                    value={this.state.customer_work_number}
                                                    min="0"
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({
                                                                customer_work_number: e.target.value
                                                            })
                                                        }
                                                        this.setState({
                                                            disabled : true,
                                                            message : null
                                                        }) 
                                                        if(e.target.value.length != 10){
                                                            this.setState({
                                                                customer_work_number_error : 'Invalid phone number.'
                                                            })          
                                                         }else{
                                                             this.setState({
                                                                customer_work_number_error : null,
                                                                disabled : false
                                                             })
                                                         }

                                                         if(e.target.value === ''){
                                                            this.setState({
                                                                customer_work_number : null,
                                                                customer_work_number_error: null,
                                                                disabled: false
                                                            })
                                                         }
                                                    }}                                               
                                                     />
                                            </Col>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='feAddress'>Customer Address <span style={{color:'red'}}>*</span></label>
                                                <FormInput
                                                    id='address'
                                                    placeholder='Customer Address'
                                                    required
                                                    value={this.state.address}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            address: e.target.value,
                                                            message : null
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <hr/>

                                        <Row form>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='feAddress'>Model No. <span> (Optional) </span></label>
                                                <FormInput
                                                    id='model'
                                                    placeholder='Model Number'
                                                    value={this.state.model_no}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({
                                                                model_no: e.target.value,
                                                                message : null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='issue'>Fault Note <span style={{color:'red'}}>*</span></label>
                                                <FormTextarea
                                                    placeholder='Issue'
                                                    rows='4'
                                                    required
                                                    value={this.state.fault_note}
                                                    onChange={(e) => {
                                                            this.setState({
                                                                fault_note: e.target.value,
                                                                message : null
                                                            })
                                                        
                                                    }}
                                                />
                                            </Col>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='issue'>Special Instructions <span>(Optional)</span></label>
                                                <FormTextarea
                                                    placeholder='Special Instructions'
                                                    rows='4'
                                                    onChange={(e) => {
                                                        this.setState({
                                                            special_instructions: e.target.value,
                                                            message : null
                                                        })
                                                    }}                                            
                                                 />
                                            </Col>
                                        </Row>

                                        <hr/>


                                        <Row form>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='sponsor_area'>Service Area <span style={{color:'red'}}>* </span>  {this.state.service_area_error && (<span style={{color:'red'}}>{this.state.service_area_error}</span>)}</label>
                                                <FormSelect
                                                    id='service_area'
                                                    required
                                                    onChange={(e) => {
                                                        const selectedArea = e.target.value.toLowerCase();
                                                        if (selectedArea === 'k21' ||selectedArea === 'k22') {
                                                            this.setState({job_type : 'walk_in',service_area :selectedArea,service_area_error: null,
                                                            message : null})
                                                        }else  {
                                                            this.setState({job_type : 'call_out',service_area : selectedArea,service_area_error: null,
                                                            message : null})
                                                        }
                                                    }}
                                                >
                                                    {this.fetchUpdateStates()}

                                                </FormSelect>
                                            </Col>

                                            <Col md='3' className='form-group'>
                                                <label htmlFor='job_type'>Call Type {this.state.call_type_error && (<span style={{color:'red'}}>{this.state.call_type_error}</span>)}</label>
                                                <FormSelect
                                                    id='call_type'
                                                    required
                                                    value={this.state.job_type}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            job_type: e.target.value.toLowerCase(),
                                                            message : null,
                                                            disabled : false,
                                                        })
                                                        if(e.target.value === ''){
                                                            this.setState({
                                                                call_type_error : null,
                                                                call_type_error: null
                                                            })
                                                         }
                                                    }}>
                                                    <option value=''>-</option>
                                                    <option value='call_out'>Call Out</option>
                                                    <option value='warranty'>Warranty</option>
                                                    <option value='walk_in'>Walk In</option>
                                                </FormSelect>
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='insure'>Insurer {this.state.call_type_error && (<span style={{color:'red'}}>{this.state.call_type_error}</span>)}</label>
                                                <FormSelect
                                                    value={this.state.insure_type}
                                                    id='insure_type'
                                                    onChange={(e) => {
                                                        this.setState({
                                                            insure_type: e.target.value.toLowerCase(),
                                                            message : null,
                                                            disabled : false,
                                                        })
                                                    }}>
                                                    <option value=''>-</option>
                                                    <option value='bosch'>Bosch</option>
                                                    <option value='insurance'>Insurance</option>
                                                    <option value='candy_appliances'>Candy Appliances</option>
                                                    <option value='samsung'>Samsung</option>
                                                </FormSelect>
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='retailer_name'>Retailer <span> (Optional)</span></label>
                                                <FormInput
                                                    id='retailer_name'
                                                    placeholder='Retailer'
                                                    value={this.state.retailer}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            retailer: e.target.value,
                                                            message : null
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='retailer_contact'>Retailer Contact <span> (Optional)</span> {this.state.retailer_contact_error && (<span style={{color:'red'}}>{this.state.retailer_contact_error}</span>)}</label>
                                                <FormInput
                                                    id='retailer_contact'
                                                    value={this.state.retailer_contact}
                                                    type="number"
                                                    min="0"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            retailer_contact: e.target.value,
                                                            message : null
                                                        })

                                                        this.setState({
                                                            disabled : true
                                                        }) 
                                                        if(e.target.value.length != 10){
                                                            this.setState({
                                                                retailer_contact_error : 'Invalid phone number.'
                                                            })          
                                                         }else{
                                                             this.setState({
                                                                retailer_contact_error : null
                                                             })
                                                             this.setState({
                                                                  disabled : false
                                                             })   
                                                         }

                                                         if(e.target.value === ''){
                                                            this.setState({
                                                                retailer_contact_error : null,
                                                                disabled: false
                                                            })
                                                         }
                                                    }}
                                                />
                                            </Col>
                                        </Row>                           
                                                                                
                                        <Row>
                                            <Col md='6'>
                                                <ButtonGroup style={{ float: 'left' }}>
                                                <Button theme='success'
                                                 type='submit'
                                                 disabled={this.state.disabled} > Submit</Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        
                                        <hr/>

                                        {this.state.is_loading && (<Row>
                                            <Col md='6'>
                                                <p><strong>Adding Job, Please Wait ...</strong></p>
                                            </Col>
                                        </Row>)}

                                        <Row>
                                            <Col md='6'>
                                                <p><strong>{this.state.message}</strong></p>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>


                        </ListGroupItem>
                    </ListGroup>
                </Card >
            </Container>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps) (withRouter(JobCapture));

