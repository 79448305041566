import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import UserTable from '../components/libs/users/UserTable';
import UserActions from '../components/libs/users/UserActions';
import UserPopup from '../components/libs/users/UserPopup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_users } from '../store/action';

class ManageUsers extends React.Component {
    state = {
        users: [],
        selected: {},
        pagination: {},
        showModel: false,
        viewOnly: false,
        page: 1
    }

    async componentDidMount() {
        if (this.props.fetch_users) {
            this.props.fetch_users(1)
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            pagination: props.pagination || {},
            users: props.users || []
        }
    }

    onPage(next = false, prev = false) {
        const state = this.state
        var page = null
        if (next) {
            state['page'] = state.page + 1
            page = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
            page = state.page - 1
        }
        this.setState(state)
        delete state['users']
        this.props.fetch_users(state['page'])
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onFilter(key, value) {
        const state = this.state
        state[key] = value

        state['page'] = 1

        this.setState(state)
        delete state['users']
        this.props.fetch_users(1, state)
    }

    render() {
        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                {this.state.showModel && <UserPopup
                    data={this.state.selected}
                    viewOnly={this.state.viewOnly}
                    onCancel={() => {
                        this.setState({ showModel: false, page: 1 })
                        this.props.fetch_users(1)
                    }}
                    onSuccess={() => {
                        this.setState({ showModel: false })
                        this.props.fetch_users(1)
                    }}
                />}
                <UserActions
                    onAddUser={() => this.setState({ user: null, showModel: true })}
                    onSearch={search => this.onFilter('search', search)}
                    onClearAll={() => this.props.history.push('/refresh-users', { clear: true })}
                />
                <Row className='mx-0'>
                    <Col>
                        <div className='job-table'>
                            <Card small className="mb-4" style={{ position: 'relative' }}>
                                <UserTable
                                    data={this.state.users}
                                    onSelect={(selected) => this.setState({ selected, showModel: true, viewOnly: false })}
                                    onSelectView={(selected) => this.setState({ selected, showModel: true, viewOnly: true })}
                                    onPrev={() => this.onPage(false, true)}
                                    onNext={() => this.onPage(true)}
                                    page={this.state.page}
                                    count={this.state.pagination.count}
                                    onSuccess={() => this.props.fetch_users(1)}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        users: state.users,
        pagination: state.pagination
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_users: (page, params) => dispatch(fetch_users(page, params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageUsers));
