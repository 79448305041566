// import React from "react";
// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     CardBody,
//     NavbarBrand,
//     Form,
//     FormInput,
//     FormGroup,
//     InputGroup,
//     InputGroupAddon,
//     InputGroupText,
//     Button,
//     Alert
// } from "shards-react";
// import { fetchPost } from '../utils/api';
// import { saveSession, clearSession } from '../utils/cookies';
// import { connect } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
// import { colors } from '../utils/colors';

// const IMG_LOGO = require('../assets/images/s4biz-white.png').default;

// class Login extends React.Component {
//     state = {
//         email: '',
//         password: ''
//     }

//     componentDidMount() {
//         clearSession()
//         this.props.dispatch({ type: 'LOGOUT' })
//     }


//     render() {
//         return (
//             <Container fluid className='main-content-container px-4'>
//                 <NavbarBrand
//                     className='w-100 mr-0'
//                     href='#'
//                     style={{ lineHeight: '25px', padding: '8px 0' }}
//                 >
//                     <div className='m-auto'>
//                         <img
//                             id='main-logo'
//                             className='d-inline-block align-top mr-1'
//                             style={{ maxWidth: '160px' }}
//                             src={IMG_LOGO}
//                             alt='S4Biz Logo'
//                         />
//                     </div>
//                 </NavbarBrand>
//                 <Row>
//                     <Col lg="12" className="mb-4">
//                         <Card small className="mb-4">
//                             <CardBody className="p-4 pb-3">
//                                 <Col
//                                     sm='12' md='12'
//                                 >
//                                     <h4><strong className='d-block mb-2'>Sign in to S4Biz</strong></h4>
//                                     <p className='text-muted d-block mb-4'>A powerful service desk for admins</p>
//                                     <Form
//                                         onSubmit={(e) => {
//                                             e.preventDefault()
//                                             this.setState({ loading: true, message: null })
//                                             fetchPost('/users/login',
//                                                 { email: this.state.email, password: this.state.password },
//                                                 // { email: this.state.email, password: 'password01' },
//                                                 (response) => {
//                                                     if (response && response.user) {
//                                                         saveSession(response.user)
//                                                         this.props.dispatch({ type: 'SET_USER', user: response.user })
//                                                         this.props.history.push('/')
//                                                     } else {
//                                                         this.setState({ message: 'User not found, please re-check email', loading: false })
//                                                     }
//                                                 },
//                                                 (response) => {
//                                                     this.setState({ message: response.message, loading: false })
//                                                 }
//                                             )
//                                         }}
//                                     >
//                                         <FormGroup>
//                                             <InputGroup className='mb-3'>
//                                                 <InputGroupAddon type='prepend'>
//                                                     <InputGroupText>@</InputGroupText>
//                                                 </InputGroupAddon>
//                                                 <FormInput
//                                                     type='email'
//                                                     placeholder='eg: account@example.com'
//                                                     required
//                                                     onChange={(e) => {
//                                                         this.setState({
//                                                             email: e.target.value
//                                                         })
//                                                     }}
//                                                 />
//                                             </InputGroup>
//                                         </FormGroup>
//                                         <FormGroup>
//                                             <FormInput
//                                                 type='password'
//                                                 placeholder='Enter password'
//                                                 required
//                                                 value={this.state.password}
//                                                 onChange={(e) => {
//                                                     this.setState({
//                                                         password: e.target.value
//                                                     })
//                                                 }}
//                                             />
//                                         </FormGroup>
//                                         {this.state.loading && <p>Signing you in...Server may take a minute to start up</p>}
//                                         {this.state.message && (
//                                             <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
//                                                 {this.state.message}
//                                             </Alert>
//                                         )}
//                                         <Button type='submit'>Sign in to Account</Button> 
//                                     </Form>
//                                 </Col>
//                             </CardBody>
//                         </Card>
//                     </Col>
//                 </Row>
//             </Container>
//         )
//     }
// }

// export default connect()(withRouter(Login));


// import React from "react";
// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     CardBody,
//     NavbarBrand,
//     Form,
//     FormInput,
//     FormGroup,
//     InputGroup,
//     InputGroupAddon,
//     InputGroupText,
//     Button,
//     Alert
// } from "shards-react";
// import { fetchPost } from '../utils/api';
// import { saveSession, clearSession } from '../utils/cookies';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { colors } from '../utils/colors';

// const IMG_LOGO = require('../assets/images/s4biz-white.png').default;

// class Login extends React.Component {
//     state = {
//         email: '',
//         password: ''
//     }

//     componentDidMount() {
//         clearSession()
//         this.props.dispatch({ type: 'LOGOUT' })
//     }

//     render() {
//         return (
//             <Container fluid className='main-content-container px-4'>
//                 <NavbarBrand
//                     className='w-100 mr-0'
//                     href='#'
//                     style={{ lineHeight: '25px', padding: '8px 0' }}
//                 >
//                     <div className='m-auto'>
//                         <img
//                             id='main-logo'
//                             className='d-inline-block align-top mr-1'
//                             style={{ maxWidth: '160px' }}
//                             src={IMG_LOGO}
//                             alt='S4Biz Logo'
//                         />
//                     </div>
//                 </NavbarBrand>
//                 <Row>
//                     <Col lg="12" className="mb-4">
//                         <Card small className="mb-4">
//                             <CardBody className="p-4 pb-3">
//                                 <Col
//                                     sm='12' md='12'
//                                 >
//                                     <h4><strong className='d-block mb-2'>Sign in to S4Biz</strong></h4>
//                                     <p className='text-muted d-block mb-4'>A powerful service desk for admins</p>
//                                     <Form
//                                         onSubmit={(e) => {
//                                             e.preventDefault()
//                                             this.setState({ loading: true, message: null })
//                                             fetchPost('/users/login',
//                                                 { email: this.state.email, password: this.state.password },
//                                                 // { email: this.state.email, password: 'password01' },
//                                                 (response) => {
//                                                     if (response && response.user) {
//                                                         saveSession(response.user)
//                                                         this.props.dispatch({ type: 'SET_USER', user: response.user })
//                                                         this.props.history.push('/')
//                                                     } else {
//                                                         this.setState({ message: 'User not found, please re-check email', loading: false })
//                                                     }
//                                                 },
//                                                 (response) => {
//                                                     this.setState({ message: response.message, loading: false })
//                                                 }
//                                             )
//                                         }}
//                                     >
//                                         <FormGroup>
//                                             <InputGroup className='mb-3'>
//                                                 <InputGroupAddon type='prepend'>
//                                                     <InputGroupText>@</InputGroupText>
//                                                 </InputGroupAddon>
//                                                 <FormInput
//                                                     type='email'
//                                                     placeholder='eg: account@example.com'
//                                                     required
//                                                     onChange={(e) => {
//                                                         this.setState({
//                                                             email: e.target.value
//                                                         })
//                                                     }}
//                                                 />
//                                             </InputGroup>
//                                         </FormGroup>
//                                         <FormGroup>
//                                             <FormInput
//                                                 type='password'
//                                                 placeholder='Enter password'
//                                                 required
//                                                 value={this.state.password}
//                                                 onChange={(e) => {
//                                                     this.setState({
//                                                         password: e.target.value
//                                                     })
//                                                 }}
//                                             />
//                                         </FormGroup>
//                                         {this.state.loading && <p>Signing you in...Server may take a minute to start up</p>}
//                                         {this.state.message && (
//                                             <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
//                                                 {this.state.message}
//                                             </Alert>
//                                         )}
//                                         <Button type='submit'>Sign in to Account</Button>
//                                     </Form>
//                                 </Col>
//                             </CardBody>
//                         </Card>
//                     </Col>
//                 </Row>
//             </Container>
//         )
//     }
// }

// export default connect()(withRouter(Login));


import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    NavbarBrand,
    Form,
    FormInput,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Alert
} from "shards-react";
import { fetchPost } from '../utils/api';
import { saveSession, clearSession } from '../utils/cookies';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { colors } from '../utils/colors';

const IMG_LOGO = require('../assets/images/s4biz-white.png').default;

class Login extends React.Component {
    state = {
        email: '',
        password: '',
        uuid: '',
        showReset: false
    }

    componentDidMount() {
        clearSession()
        this.props.dispatch({ type: 'LOGOUT' })
    }

    renderReset() {
        return (
            <CardBody className="p-4 pb-3">
                <Col
                    sm='12' md='12'
                >
                    <h4><strong className='d-block mb-2'>Reset Password</strong></h4>
                    <p className='text-muted d-block mb-4'>Your account requires a password reset</p>
                    <Form
                        autocomplete="off"
                        onSubmit={(e) => {
                            e.preventDefault()
                            this.setState({ loading: true })
                            if (!this.state.reset) {
                                this.setState({ loading: false, message: 'Please enter your reset password' })
                            } else if (this.state.reset === this.state.resetConfirm) {
                                fetchPost('/users/reset_password',
                                    {
                                        uuid: this.state.uuid,
                                        temp_password: this.state.password,
                                        password: this.state.reset,
                                        email: this.state.email
                                    },
                                    (response) => {
                                        if (response) {
                                            var areas = response.user.areas
                                            const user = response.user
                                            delete user['areas']
                                            saveSession(user)
                                            this.props.dispatch({ type: 'SET_USER', user: user })
                                            this.props.dispatch({ type: 'SERVICE_AREAS_LIST', areas: areas })
                                            this.props.history.push('/')
                                        } else {
                                            this.setState({ loading: false, message: 'Error fetching user' })
                                        }
                                    },
                                    (response) => {
                                        this.setState({ loading: false, message: response.message })
                                    }
                                )
                            } else {
                                this.setState({ loading: false, message: 'Passwords do not match' })
                            }
                        }}
                    >
                        <input type='hidden' autocomplete="off" />
                        <FormGroup>
                            <FormInput
                                type='password'
                                placeholder='New Password'
                                required
                                minlength={6}
                                value={this.state.reset}
                                onChange={(e) => {
                                    this.setState({
                                        reset: e.target.value
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormInput
                                type='password'
                                placeholder='Confirm Password'
                                required
                                minlength={6}
                                value={this.state.resetConfirm}
                                onChange={(e) => {
                                    this.setState({
                                        resetConfirm: e.target.value
                                    })
                                }}
                            />
                        </FormGroup>
                        {this.state.loading && <p>Resetting and signing you in...</p>}
                        {this.state.message && (
                            <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                {this.state.message}
                            </Alert>
                        )}
                        <Button type='submit'>Reset and Signin</Button>
                    </Form>
                </Col>
            </CardBody>
        )
    }

    render() {
        return (

            <Container fluid className='main-content-container px-4'>
            <NavbarBrand
                className='w-100 mr-0'
                href='#'
                style={{ lineHeight: '25px', padding: '8px 0' }}
            >
                <div className='m-auto'>
                    <img
                        id='main-logo'
                        className='d-inline-block align-top mr-1'
                        style={{ maxWidth: '160px' }}
                        src={IMG_LOGO}
                        alt='S4Biz Logo'
                    />
                </div>
            </NavbarBrand>
            <Row>
                <Col lg="12" className="mb-4">
                    <Card small className="mb-4">
                        <CardBody className="p-4 pb-3">
                            <Col
                                sm='12' md='12'
                            >
                                {this.state.showReset ? this.renderReset() : this.renderLogin()}
                            </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderLogin() {
        return (
            <div>
            <h4><strong className='d-block mb-2'>Sign in to S4Biz</strong></h4>
                                    <p className='text-muted d-block mb-4'>A powerful service desk for admins</p>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            this.setState({ loading: true, message: null })
                                            fetchPost('/users/login',
                                                { email: this.state.email, password: this.state.password },
                                                // { email: this.state.email, password: 'password01' },
                                                
                                                (response) => {
                                                    console.log(response)
                                                    if (response.user.reset_password) {
                                                        this.setState({ loading: false, uuid: response.uuid, showReset: true, email: this.state.email, reset: '', resetConfirm: '' })
                                                    }else if (response && response.user) {
                                                        saveSession(response.user)
                                                        this.props.dispatch({ type: 'SET_USER', user: response.user })
                                                        this.props.history.push('/')
                                                    } else {
                                                        this.setState({ message: 'User not found, please re-check email', loading: false })
                                                    }
                                                },
                                                (response) => {
                                                    this.setState({ message: response.message, loading: false })
                                                }
                                            )
                                        }}
                                    >
                                        <FormGroup>
                                            <InputGroup className='mb-3'>
                                                <InputGroupAddon type='prepend'>
                                                    <InputGroupText>@</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput
                                                    type='email'
                                                    placeholder='eg: account@example.com'
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            email: e.target.value
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormInput
                                                type='password'
                                                placeholder='Enter password'
                                                required
                                                value={this.state.password}
                                                onChange={(e) => {
                                                    this.setState({
                                                        password: e.target.value
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                        {this.state.loading && <p>Signing you in...Server may take a minute to start up</p>}
                                        {this.state.message && (
                                            <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                                {this.state.message}
                                            </Alert>
                                        )}

                                        <Button type='submit'>Sign in to Account</Button> <Link to="/auth/forgot-password" relative="path">Forgot Password ?</Link>

                    </Form>
            </div>

        )
    }
}

export default connect()(withRouter(Login));
