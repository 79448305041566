import React from 'react';
import { Nav } from 'shards-react';

import SidebarNavItem from './SidebarNavItem';
import SidebarNavInnerItem from './SidebarNavInnerItem';
import { toggle_sidebar } from '../../../store/action';
import { connect } from 'react-redux';

class SidebarNavItems extends React.Component {
  state = {
    activeRoute: null,
    user: {},
    navItems: []
  }

  static getDerivedStateFromProps(props) {
    return {
      user: props.user
    }
  }

  handleToggleSidebar() {
    if (this.props.toggle_sidebar) {
      this.props.toggle_sidebar()
    }
  }

  onRouteActive(route) {
    if (this.state.activeRoute === route) {
      this.setState({
        activeRoute: null
      })
    } else {
      this.setState({
        activeRoute: route
      })
    }
  }

  renderInnerRoutes(item) {
    return item.routes.map((inner, i) => (
      <SidebarNavInnerItem key={i} item={inner} />
    ))
  }

  render() {
    if(this.props.user && this.props.user['sponsor_name'] && this.props.user['sponsor_name'] === 'ARC'){
      this.state.navItems = [
        {
          title: "Dashboard",
          to: "/",
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: ""
        },
        {
          title: "Log Service Call",
          htmlBefore: '<i class="material-icons">library_add</i>',
          to: "/job-capture",
        },
        {
          title: "Service Calls",
          htmlBefore: '<i class="material-icons">confirmation_number</i>',
          to: "/jobs",
        },
        {
          title: "Enquire",
          htmlBefore: '<i class="material-icons">search</i>',
          to: "/job/enquire",
        },
        {
          title: "Agents",
          htmlBefore: '<i class="material-icons">assignment_ind</i>',
          to: "/agents",
        },
        {
          title: "Invoices",
          htmlBefore: '<i class="material-icons">receipt</i>',
          to: "/invoices",
        },
        {
          title: "Reviews",
          htmlBefore: '<i class="material-icons">rate_review</i>',
          to: "/reviews",
        },
        {
          title: "Notifications",
          htmlBefore: '<i class="material-icons">mark_email_unread</i>',
          to: "/notifications",
        },
        {
          title: "Reports",
          htmlBefore: '<i class="material-icons">dashboard</i>',
          to: "/reports",
        },
        {
          title: "Archives",
          htmlBefore: '<i class="material-icons">archive</i>',
          to: "/archives",
        },
        {
          title: "Manage Inventory",
          htmlBefore: '<i class="material-icons">inventory</i>',
          to: "/manage-inventory",
        },
        {
          title: "Manage Users",
          htmlBefore: '<i class="material-icons">group</i>',
          onlySuper: true,
          to: "/manage-users",
        },
        {
          title: "Sign Out",
          htmlBefore: '<i class="material-icons">exit_to_app</i>',
          to: "/auth/signin",
        }
      ];
    }else{
      this.state.navItems = [
        {
          title: "Dashboard",
          to: "/",
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: ""
        },
        {
          title: "Log Service Call",
          htmlBefore: '<i class="material-icons">library_add</i>',
          to: "/job-capture/development",
        },
        {
          title: "Service Calls",
          htmlBefore: '<i class="material-icons">confirmation_number</i>',
          to: "/jobs",
        },
        {
          title: "Enquire",
          htmlBefore: '<i class="material-icons">search</i>',
          to: "/job/enquire",
        },
        {
          title: "Agents",
          htmlBefore: '<i class="material-icons">assignment_ind</i>',
          to: "/agents",
        },
        {
          title: "Invoices",
          htmlBefore: '<i class="material-icons">receipt</i>',
          to: "/invoices",
        },
        {
          title: "Reviews",
          htmlBefore: '<i class="material-icons">rate_review</i>',
          to: "/reviews",
        },
        {
          title: "Notifications",
          htmlBefore: '<i class="material-icons">mark_email_unread</i>',
          to: "/notifications",
        },
        {
          title: "Reports",
          htmlBefore: '<i class="material-icons">dashboard</i>',
          to: "/reports",
        },
        {
          title: "Archives",
          htmlBefore: '<i class="material-icons">archive</i>',
          to: "/archives",
        },
        {
          title: "Manage Inventory",
          htmlBefore: '<i class="material-icons">inventory</i>',
          to: "/manage-inventory",
        },
        {
          title: "Manage Users",
          htmlBefore: '<i class="material-icons">group</i>',
          onlySuper: true,
          to: "/manage-users",
        },
        {
          title: "Sign Out",
          htmlBefore: '<i class="material-icons">exit_to_app</i>',
          to: "/auth/signin",
        }
      ];
    }

    return (
      <div className='nav-wrapper'>
        <Nav className='flex-column'>
          {this.state.navItems.map((item, idx) => {
            if (item.onlySuper) {
              const user = this.state.user || {}
              if (user.user_type === 'super') {
                return (
                  <div key={idx}>
                    <SidebarNavItem key={idx} item={item} onRouteActive={(title) => this.onRouteActive(title)} />
                    {(this.state.activeRoute === item.title) && this.renderInnerRoutes(item)}
                  </div>
                )
              } else {
                return undefined
              }
            } else {
              if (item.to === '/auth/signin') {
                return (
                  <div key={idx} style={{ paddingTop: '60px' }}>
                    <SidebarNavItem key={idx} item={item} noBorder onRouteActive={(title) => this.onRouteActive(title)} />
                  </div>
                )
              }
              return (
                <div key={idx}>
                  <SidebarNavItem key={idx} item={item} onToggle={() => this.handleToggleSidebar()} onRouteActive={(title) => this.onRouteActive(title)} />
                </div>
              )
            }
          })}
        </Nav>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user,
    menuVisible: state.menuVisible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);

