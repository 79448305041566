import React from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, InputGroup, FormInput, Alert } from 'shards-react';

import RangeDatePicker from '../common/RangeDatePicker';
import JobStatusFilter from './jobs/JobStatusFilter';
import AgentFilter from './agents/AgentFilter';
import { fetchDownloadMulti, fetchGet } from '../../utils/api';
import { colors } from '../../utils/colors';
import moment from 'moment-timezone';

class ArchiveActions extends React.Component {
    state = {
        open: false,
        title: '',
        showStatusFilter: false,
        showAgentFilter: false,
        searchJob: '',
        searchInvoice: '',
        startAt: null,
        isArchive: true,
        endAt: null,
        clear: false,
        jobStatus: null,
        agent: null,
        length: 0,
        showExport: true
    }

    componentDidUpdate(props) {
        if (this.props.length !== props.length) {
            this.setState({
                length: this.props.length
            })
        }

        if (this.props.status !== props.status) {
            this.setState({
                status: this.props.status
            })
        }

        if (this.props.tab !== props.tab) {
            this.setState({
                showExport: this.props.tab === 0
            })
        }

        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({
                status: null,
                agent: null,
                startAt: null,
                endAt: null,
                searchJob: '',
                searchInvoice: ''
            })
        }
    }

    downloadInvoice() {
        var api = '/utils/export/invoice_excel/multi'

        var sortedList = this.props.selectedDates.sort((a, b) => {
            if (moment(a).diff(moment(b)) > 0)
                return 1
            if (moment(a).diff(moment(b)) < 0)
                return -1
            return 0
        })

        var startAt = moment(sortedList[0]).format('YYYY-MM-DD')
        var endAt = moment(sortedList[sortedList.length - 1]).format('YYYY-MM-DD')

        fetchDownloadMulti(api,
            {
                invoices: this.props.selected,
                startAt: this.state.startAt || startAt,
                endAt: this.state.endAt || endAt,
                filters: {
                    serviceArea: this.state.serviceArea,
                    sponsorArea: this.state.sponsorArea,
                    state: this.state.status,
                    additionalLines: this.state.line
                }
            },
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                this.setState({ downloadURL: url })
                this.fetchName(startAt, endAt)
            },
            (response) => {
            }
        );
    }

    fetchName(startAt, endAt) {
        var api = '/invoices/fetch_batch_name'

        fetchGet(api,
            (response) => {
                let a = document.getElementById('download');
                a.href = this.state.downloadURL;
                const details = response.details
                // const name = `${details.area}_${this.state.startAt || startAt}_${stateEndAt || endAt}_${details.batch}.xlsx`
                const name = response.name
                a.download = name;
                a.click();
                this.toggle('submitted')
            },
            () => {
            }
        );
    }

    fetchStatus(status) {
        return status === 'invoice_submitted' ? 'Submitted' : 'Generated'
    }

    toggle(title) {
        if (title === 'showUpdate') {
            this.props.onUpdateMultiple()
        } else if (title === 'selection') {
            this.setState({ selectAll: !this.state.selectAll })
            this.props.onSelection(!this.state.selectAll)
            if (this.state.selectAll) {
                this.props.onDone()
            }
        } else if (title === 'submitted') {
            this.props.onDone(1)
        } else if (title === 'archived') {
            this.props.onDone(2)
        } else {
            const state = {
                showStatusFilter: false,
                showServiceFilter: false,
                showAgentFilter: false,
                showPriorityFilter: false,
                showSponsorFilter: false,
                showJobTypeFilter: false,
                clear: false
            }
            if (title) {
                state[title] = !this.state[title]
            }
            this.setState(state);
        }
    }

    render() {
        return (
            <div className='job-actions'>
                <a id='download' href='#' style={{ display: 'none' }} />
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='6' lg='5' className='d-flex'>
                                <RangeDatePicker
                                    clear={this.state.clear}
                                    onStartAt={(startAt) => {
                                        this.setState({ startAt, clear: false })
                                        this.props.onStartAt(startAt)
                                    }}
                                    onEndAt={(endAt) => {
                                        this.setState({ endAt, clear: false })
                                        this.props.onEndAt(endAt)
                                    }}
                                />
                            </Col>
                            <Col sm='12' md='6' lg='5'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search invoice no." style={{ width: '40%' }} value={this.state.searchInvoice} onChange={e => {
                                        this.setState({ searchInvoice: e.target.value })
                                        this.props.onSearchInvoice(e.target.value)
                                    }} />
                                    <FormInput placeholder="or job no." style={{ width: '40%' }} value={this.state.searchJob} onChange={e => {
                                        this.setState({ searchJob: e.target.value })
                                        this.props.onSearchJob(e.target.value)
                                    }} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='5' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Agent Filter */}
                                    <Button
                                        size='sm'
                                        className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                        onClick={() => this.toggle('showAgentFilter')}
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>{this.state.agent || 'Agent Filter'}</span>
                                        {this.state.agent ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                    </Button>
                                    <AgentFilter visible={this.state.showAgentFilter} selected={this.state.selected} onSelect={(filter) => {
                                        this.props.onFilterAgent(filter)
                                        this.setState({ showAgentFilter: false, agent: filter.value ? filter.label : null })
                                    }} />

                                    <Button
                                        size='sm'
                                        className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                        onClick={() => this.toggle('showJobStatusFilter')}
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>{this.state.jobStatus || 'Job Status'} &darr;</span>
                                        {this.state.jobStatus ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                    </Button>
                                    <JobStatusFilter visible={this.state.showJobStatusFilter} onSelect={(filter) => {
                                        this.props.onFilterJobStatus(filter.value)
                                        this.setState({ showJobStatusFilter: false, jobStatus: filter.value ? filter.label : null })
                                    }} status={this.state.jobStatus} />
                                </ButtonGroup>
                            </Col>
                            <Col sm='12' md='12' lg='6' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Export CSV */}
                                    {this.state.showExport && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onExport()}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Export &rarr;</span>
                                        </Button>
                                    </div>)}
                                    <Button
                                        size='sm'
                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                        onClick={() => {
                                            this.toggle()
                                            this.setState({
                                                searchInvoice: null,
                                                searchJob: '',
                                                status: null,
                                                agent: null,
                                                startAt: null,
                                                clear: true,
                                                endAt: null
                                            })

                                            this.props.onClearAll()
                                        }}
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {this.state.message && (
                            <Row>
                                <Col sm='12' md='12' lg='12' className='d-flex w-100'>
                                    <Alert style={{ backgroundColor: this.state.error ? colors.RED : colors.GREEN, color: '#ffffff', width: '100%' }}>
                                        {this.state.message}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default ArchiveActions;
