import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    NavbarBrand,
    Form,
    FormInput,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Alert
} from "shards-react";
import { fetchPost } from '../utils/api';
import { saveSession, clearSession } from '../utils/cookies';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../utils/colors';

const IMG_LOGO = require('../assets/images/s4biz-white.png').default;

class ForgetPassword extends React.Component {
    state = {
        email: '',
        cell_number: '',
        user: null,
        valid : false,
        password_message :null,
        valid_temp_password : false,
        password_updated : false
    }

    componentDidMount() {
        clearSession()
        this.props.dispatch({ type: 'LOGOUT' })
    }


    updatePassword(){

        if(this.state.user && this.state.user.temp_password === this.state.temp_password){

        }else{
            this.setState({ message: 'Incorrect Temporary Password', loading: false, success: false })
            return
        }
        var  api = '/users/update/' + this.state.user['uuid']

        fetchPost(api,
            {
                first_name: this.state.user['first_name'] || null,
                last_name: this.state.user['last_name'] || null,
                email: this.state.user['email'] || null,
                contact_no: this.state.user['contact_no'] || null,
                temp_password: this.state.confirm_password || null,
                reset_password:  false,
                user_type: this.state.user['user_type'] || null,
                service_org: this.state.user['service_org' || null],

            },
            (response) => {
                if (response && response.status === 200) {
                    this.props.history.push('/auth/login')
                } else {
                    this.setState({ message: response.message, loading: false, success: false })
                }
            },
            (response) => {
                this.setState({ success_message: 'Password Successfully Updated.', loading: false, success: true,password_updated : true})
            },
            true
        )
    }

    render() {
        return (
            <Container fluid className='main-content-container px-4'>
                <NavbarBrand
                    className='w-100 mr-0'
                    href='#'
                    style={{ lineHeight: '25px', padding: '8px 0' }}
                >
                    <div className='m-auto'>
                        <img
                            id='main-logo'
                            className='d-inline-block align-top mr-1'
                            style={{ maxWidth: '160px' }}
                            src={IMG_LOGO}
                            alt='S4Biz Logo'
                        />
                    </div>
                </NavbarBrand>
                <Row>
                    <Col lg="12" className="mb-4">
                        <Card small className="mb-4">
                            <CardBody className="p-4 pb-3">
                                <Col
                                    sm='12' md='12'
                                >
                                    <h4><strong className='d-block mb-2'>Sign in to S4Biz</strong></h4>
                                    <p className='text-muted d-block mb-4'>Reset Password</p>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            if(this.state.user && this.state.user['uuid']){
                                                this.updatePassword()
                                            }else{
                                                this.setState({ loading: true, message: null })
                                                fetchPost('/users/search_user_by_cell_number',
                                                    { cell_number: this.state.cell_number },
                                                    
                                                    (response) => {
                                                            if(response){
                                                                this.setState({ user: response, loading: false,valid:true })
                                                            }else{
                                                                this.setState({ message: 'Cell phone number not found', loading: false })
                                                            }    
                                                    },
                                                    (response) => {
                                                        this.setState({ user: response,message: response.message, loading: false })
                                                    }
                                                )
                                            }
                                        }}
                                    >
                                        {!this.state.valid && (<FormGroup>
                                            <InputGroup className='mb-3'>
                                                <InputGroupAddon type='prepend'>
                                                    <InputGroupText>+27</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput
                                                    type='number'
                                                    placeholder='Cell Number'
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            cell_number: e.target.value
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
)}
                                        
                                        {this.state.valid && (<div>
                                            <FormGroup>
                                            <InputGroup className='mb-3'>
                                                <InputGroupAddon type='prepend'>
                                                    <InputGroupText>@</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput
                                                    type='text'
                                                    placeholder='Temporary Password'
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            temp_password: e.target.value
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                            </FormGroup>

                                            {this.state.temp_password && (                                                <div>
                                                    <FormGroup>
                                                            <InputGroup className='mb-3'>
                                                                <InputGroupAddon type='prepend'>
                                                                    <InputGroupText>@</InputGroupText>
                                                                </InputGroupAddon>
                                                                <FormInput
                                                                    type='password'
                                                                    placeholder='New Password'
                                                                    required
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            new_password: e.target.value
                                                                        })
                                                                    }}
                                                                
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <InputGroup className='mb-3'>
                                                                <InputGroupAddon type='prepend'>
                                                                    <InputGroupText>@</InputGroupText>
                                                                </InputGroupAddon>
                                                                <FormInput
                                                                    type='password'
                                                                    placeholder='Confirm Password'
                                                                    required
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            confirm_password: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                </div>)}

                                        </div>)}
      
                                        {this.state.loading && <p>Loading, Please Wait ...</p>}
                                        {this.state.message && !this.state.success && (
                                            <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                                {this.state.message}
                                            </Alert>
                                        )}

                                        {this.state.success_message && (
                                            <Alert style={{ backgroundColor: colors.GREEN, color: '#ffffff' }}>
                                                {this.state.success_message}
                                            </Alert>
                                        )}

                                        {this.state.confirm_password && this.state.new_password !== this.state.confirm_password && (
                                            <Alert style={{ backgroundColor: colors.ORANGE, color: '#ffffff' }}>
                                                Password's do not match
                                            </Alert>
                                        )}

                                        {!this.state.valid && <div>
                                            <Button type='submit' >Reset Password</Button>  <Button theme='danger' onClick={() => {
                                            this.props.history.push('/auth/login')
                                        }}>Cancel</Button></div>}


                                        {this.state.valid && (this.state.confirm_password && this.state.new_password === this.state.confirm_password) && !this.state.password_updated && <div>
                                            <Button>Update Password</Button>  <Button theme='danger' nClick={() => {
                                            this.props.history.push('/auth/login')}}>Cancel</Button></div>}

                                        {this.state.success && (<Button theme='success' onClick={() => {
                                            this.props.history.push('/auth/login')}}>Login</Button>)}


                                    </Form>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect()(withRouter(ForgetPassword));