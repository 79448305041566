import React from 'react';
import {
    Container, CardBody, Row, Col, Badge, Button, ButtonGroup, FormCheckbox
} from "shards-react";
import * as moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { getColorMatch } from '../../../utils/colors';

class JobTable extends React.Component {
    state = {
        selected: [],
        count: 0
    }

    componentDidUpdate(props) {
        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({ selected: [] })
        }
        if (this.props.count !== props.count) {
            this.setState({ count: this.props.count })
        }
    }

    renderState(state) {
        if (state) {
            switch (state) {
                case 'assigned_to_agent':
                case 'invoice_generated':
                case 'awaiting_parts_ordered':
                    return <Badge theme="info" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'in_hand_by_agent':
                    return <Badge theme="warning" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'awaiting_parts':
                case 'awaiting_parts_not_ordered':
                case 'refer_to_defy':
                case 'awaiting_parts_refer_to_defy':
                    return <Badge theme="secondary" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'closed':
                case 'completed':
                case 'invoice_submitted':
                    return <Badge theme="success" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                case 'cancelled':
                case 'unable_to_contact':
                    return <Badge theme="danger" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
                default:
                    return <Badge theme="light" className='mr-2'>{state.replace(/_/g, ' ')}</Badge>
            }
        } return undefined
    }

    renderOrigin(origin) {
        switch (origin) {
            case 'walk_in':
                return <Badge theme="danger" className='mr-2' style={{ background: '#A9B0BC', fontSize: '8px' }}>Walk In</Badge>
            default:
                return <Badge theme="light" className='mr-2' style={{ fontSize: '8px' }}>Referral</Badge>
        }
    }

    renderPriority(priority) {
        switch (priority) {
            case 'urgent':
                return <Badge theme="danger">{priority}</Badge>
            case 'high':
                return <Badge className='badge-high'>{priority}</Badge>
            case 'medium':
                return <Badge theme="warning">{priority}</Badge>
            default:
                return <Badge theme="light">{priority}</Badge>
        }
    }

    render() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4 job-table">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Service Calls Found</h3>
                        <p>It could be that there are no service calls or none for the filters selected </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3 " style={{ background: 'transparent' }}>
                {this.state.message && (
                    <Row className='py-3 px-4 mx-0' >
                        <div className="text-center text-danger">{this.state.message}</div>
                    </Row>
                )}
                {this.props.data.map((row, idx) => {
                    const selected = this.state.selected.filter(item => item.uuid === row['uuid']).length > 0
                    const customer = row['customer'] || {}
                    const customerFullName = customer['full_name'] || 'A'
                    const customerName = customerFullName.slice(0, 1).toLowerCase()

                    var dueDate = 'Done'
                    if (row['due_in_days'] !== undefined) {
                        if (row['due_in_days'] > 0) {
                            dueDate = 'Due in ' + Math.abs(row['due_in_days']) + ' days'
                        } else if (row['due_in_days'] === 0) {
                            dueDate = 'Due Today'
                        } else {
                            dueDate = 'Due ' + Math.abs(row['due_in_days']) + ' days ago'
                        }
                    }

                    if (row['is_archived']) {
                        dueDate = 'Done'
                    }

                    var appointmentDate = ''
                    if (row['appointment_at']) {
                        appointmentDate = moment(row['appointment_at']).tz('Africa/Johannesburg').calendar()
                    }

                    var sponsorArea = ''
                    if (row['service_area']) {
                        const area = row['areas'].find(x => x['code'] === row['service_area'])
                        sponsorArea = `${area['code']} - ${area['description']}`
                    } else {
                        sponsorArea = `${row['sponsor_area']} ${row['areas'].map(area => area['code'])}`
                    }

                    const highlight = this.props.scroll === row['uuid'] ? { backgroundColor: '#E8EDF1' } : {}

                    const diff = Math.abs(moment.duration(moment().diff(moment(row['awaiting_parts_at']))).asDays())
                    const timer = <Badge theme="secondary" className='mr-2'>Day {parseInt(diff)}</Badge>
                    const showAwaitingPartsTimer = row['state'] === 'awaiting_parts_ordered' || row['state'] === 'awaiting_parts_refer_to_defy'

                    return (
                        <div id={row['uuid']} key={row['uuid']}>
                            <Row className='job-list__item d-flex pr-2 pl-4 px-2 mx-0' style={highlight}>
                                <Col sm='1' md='1' lg='1' className='hide-on-tablet'>
                                    <div className='mx-3 my-3 px-2 py-2'>
                                        {!row['is_archived'] && (<FormCheckbox
                                            checked={selected}
                                            style={{ width: '4rem' }}
                                            onChange={() => {
                                                var list = this.state.selected
                                                if (selected) {
                                                    list = list.filter(item => item.uuid !== row['uuid'])
                                                    this.setState({
                                                        selected: list,
                                                        message: null
                                                    })
                                                    this.props.onSelected(list)
                                                } else {
                                                    list.push({
                                                        uuid: row['uuid'],
                                                        sponsor: row['sponsor_area'],
                                                        service: row['service_area'],
                                                        areas: row['areas'],
                                                        job_no: row['job_no'],
                                                        state_from: row['state'],
                                                        agent: row['agent'],
                                                        contact_customer: row['customer'] ? row['customer']['contact_mobile'] : null
                                                    })
                                                    this.setState({
                                                        selected: list,
                                                        message: null
                                                    })
                                                    this.props.onSelected(list)
                                                }
                                            }}
                                        />
                                        )}
                                    </div>
                                </Col>
                                <Col sm='12' md='8' lg='7' className='py-2 px-0'>
                                    <div
                                        onClick={() => this.props.history.push('/jobs/details', {
                                            row: row,
                                            currentState: this.props.currentState,
                                            scroll: row['uuid'],
                                            returnTo: row['is_archived'] ? '/archives' : '/jobs'
                                        })}
                                    >
                                        <div className='d-flex-to-block text-uppercase'>
                                            <Badge theme="light" className='mr-2'>JOB NO. {row['job_no']}</Badge>
                                            {row['invoice_no'] && <Badge theme="light" className='mr-2'>INVOICE {row['invoice_no']}</Badge>}
                                            <div>
                                                {this.renderState(row['state'])}
                                                {(row['invoice_state'] && row['invoice_state'] !== 'null') && this.renderState(row['invoice_state'])}
                                                {(row['state'] !== 'completed' && row['state'] !== 'closed') && this.renderPriority(row['priority'])}
                                            </div>
                                        </div>
                                        <h6 className='job-title my-2'>{row['model'] || 'Unknown Model'}</h6>
                                        <div className='my-1' style={{}}>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">notes</i>' }}
                                            />
                                            <span>{row['issue']}</span>
                                        </div>
                                        <div className='my-1' style={{}}>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">location_on</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{customer['address']}</span>
                                        </div>
                                        {this.renderOrigin(row['job_origin'])}
                                        <span>&nbsp;{row['customer'] ? row['customer']['full_name'] : ''}</span>
                                        <span>{' • '} {customer['contact_mobile'] || customer['contact_home'] || customer['contact_work']}</span>
                                        <span>{' • '} {moment(row['called_at']).fromNow()}</span>
                                    </div>
                                </Col>
                                <Col sm='12' md='4' lg='4'>
                                    <div
                                        onClick={() => this.props.history.push('/jobs/details', {
                                            row: row,
                                            currentState: this.props.currentState,
                                            scroll: row['uuid'],
                                            returnTo: row['is_archived'] ? '/archives' : '/jobs'
                                        })}
                                    >
                                        <div className='d-flex my-1'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon bolder'
                                                style={{ fontWeight: 600 }}
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">alarm</i>' }}
                                            />
                                            <span style={{ textTransform: 'uppercase', fontWeight: 600 }}>{dueDate}</span>
                                        </div>
                                        <div className='d-flex my-1 text-uppercase'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">location_on</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{sponsorArea}</span>
                                        </div>
                                        <div className='d-flex my-1'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">engineering</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{row['agent'] ? row['agent']['full_name'] : 'Unassigned'}</span>
                                        </div>
                                        <div className='d-flex my-1 text-uppercase'>
                                            <div
                                                className='d-inline-block item-icon-wrapper custom-nav-icon'
                                                dangerouslySetInnerHTML={{ __html: '<i class="material-icons">event_available</i>' }}
                                            />
                                            <span style={{ textTransform: 'capitalize' }}>{appointmentDate}</span>
                                        </div>
                                        {showAwaitingPartsTimer && <div className='d-flex my-1 text-uppercase'>
                                            {timer}
                                        </div>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })
                }
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody >
        )
    }
}

export default withRouter(JobTable)
