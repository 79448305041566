import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormInput,
    FormSelect,
    FormGroup,
    Button,
    Alert
} from "shards-react";
import { postFile, fetchDownloadMulti } from '../../utils/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../utils/colors';
import * as moment from 'moment-timezone';

class ImportPopup extends React.Component {
    state = {
        file: null,
        report: null,
        jobOrigin: 'referral'
    }

    downloadReport() {
        var api = '/utils/import/report'

        fetchDownloadMulti(api, { report: this.state.report },
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.getElementById('download');
                a.href = url;
                const name = `ImportReport_${moment().format('DD-MM-YYYY')}.csv`
                a.download = name;
                a.click();
            },
            (response) => {
            }
        );
    }

    renderCSVImport() {
        return (
            <CardBody className="p-4 pb-3">
                <Col
                    sm='12' md='12'
                >
                    <h4><strong className='text-muted d-block mb-2'>Import Service Calls</strong></h4>
                    <p className='text-muted d-block mb-4'>Upload coverted .csv file of service calls</p>
                    <Form
                        onSubmit={async (e) => {
                            e.preventDefault()
                            var api = `/utils/import/jobs/csv?origin=${this.state.jobOrigin}`
                            this.setState({ loading: true, message: null })
                            postFile(api,
                                this.state.file,
                                (response) => {
                                    if (response && response.status === 200) {
                                        this.setState({
                                            message: response.message,
                                            result: response.result,
                                            loading: false,
                                            passed: response.duplicates.length === 0,
                                            report: response.duplicates.length > 0 ? response.duplicates : null
                                        })
                                    } else {
                                        this.setState({ message: response.message, loading: false })
                                    }
                                },
                                (response) => {
                                    this.setState({ message: response.message, loading: false })
                                }
                            )
                        }}
                    >
                        <hr />
                        <FormGroup>
                            <p className='text-muted d-block mb-2'>Step 1: Convert .pdf file to .csv</p>
                        </FormGroup>
                        <FormGroup>
                            <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                <a
                                    href='https://convertio.co/pdf-csv/'
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                    <Button
                                        size='sm'
                                        type='button'
                                        className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>Convert PDF</span>
                                    </Button>
                                </a>
                            </div>
                        </FormGroup>
                        <hr />
                        <FormGroup>
                            <p className='text-muted d-block mb-2'>Step 2: Import converted .csv file</p>
                        </FormGroup>
                        <FormGroup>
                            <FormInput
                                type='file'
                                placeholder='eg: file.csv'
                                required
                                // value={this.state.file}
                                onChange={(e) => {
                                    var file = e.target.files[0]
                                    this.setState({
                                        file: file
                                    })
                                }}
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup>
                            <p className='text-muted d-block mb-2'>Step 3: Select job origin</p>
                        </FormGroup>
                        <FormGroup>
                            <FormSelect
                                id='type'
                                value={this.state.jobOrigin}
                                required={true}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        this.setState({
                                            jobOrigin: e.target.value
                                        })
                                    }
                                }}
                            >
                                <option value='referral'>Referred</option>
                                <option value='walk_in'>Walk In</option>
                            </FormSelect>
                        </FormGroup>
                        {this.state.loading && <p>Importing service calls...</p>}
                        {(this.state.message || this.state.result) && (
                            <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                {this.state.result}<br />
                                {this.state.message}
                            </Alert>
                        )}
                        <Button
                            size='md'
                            className='btn-white mr-3 mt-3 mt-sm-0'
                            onClick={() => this.props.onCancel()}
                            type='button'
                        >
                            Close
                        </Button>
                        <Button
                            size='md'
                            className='ml-auto mr-3 mt-3 mt-sm-0'
                            type='submit'
                        >
                            Import
                        </Button>
                        {this.state.report && (
                            <Button
                                size='md'
                                className='ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                type='button'
                                onClick={() => this.downloadReport()}
                            >
                                Download Report
                            </Button>
                        )}
                    </Form>
                </Col>
            </CardBody>
        )
    }

    renderPDFImport() {
        return (
            <CardBody className="p-4 pb-3">
                <Col
                    sm='12' md='12'
                >
                    <h4><strong className='text-muted d-block mb-2'>Import Service Calls</strong></h4>
                    <p className='text-muted d-block mb-4'>Upload original PDF file of service calls</p>
                    <Form
                        onSubmit={async (e) => {
                            e.preventDefault()
                            var api = `/utils/import/jobs/pdf?origin=${this.state.jobOrigin}`
                            this.setState({ loading: true, message: null })
                            postFile(api,
                                this.state.file,
                                (response) => {
                                    if (response && response.status === 200) {
                                        this.setState({
                                            message: response.message,
                                            result: response.result,
                                            loading: false,
                                            passed: response.duplicates.length === 0,
                                            report: response.duplicates.length > 0 ? response.duplicates : null
                                        })
                                    } else {
                                        this.setState({ message: response.message, loading: false })
                                    }
                                },
                                (response) => {
                                    this.setState({ message: response.message, loading: false })
                                }
                            )
                        }}
                    >
                        <FormGroup>
                            <p className='text-muted d-block mb-2'>Import .pdf file below</p>
                        </FormGroup>
                        <FormGroup>
                            <FormInput
                                type='file'
                                accept='application/pdf'
                                placeholder='eg: file.pdf'
                                required
                                // value={this.state.file}
                                onChange={(e) => {
                                    var file = e.target.files[0]
                                    this.setState({
                                        file: file
                                    })
                                }}
                            />
                        </FormGroup>
                        {this.state.loading && <p>Importing service calls...</p>}
                        {(this.state.message || this.state.result) && (
                            <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                {this.state.result}<br />
                                {this.state.message}
                            </Alert>
                        )}
                        <Button
                            size='md'
                            className='btn-white mr-3 mt-3 mt-sm-0'
                            onClick={() => this.props.onCancel()}
                            type='button'
                        >
                            Close
                        </Button>
                        <Button
                            size='md'
                            className='ml-auto mr-3 mt-3 mt-sm-0'
                            type='submit'
                        >
                            Import
                        </Button>
                        {this.state.report && (
                            <Button
                                size='md'
                                className='ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                type='button'
                                onClick={() => this.downloadReport()}
                            >
                                Download Report
                            </Button>
                        )}
                    </Form>
                </Col>
            </CardBody>
        )
    }

    render() {
        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >

                        <div className='center-block'>
                            <Row>
                                <a id='download' href='#' style={{ display: 'none' }} />
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        {this.renderPDFImport()}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect()(withRouter(ImportPopup));
