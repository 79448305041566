import React from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import JobDetails from "../components/libs/jobs/JobDetails";
import ManualClosed from '../components/libs/ManualClosed';
import RevertPopup from '../components/libs/RevertPopup';
import RegenerateInvoice from '../components/libs/RegenerateInvoice';
import { withRouter } from 'react-router-dom';

class AddEditJob extends React.Component {
    state = {
        data: {},
        showClose: false,
        currentState: {},
        scroll: null,
        returnTo: null
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (this.props.location.state) {
            this.setState({
                data: this.props.location.state.row || {},
                currentState: this.props.location.state.currentState || null,
                scroll: this.props.location.state.scroll || null,
                returnTo: this.props.location.state.returnTo || null
            })
        }
    }

    static getDerivedStateFromProps(props) {
        if (!props.location.state) {
            props.history.push('/jobs')
        }

        return {
            data: props.location.state.row || {},
            currentState: props.location.state.currentState || null,
            scroll: props.location.state.scroll || null,
            returnTo: props.location.state.returnTo || null
        }
    }

    render() {
        const { currentState, scroll } = this.state

        return (
            <Container fluid className="main-content-container px-4">
                {this.state.showRegenerate && (
                    <RegenerateInvoice
                        job={this.state.data}
                        onComplete={() => {
                            this.setState({ showRegenerate: false })
                            this.props.history.push('/jobs', {
                                currentState: currentState,
                                scroll: scroll
                            })
                        }}
                        onCancel={() => {
                            this.setState({ showRegenerate: false })
                        }}
                    />
                )}
                {this.state.showClose && (
                    <ManualClosed
                        job={this.state.data}
                        onComplete={() => {
                            this.setState({ showClose: false })
                            this.props.history.push('/jobs', {
                                currentState: currentState,
                                scroll: scroll
                            })
                        }}
                        onCancel={() => {
                            this.setState({ showClose: false })
                        }}
                    />
                )}
                {this.state.showRevert && (
                    <RevertPopup
                        job={this.state.data}
                        onComplete={() => {
                            this.setState({ showRevert: false })
                            this.props.history.push('/jobs', {
                                currentState: currentState,
                                scroll: scroll
                            })
                        }}
                        onCancel={() => {
                            this.setState({ showRevert: false })
                        }}
                    />
                )}
                <Row noGutters className="page-header py-4">
                    <PageTitle title="Jobs" subtitle={`No. ${this.state.data['job_no']}`} md="12"
                        className="ml-sm-auto mr-sm-auto"
                        onBack={() => {
                            if (this.state.returnTo) {
                                this.props.history.push(this.state.returnTo, {
                                    currentState: currentState,
                                    scroll: scroll
                                })
                            } else {
                                this.props.history.push('/jobs', {
                                    currentState: currentState,
                                    scroll: scroll
                                })
                            }
                        }}
                    />
                </Row>
                <Row>
                    <Col lg="12">
                        <JobDetails
                            data={this.state.data}
                            history={this.props.history}
                            onShowReneratePopup={() => this.setState({ showRegenerate: true, showRevert: false, showClose: false })}
                            onShowPopup={() => this.setState({ showClose: true, showRevert: false, showRegenerate: false })}
                            onShowRevertPopup={() => this.setState({ showRevert: true, showClose: false, showRegenerate: false })}
                            onBack={() => {
                                if (this.state.returnTo) {
                                    this.props.history.push(this.state.returnTo, {
                                        currentState: currentState,
                                        scroll: scroll
                                    })
                                } else {
                                    this.props.history.push('/jobs', {
                                        currentState: currentState,
                                        scroll: scroll
                                    })
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(AddEditJob)
