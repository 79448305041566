import React from 'react';
import classNames from 'classnames';
import { Col } from 'shards-react';

const IMG_LOADER = require('../../assets/images/loader.gif').default;

const Loader = ({ title }) => {
    const classes = classNames(
        'text-center',
        'text-md-left',
        'mb-sm-0'
    );

    return (
        <Col className={classes}>
            <div className='center-block'>
                <img
                    id='loader'
                    className='d-inline-block align-top m-auto'
                    style={{ width: '200px' }}
                    src={IMG_LOADER}
                    alt='S4Biz Loader'
                />
            </div>
        </Col>
    )
};

export default Loader