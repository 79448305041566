import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';

const list = [
    {
        code: 'bosch',
        description: 'Bosch'
    },
    {
        code: 'candy_appliances',
        description: 'Candy Appliances'
    },
    {
        code: 'insurance',
        description: 'Insurance'
    },
    {
        code: null,
        description: 'Remove Filter'
    }
]

class InsurerTypeFilter extends React.Component {
    state = {
        visible: false
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }
    }

    render() {
        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {list.map((item, idx) => {
                    if (item.code) {
                        return (
                            <div key={idx}>
                                <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: item.description,
                                        value: item.code
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {item.description.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }

                    return (
                        <div key={idx}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: item.description,
                                        value: item.code
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {item.code} - {item.description.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}

export default InsurerTypeFilter;