import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { isProduction, isStaging } from '../config'

import thunk from 'redux-thunk';
import reducer from './reducer';

const key = isProduction ? 's4biz-admin-prod' : isStaging ? 's4biz-admin-qa' : 's4biz-admin-dev'

const persistConfig = {
    key: key,
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);