const characters = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const colors = {
    ORANGE: '#E19229',
    OFF_WHITE: '#F7F7F7',
    PRIMARY: '#3C5490',
    YELLOW: '#EBB748',
    GREEN: '#81A94F',
    LIME: '#81A94F',
    BLUE: '#3C5490',
    TURQUIOSE: '#818EA3',
    PURPLE: '#7A4CA6',
    LILAC: '#A06BB4',
    RED: '#DD5757',
    LIGHT_ORANGE: '#F0C894',
    LIGHT_YELLOW: '#F5DBA4',
    LIGHT_GREEN: '#A9DFBF',
    LIGHT_LIME: '#C5ECD5',
    LIGHT_BLUE: '#95B2D8',
    LIGHT_TURQUIOSE: '#BBEBFA',
    LIGHT_PURPLE: '#D7B9F3',
    LIGHT_LILAC: '#CFB5D9'
}

export const themes = {
    ORANGE: '#f26419',
    YELLOW: '#f6ae2d',
    BLUE: '#33658a',
    LIGHT_BLUE: '#86bbd8',
    DARK: '#2f4858',
    LIGHT: '#D0D8DA',
}

export function getColorMatch(char) {
    if (characters.slice(0, 3).includes(char)) {
        return { dark: colors.ORANGE, light: colors.LIGHT_ORANGE }
    } else if (characters.slice(3, 6).includes(char)) {
        return { dark: colors.YELLOW, light: colors.LIGHT_YELLOW }
    } else if (characters.slice(6, 9).includes(char)) {
        return { dark: colors.GREEN, light: colors.LIGHT_GREEN }
    } else if (characters.slice(9, 12).includes(char)) {
        return { dark: colors.LIME, light: colors.LIGHT_LIME }
    } else if (characters.slice(12, 15).includes(char)) {
        return { dark: colors.BLUE, light: colors.LIGHT_BLUE }
    } else if (characters.slice(15, 18).includes(char)) {
        return { dark: colors.TURQUIOSE, light: colors.LIGHT_TURQUIOSE }
    } else if (characters.slice(18, 21).includes(char)) {
        return { dark: colors.PURPLE, light: colors.LIGHT_PURPLE }
    } else {
        return { dark: colors.LILAC, light: colors.LIGHT_LILAC }
    }
}

