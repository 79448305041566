import React from 'react';

import { toggle_sidebar } from '../../../store/action';
import { connect } from 'react-redux';

class NavbarToggle extends React.Component {
  state = {}

  handleClick() {
    if (this.props.toggle_sidebar) {
      this.props.toggle_sidebar()
    }
  }

  render() {
    return (
      <nav className='nav'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={() => this.handleClick()} className='nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center'>
          <i className='material-icons'>&#xE5D2;</i>
        </a>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarToggle);
