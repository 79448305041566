import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import DefaultLayout from './components/layouts/DefaultLayout';
import FullPageLayout from './components/layouts/FullPage';

import Maintenance from './pages/MaintanencePage';

// Route Views
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Agents from './pages/Agents';
import ManageUsers from './pages/ManageUsers';
import Jobs from './pages/Jobs';
import AddEditJob from './pages/AddEditJob';
import MyAccount from './pages/MyAccount';
import Reports from './pages/Reports';
import Reviews from './pages/Reviews';
import Notifications from './pages/Notifications';
import Invoices from './pages/Invoices';
import Archives from './pages/Archives';
import ViewInvoice from './pages/ViewInvoice';
import Settings from './pages/Settings';
import ManageInventory from './pages/ManageInventory';
import Errors from './pages/Errors.jsx';
import JobCapture from './components/libs/jobs/JobCapture';
import JobEnquire from './pages/JobEnquire';
import ForgetPassword from './pages/ForgetPassword';

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to='/overview' />
  },
  {
    path: '/overview',
    layout: DefaultLayout,
    component: Dashboard,
    exact: true
  },
  {
    path: '/agents',
    layout: DefaultLayout,
    component: Agents,
    exact: true
  },
  {
    path: '/jobs',
    layout: DefaultLayout,
    component: Jobs,
    exact: true
  },
  {
    path: '/jobs/:uuid',
    layout: DefaultLayout,
    component: AddEditJob,
    exact: true
  },
  {
    path: '/invoices',
    layout: DefaultLayout,
    component: Invoices,
    exact: true
  },
  {
    path: '/invoices/:uuid/view',
    layout: DefaultLayout,
    component: ViewInvoice,
    exact: true
  },
  {
    path: '/reports',
    layout: DefaultLayout,
    component: Reports,
    exact: true
  },
  {
    path: '/reviews',
    layout: DefaultLayout,
    component: Reviews,
    exact: true
  },
  {
    path: '/notifications',
    layout: DefaultLayout,
    component: Notifications,
    exact: true
  },
  {
    path: '/job/enquire',
    layout: DefaultLayout,
    component: JobEnquire,
    exact: true
  },

  {
    path: '/archives',
    layout: DefaultLayout,
    component: Archives,
    exact: true
  },
  {
    path: '/my-account',
    layout: DefaultLayout,
    component: MyAccount
  },
  {
    path: '/settings',
    layout: DefaultLayout,
    component: Settings
  },
  {
    path: '/refresh-jobs',
    layout: DefaultLayout,
    component: () => <Redirect to='/jobs' />
  },
  {
    path: '/refresh-invoices',
    layout: DefaultLayout,
    component: () => <Redirect to='/invoices' />
  },
  {
    path: '/refresh-agents',
    layout: DefaultLayout,
    component: () => <Redirect to='/agents' />
  },
  {
    path: '/refresh-users',
    layout: DefaultLayout,
    component: () => <Redirect to='/manage-users' />
  },
  {
    path: '/refresh-notifications',
    layout: DefaultLayout,
    component: () => <Redirect to='/notifications' />
  },
  {
    path: '/refresh-reviews',
    layout: DefaultLayout,
    component: () => <Redirect to='/reviews' />
  },
  {
    path: '/manage-users',
    layout: DefaultLayout,
    component: ManageUsers
  },
  {
    path: '/manage-inventory',
    layout: DefaultLayout,
    component: ManageInventory
  },

  {
    path: '/job-capture/development',
    layout: DefaultLayout,
    component: ManageInventory
  },

  {
  path: '/job-capture',
  layout: DefaultLayout,
  component: JobCapture,
  exact: true
  },
  {
    path: '/auth/signin',
    layout: FullPageLayout,
    component: Login
  },
  {
    path: '/auth/forgot-password',
    layout: FullPageLayout,
    component: ForgetPassword
  },
  {
    layout: DefaultLayout,
    component: Errors
  },
];
