import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';

const lines = [
    {
        value: 'created_at',
        label: 'Imported Date'
    },
    {
        value: 'updated_at',
        label: 'Updated Date'
    }
]

class DateColumnFilter extends React.Component {
    state = {
        visible: false,
        line: null
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }

        if (this.props.line !== props.line) {
            this.setState({ line: this.props.line })
        }
    }

    render() {
        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {lines.map((line, codex) => {
                    return (
                        <div key={codex}>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    label: line.label,
                                    value: line.value
                                })}>
                                    <p className='mb-0 text-capitalize'>
                                        {line.label}
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )

                })}
            </Collapse>
        )
    }
}

export default DateColumnFilter;