import React from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'shards-react';
import * as moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { fetchGet, fetchDownload } from '../utils/api';
import RangeDatePicker from '../components/common/RangeDatePicker';
import AgentFilter from './../components/libs/agents/AgentFilter';
import lodash from 'lodash';
import { createRequestParams } from '../utils/functions';
import { connect } from 'react-redux';


class Reports extends React.Component {
    state = {
        selected: [],
        disabled: false,
        report: {},
        params: {},
        agent: null,
        user: {},
        agentUUID: null
    }

    componentDidMount() {
        this.fetchReports()
    }

    fetchReports(url) {
        var api = url || `/utils/reports/fetch`

        fetchGet(api,
            (response) => {
                this.setState({ report: response.report })
            },
            (response) => {
                this.setState({ message: response.message, error: true })
            }
        )
    }

    fetchColour(key) {
        // switch (key) {
        // case 'job_completion_time':
        // green
        // return { color: 'white', background: '#81A94F', borderColor: '#e1e5eb' }
        // case 'in_hand_by_agent':
        // yellow
        // return { color: 'white', background: '#EBB748', borderColor: '#e1e5eb' }
        // case 'pickup_for_workshop':
        // case 'refer_to_defy':
        //     // orange
        //     return { color: 'white', background: '#E19229', borderColor: '#e1e5eb' }
        // case 'awaiting_parts_ordered':
        // case 'awaiting_parts_not_ordered':
        // black
        return { color: '#323539', background: 'white', borderColor: '#e1e5eb' }
        // case '':
        //     return { color: 'rgb(0,123,255)', background: 'rgb(0,123,255,0.1)', borderColor: '#e1e5eb' }
        // case 'awaiting_parts_ordered':
        // case 'awaiting_parts_not_ordered':
        // blue
        //     return { color: 'white', background: 'rgb(0, 184, 216)', borderColor: '#e1e5eb' }
        // default:
        // return { color: 'white', background: '#A9B0BC', borderColor: '#e1e5eb' }
        // }
    }

    onClearAll() {
        this.setState({
            selected: [],
            disabled: false,
            startAt: null,
            endAt: null,
            report: {},
            params: {},
            agent: null,
            agentUUID: null
        })
        this.fetchReports()
    }

    onFilter(key, value) {
        const state = this.state
        if (typeof key === 'object') {
            state[key[0]] = value[0]
            state[key[1]] = value[1]
        } else {
            state[key] = value
        }

        state['page'] = 1

        this.setState(state)
        delete state['jobs']
        this.props.fetch_jobs(state, null)
    }

    onExport() {
        const params = this.state
        params['agent'] = this.state.agentUUID
        var api = createRequestParams('/utils/export/reports', params)

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Report_${moment().format('DD-MM-YYYY')}.csv`
                if (params.startAt && params.endAt) {
                    name = `Export_Report_${params.startAt}-to-${params.endAt}.csv`
                }
                a.download = name;
                a.click();
            },
            (response) => {
                this.setState({ message: response.message })
            }
        );
    }

    onFilter(key, value) {
        var api = '/utils/reports/fetch'

        const state = this.state.params
        state[key] = value

        Object.keys(state).forEach((x, i) => {
            if (i === 0) {
                api += `?${x}=${state[x]}`
            } else {
                api += `&${x}=${state[x]}`
            }
        })

        this.setState({
            params: state
        })
        this.fetchReports(api)
    }

    renderStat(key, stat = {}, type) {
        const total = stat.total
        const average = stat.average

        if (type === 'performance') {
            const color = average > 59 ? '#81A94F' : '#A9B0BC'

            return (
                <Col className='col-lg mb-4' lg='3' md='6' sm='6'>
                    <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(key)}>
                        <CardBody className='p-0 d-flex' style={{ background: this.fetchColour(key).background }} >
                            <div className='d-flex flex-column m-auto' >
                                <div className='stats-small__data text-center'>
                                    <span className='stats-small__label text-uppercase' style={{ color: this.fetchColour(key).color }}>{lodash.startCase(key)}</span>
                                    <h3 className='stats-small__value count my-3' style={{ color: color, fontSize: '2.4rem' }}>{parseInt(average)} %</h3>
                                    <span className='stats-small__label text-uppercase' style={{ color: this.fetchColour(key).color }}>Out of {total}</span>
                                </div>
                                <div className='stats-small__data'>
                                    <span className='text-right align-items-center'></span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )
        }

        return (
            <Col className='col-lg mb-4' lg='6' md='6' sm='6'>
                <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(key)}>
                    <CardBody className='p-0 d-flex' style={{ background: this.fetchColour(key).background }} >
                        <div className='d-flex flex-column m-auto' >
                            <div className='stats-small__data text-center'>
                                <span className='stats-small__label text-uppercase' style={{ color: this.fetchColour(key).color }}>{lodash.startCase(key)}</span>
                                <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(key).color }}>{parseFloat(average || 0).toFixed(1)}</h6>
                                <span className='stats-small__label text-uppercase' style={{ color: this.fetchColour(key).color }}>Avg of {total || 0}</span>
                            </div>
                            <div className='stats-small__data'>
                                <span className='text-right align-items-center'></span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    render() {
        var turnaround = {}
        var performance = {}
        if (this.state.report) {
            if (this.state.report['turnaround']) {
                turnaround = this.state.report['turnaround']
            }
            if (this.state.report['performance']) {
                performance = this.state.report['performance']
            }
        }

        const keysAdminPerformance = ['in_hand_by_agent', 'awaiting_parts_ordered']
        const keysAgentPerformance = ['awaiting_parts_not_ordered']
        const keysASCPerformance = ['pickup_for_workshop', 'job_completion_time']
        const keysDefyPerformance = ['refer_to_defy', 'awaiting_parts_refer_to_defy']
        const keyReviews = ['issues_resolved', 'overall_satisfaction', 'agent_interaction', 'turnaround_satisfaction']

        return (
            <Container fluid className='main-content-container px-4'>
                <Row noGutters className='mb-4 py-2 px-3 border bg-light'>
                    <Col sm='12' md='6' lg='6' className='d-flex'>
                        <RangeDatePicker
                            style={{ flex: 10 }}
                            placeholderStartAt='Filter Start Date'
                            placeholderEndAt='Filter End Date'
                            onStartAt={(startAt) => {
                                this.setState({ startAt })
                                this.onFilter('startAt', startAt)
                            }}
                            onEndAt={(endAt) => {
                                this.setState({ endAt })
                                this.onFilter('endAt', endAt)
                            }}
                        />
                        <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                            <Button
                                size='sm'
                                className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                onClick={() => this.setState({
                                    showAgentFilter: !this.state.showAgentFilter
                                })}
                            >
                                <span style={{ fontSize: '0.75rem' }}>{this.state.agent || `Agent Filter`} &darr;</span>
                                {this.state.agent ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                            </Button>
                            <AgentFilter visible={this.state.showAgentFilter} selected={this.state.selected} onSelect={(filter) => {
                                if (filter.value) {
                                    this.onFilter('agent', filter.value.uuid)
                                    this.setState({ showAgentFilter: false, agent: filter.label, agentUUID: filter.value.uuid })
                                } else {
                                    this.onFilter('agent', '')
                                    this.setState({ showAgentFilter: false, agent: null, agentUUID: null })
                                }

                            }} />
                        </div>
                    </Col>
                    <Col sm='12' md='6' lg='6' className='d-flex pl-2'>

                        {/* Export CSV */}
                        <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                            <Button
                                size='sm'
                                className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                onClick={() => this.onExport()}
                            >
                                <span style={{ fontSize: '0.75rem' }}>Export &rarr;</span>
                            </Button>
                        </div>
                        {/* Clear All Filters */}
                        <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                            <Button
                                size='sm'
                                className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                onClick={() => {
                                    this.onClearAll()
                                }}
                            >
                                <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                            </Button>
                        </div>
                    </Col>
                </Row>

                <div className='py-2 px-3 border' style={{ background: 'white' }}>
                    <Row className='py-2 px-0'>
                        <Col className='col-lg mb-4' lg='6' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>Admin Performance</h6></Row>
                            <Row>
                                {keysAdminPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col>
                        <Col className='col-lg mb-4' lg='6' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>Agent Performance</h6></Row>
                            <Row>
                                {keysAgentPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col>
                    </Row>

                    {(this.props.user && this.props.user['sponsor_name'] && this.props.user['sponsor_name'] === 'ARC') && (
                        <Row className='py-2 px-0'>
                        <Col className='col-lg mb-4' lg='12' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>ARC Performance</h6></Row>
                            <Row>
                                {keysASCPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col>
                        {/* <Col className='col-lg mb-4' lg='6' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>Defy Performance</h6></Row>
                            <Row>
                                {keysDefyPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col> */}
                    </Row>
                    )}

                    {(this.props.user && this.props.user['sponsor_name'] && this.props.user['sponsor_name'] !== 'ARC') && (
                        <Row className='py-2 px-0'>
                        <Col className='col-lg mb-4' lg='6' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>ASC Performance</h6></Row>
                            <Row>
                                {keysASCPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col>
                        <Col className='col-lg mb-4' lg='6' md='12' sm='12'>
                            <Row className='py-2 px-3'><h6>Defy Performance</h6></Row>
                            <Row>
                                {keysDefyPerformance.map(x => this.renderStat(x, turnaround[x]))}
                            </Row>
                        </Col>
                    </Row>
                    )}


                    <Row className='py-2 px-3'><h6>Customer Reviews</h6></Row>
                    <Row>
                        {keyReviews.map(x => this.renderStat(x, performance[x], 'performance'))}
                    </Row>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
      ...state,
      user: state.user
    }
  }

export default connect(mapStateToProps) (withRouter(Reports))