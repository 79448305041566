import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';
import { connect } from 'react-redux';


class ServiceAreaFilter extends React.Component {
    state = {
        visible: false,
        areas: []
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }

        if (this.props.areas !== props.areas) {
            this.setState({ areas: this.props.areas })
        }

        if (this.props.sponsors !== props.sponsors) {
            this.setState({ sponsors: this.props.sponsors })
        }
    }

    render() {
        var serviceAreas = []
        if(this.props.user && this.props.user.sponsor_name === 'ARC'){
             serviceAreas = [
                {
                    code: '01',
                    sponsor: 'k19',
                    description: 'country'
                },
                {
                    code: '02',
                    sponsor: 'k19',
                    description: 'local'
                },
                {
                    code: '03',
                    sponsor: 'k19',
                    description: 'paarl | stellenbosh | somerset west | township'
                },
                {
                    code: '04',
                    sponsor: 'k19',
                    description: "hermanus | pringle bay | betty's bay | kleinmond"
                },
                {
                    code: '05',
                    sponsor: 'k19',
                    description: 'gansbaai'
                },
                {
                    code: '06',
                    sponsor: 'k20',
                    description: 'pearly beach'
                },
                {
                    code: '07',
                    sponsor: 'k24',
                    description: 'distance from 341km to 400km'
                },
                {
                    code: '08',
                    sponsor: 'k21',
                    description: 'walk in small appliance'
                },
                {
                    code: '09',
                    sponsor: 'k22',
                    description: 'walk in large appliance'
                },
                {
                    code: '10',
                    sponsor: 'k23',
                    description: 'cash call out'
                },
                {
                    code: null,
                    sponsor: null,
                    description: 'Remove Filter'
                }
            ]
        }else {
            serviceAreas = [
               {
                   code: '01',
                   sponsor: 'k19',
                   description: 'country'
               },
               {
                   code: '02',
                   sponsor: 'k19',
                   description: 'local'
               },
               {
                   code: '03',
                   sponsor: 'k19',
                   description: 'paarl | stellenbosh | somerset west | township'
               },
               {
                   code: '04',
                   sponsor: 'k19',
                   description: "hermanus | pringle bay | betty's bay | kleinmond"
               },
               {
                   code: '05',
                   sponsor: 'k19',
                   description: 'gansbaai'
               },
               {
                   code: '06',
                   sponsor: 'k20',
                   description: 'pearly beach'
               },
               {
                   code: '07',
                   sponsor: 'k24',
                   description: 'distance from 341km to 400km'
               },
               {
                   code: null,
                   sponsor: null,
                   description: 'Remove Filter'
               }
           ]
       }

        var areas = serviceAreas
        if (this.state.areas && this.state.areas.length > 0) {
            areas = areas.filter(area => this.state.areas.indexOf(area['code']) > -1)
        }

        if (this.state.sponsors && this.state.sponsors.length > 0) {
            areas = areas.filter(area => {
                if (area['sponsor']) {
                    return (this.state.sponsors.indexOf(area['sponsor'].toUpperCase()) > -1
                        || this.state.sponsors.indexOf(area['sponsor']) > -1)
                }
                return false
            })
        }

        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {areas.map((area, codex) => {
                    if (area.sponsor) {
                        return (
                            <div key={codex}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: area.description,
                                        value: area.code
                                    })}>
                                        <p className='mb-0 text-capitalize'>
                                            {area.code} - {area.description.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }
                    return (
                        <div key={codex}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    description: area.description,
                                    value: null
                                })}>
                                    <p className='mb-0 text-capitalize'>
                                        Remove Filter
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps) (ServiceAreaFilter);