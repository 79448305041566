import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'shards-react';

const SidebarNavInnerItem = ({ item, history }) => (
    <NavItem>
        <RouteNavLink to={item.to}>
            <NavLink>
                <div className='d-flex item-icon-wrapper'>
                    {item.title && <div className='bullet' />}
                    {item.title && <span>{item.title}</span>}
                </div>
            </NavLink>
        </RouteNavLink>
    </NavItem>
    // <NavItem>
    //     <NavLink tag={RouteNavLink} to={item.to}>
    //         <div className='d-flex item-icon-wrapper'>
    //             {item.title && <div className='bullet' />}
    //             {item.title && <span>{item.title}</span>}
    //         </div>
    //     </NavLink>
    // </NavItem>
);

SidebarNavInnerItem.propTypes = {
    /**
     * The item object.
     */
    item: PropTypes.object
};

export default SidebarNavInnerItem;
