import React from 'react';
import {
    Container, CardBody, Row, Col, Badge, ButtonGroup, Button, Alert
} from "shards-react";
import * as moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import ReactStars from 'react-stars';
import { colors } from '../../../utils/colors'

class ReviewTable extends React.Component {
    state = {
        selected: [],
        count: 0
    }

    componentDidUpdate(props) {
        if (this.props.count !== props.count) {
            this.setState({ count: this.props.count })
        }
    }

    fetchColour(key) {
        if (key) {
            return { background: 'rgb(23,198,113)' }
        }
        return { background: 'rgb(0, 184, 216)' }
    }

    render() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Reviews Found</h3>
                        <p>It could be that there are no service calls with sent notifications. </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3" style={{ background: 'transparent' }}>
                <Row className='d-flex py-2 px-2 mx-0 row-header border-bottom'>
                    <Col md='0' lg='1' className='px-1 py-1 hide-on-tablet'>Date</Col>
                    <Col md='1' lg='1' className='px-1 py-1'>Job No</Col>
                    <Col md='0' lg='1' className='px-1 py-1 hide-on-tablet'>Agent</Col>
                    <Col md='0' lg='1' className='px-1 py-1 hide-on-tablet' style={{ minWidth: '100px' }}>Resolved</Col>
                    <Col sm='0' lg='1' className='px-1 py-1 hide-on-mobile' style={{ minWidth: '100px' }}>Conduct</Col>
                    <Col md='1' lg='1' className='px-1 py-1 ' style={{ minWidth: '100px' }}>Turnaround</Col>
                    <Col sm='0' lg='1' className='px-1 py-1 hide-on-mobile' style={{ minWidth: '100px' }}>Satisfaction</Col>
                    <Col sm='0' lg='4' className='px-1 py-1 hide-on-mobile'>Notes</Col>
                </Row>
                {this.props.data.map((row, idx) => {
                    const resolved = row['issue_resolved'] ? <Badge pill theme='success'>Yes</Badge> : <Badge pill theme='warning'>No</Badge>
                    return (
                        <Row className='notification-list__item d-flex py-2 px-2 mx-0 row-hightlight' >
                            <Col md='0' lg='1' className='px-0 py-1 hide-on-tablet'>
                                <div className='py-1'>
                                    {moment(row['created_at']).format('YYYY-MM-DD')}
                                </div>
                            </Col>
                            <Col md='1' lg='1' className='px-1 py-1'>
                                <div className='py-1 text-uppercase'>
                                    {row['job'] ? row['job']['job_no'] : ''}
                                </div>
                            </Col>
                            <Col md='0' lg='1' className='px-1 py-1 hide-on-tablet'>
                                <div className='py-1'>
                                    {row['agent'] ? row['agent']['full_name'] : ''}
                                </div>
                            </Col>
                            <Col md='0' lg='1' className='px-1 py-1 hide-on-tablet' style={{ minWidth: '100px' }}>
                                <div className='py-1 text-uppercase'>
                                    {resolved}
                                </div>
                            </Col>
                            <Col sm='0' lg='1' className='px-1 py-1 hide-on-mobile' style={{ minWidth: '100px' }}>
                                <div className='py-1'>
                                    <ReactStars
                                        value={row['agent_interaction']}
                                        count={5}
                                        edit={false}
                                        size={14}
                                        color='#868e96'
                                        className='mx-auto'
                                    />
                                </div>
                            </Col>
                            <Col md='1' lg='1' className='px-1 py-1 ' style={{ minWidth: '100px' }}>
                                <div className='py-1 '>
                                    <ReactStars
                                        value={row['turnaround_satisfaction']}
                                        count={5}
                                        edit={false}
                                        size={14}
                                        color='#868e96'
                                        className='mx-auto'
                                    />
                                </div>
                            </Col>
                            <Col sm='0' lg='1' className='px-1 py-1 hide-on-mobile' style={{ minWidth: '100px' }}>
                                <div className='py-1'>
                                    <ReactStars
                                        value={row['overall_satisfaction']}
                                        count={5}
                                        edit={false}
                                        size={14}
                                        color='#868e96'
                                        className='mx-auto'
                                    />
                                </div>
                            </Col>
                            <Col sm='0' lg='4' className='px-1 py-1 hide-on-mobile'>
                                <div className='pt-2'>
                                    <Alert style={{ borderRadius: '2%', background: colors.OFF_WHITE, color: '#3d5170' }}>
                                        {row['review_note'] || 'No comment'}
                                    </Alert>
                                </div>
                            </Col>
                        </Row>
                    )
                })
                }
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody >
        )
    }
}

export default withRouter(ReviewTable)


