import React from 'react';
import { Container, Card, CardBody, Row, Col, Button, ButtonGroup, InputGroup, InputGroupText, InputGroupAddon, DatePicker } from 'shards-react';

import PageTitle from './../components/common/PageTitle';
import RangeDatePicker from '../components/common/RangeDatePicker';
import DateColumnFilter from '../components/libs/DateColumnFilter';
import { fetch_metrics, fetch_agents, fetch_service_areas } from '../store/action';
import lodash from 'lodash';
import * as moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Dashboard extends React.Component {
    state = {
        statuses: {},
        priorities: {},
        appointmentDate: null,
        filter: 'created_at',
        filterName: 'Imported Date',
        showDateColumnFilter: false,
        startAt: null,
        endAt: null,
        sponsor_name : null
    }


    componentDidMount() {
        if (this.props.fetch_metrics) {
            this.props.fetch_metrics()
        }
        if (this.props.fetch_agents) {
            this.props.fetch_agents()
        }
        if (this.props.fetch_service_areas) {
            this.props.fetch_service_areas()
        }
    }

    static getDerivedStateFromProps(props) {
        if (props.metrics) {
            return {
                statuses: props.statuses || {},
                priorities: props.priorities || {}
            }
        } else {
            return {
                statuses: {},
                priorities: {}
            }
        }
    }

    fetchColour(key) {
        switch (key) {
            case 'completed':
            case 'submitted':
                // green
                return { color: '#81A94F', background: 'rgba(23,198,113,0.1)', borderColor: '#e1e5eb' }
            case 'priorityUrgent':
            case 'cancelled':
            case 'unableToContact':
                // red
                return { color: '#DD5757', background: 'rgba(255,65,105,0.1)', borderColor: 'rgb(255,65,105)' }
            case 'inHand':
            case 'priorityMedium':
                // yellow
                return { color: '#EBB748', background: 'rgba(255,180,0,0.1)', borderColor: '#e1e5eb' }
            case 'priorityHigh':
            case 'generated':
                // orange
                return { color: '#E19229', background: 'rgba(255,180,0,0.1)', borderColor: '#e1e5eb' }
            case '':
                return { color: 'rgb(0,123,255)', background: 'rgb(0,123,255,0.1)', borderColor: '#e1e5eb' }
            case 'assigned':
            case 'storeWalkIn':
            case 'referral':
            case 'notYetAllocated':
            case 'allocated_to_asc':
            case 'allocated':
            case 'allJobs':
                // blue
                return { color: 'rgb(0, 184, 216)', background: 'rgba(0, 184, 216, 0.1)', borderColor: '#e1e5eb' }
            case 'refer_to_defy':
            case 'partsOrdered':
            case 'partsNotOrdered':
            case 'awaitingPartsOrdered':
            case 'awaitingPartsNotOrdered':
            case 'referToDefy':
            case 'awaiting_parts':
            case 'awaiting_parts_not_ordered':
            case 'awaiting_parts_ordered':
            case 'awaiting_parts_refer_to_defy':
            case 'active':
            case 'archived':
                return { colors: '#323539', background: 'white', borderColor: '#e1e5eb' }
            default:
                return { color: '#A9B0BC', background: 'white', borderColor: '#e1e5eb' }
        }
    }

    onFilter(key, filter) {
        const startAt = this.state.startAt || null
        const endAt = this.state.endAt || null
        const appointmentAt = this.state.appointmentDate ? moment(this.state.appointmentDate).format('YYYY-MM-DD') : null

        if (key === 'view') {
            const params = {
                startAt, endAt, appointmentAt
            }
            if (filter) {
                params['dateColumn'] = filter
            }
            this.props.fetch_metrics(params)
        } else {
            var route = '/jobs'

            switch (key) {
                case 'onAccount': {
                    route = '/jobs?jobType=chargable_on_account'
                    break;
                }
                case 'cashJobs': {
                    route = '/jobs?jobType=cash_call'
                    break;
                }
                case 'callOut': {
                    route = '/jobs?jobType=call_out'
                    break;
                }
                case 'walkIn': {
                    route = '/jobs?jobType=walk_in'
                    break;
                }
                case 'warrantyJobs': {
                    route = '/jobs?jobType=warranty'
                    break;
                }
                case 'repeat': {
                    route = '/jobs?jobType=repeat_call'
                    break;
                }
                case 'commercial': {
                    route = '/jobs?jobType=commercial'
                    break;
                }
                case 'refurbishJobs':
                case 'refurbish': {
                    route = '/jobs?jobType=refurbish'
                    break;
                }
                case 'completed': {
                    route = '/jobs?status=completed'
                    break;
                }
                case 'cancelled': {
                    route = '/jobs?status=cancelled'
                    break;
                }
                case 'closed': {
                    route = '/jobs?status=closed'
                    break;
                }
                case 'unableToContact': {
                    route = '/jobs?status=unable_to_contact'
                    break;
                }
                case 'inHand': {
                    route = '/jobs?status=in_hand_by_agent'
                    break;
                }
                case 'awaitingPartsNotOrdered': {
                    route = '/jobs?status=awaiting_parts_not_ordered'
                    break;
                }
                case 'awaitingPartsOrdered': {
                    route = '/jobs?status=awaiting_parts_ordered'
                    break;
                }
                case 'awaitingPartsReferToDefy': {
                    route = '/jobs?status=awaiting_parts_refer_to_defy'
                    break;
                }
                case 'referToDefy': {
                    route = '/jobs?status=refer_to_defy'
                    break;
                }
                case 'pickupForWorkshop': {
                    route = '/jobs?status=pickup_for_workshop'
                    break;
                }
                case 'completedToday': {
                    route = '/jobs?status=completed&completedAt=today'
                    break;
                }
                case 'awaitingPartsReferToManagement': {
                    route = '/jobs?status=awaiting_parts_refer_to_management'
                    break;
                }
                case 'arcRepeat': {
                    route = '/jobs?is_repeat=true'
                    break;
                }
                case 'referToManagement': {
                    route = '/jobs?status=refer_to_management'
                    break;
                }
                case 'pickupForWorkshopNotPickedUp': {
                    route = '/jobs?status=not_pickup_for_workshop'
                    break;
                }
                case 'notYetAllocated':
                case 'allocated': {
                    route = '/jobs?status=allocated_to_asc'
                    break;
                }
                case 'installation': {
                    route = '/jobs?jobType=installation'
                    break;
                }
                case 'assigned': {
                    route = '/jobs?status=assigned_to_agent'
                    break;
                }
                case 'partsOrdered': {
                    route = '/jobs?status=awaiting_parts_ordered'
                    break;
                }
                case 'partsNotOrdered': {
                    route = '/jobs?status=awaiting_parts_not_ordered'
                    break;
                }
                case 'quotation': {
                    route = '/jobs?status=quotation'
                    break;
                }
                case 'bosch': {
                    route = '/jobs?insure_type=bosch'
                    break;
                }
                case 'insurance': {
                    route = '/jobs?insure_type=insurance'
                    break;
                }
                case 'samsung': {
                    route = '/jobs?insure_type=samsung'
                    break;
                }
                case 'candyAppliances': {
                    route = '/jobs?insure_type=candy_appliances'
                    break;
                }
                case 'technicalReport': {
                    route = '/jobs?status=technical_report'
                    break;
                }
                case 'pickupForWorkshopPickedUp': {
                    route = '/jobs?status=pickup_for_workshop'
                    break;
                }
                case 'storeWalkIn': {
                    route = '/jobs?jobOrigin=walk_in'
                    break;
                }
                case 'referral': {
                    route = '/jobs?jobOrigin=referral'
                    break;
                }
                case 'invoiceGenerated':
                case 'generated': {
                    route = '/jobs?invoiceState=invoice_generated'
                    break;
                }
                case 'invoiceSubmitted':
                case 'submitted': {
                    route = '/jobs?invoiceState=invoice_submitted'
                    break;
                }
                case 'priorityLow': {
                    route = '/jobs?priority=low&status=allocated_to_asc'
                    break;
                }
                case 'priorityMedium': {
                    route = '/jobs?priority=medium&status=allocated_to_asc'
                    break;
                }
                case 'priorityHigh': {
                    route = '/jobs?priority=high&status=allocated_to_asc'
                    break;
                }
                case 'priorityUrgent': {
                    route = '/jobs?priority=urgent&status=allocated_to_asc'
                    break;
                }
                case 'allJobs': {
                    route = '/jobs?retrieveAll=true'
                    break;
                }
                default: {
                    route = '/jobs?'
                    break;
                }
            }

            if(this.props.user && this.props.user['sponsor_name'] === 'ARC'){
                switch (key) {
                    case 'notYetAllocated':
                    case 'allocated': {
                        route = '/jobs?status=allocated_to_arc'
                        break;
                    }
                    case 'priorityLow': {
                        route = '/jobs?priority=low&status=allocated_to_arc'
                        break;
                    }
                    case 'priorityMedium': {
                        route = '/jobs?priority=medium&status=allocated_to_arc'
                        break;
                    }
                    case 'priorityHigh': {
                        route = '/jobs?priority=high&status=allocated_to_arc'
                        break;
                    }
                    case 'priorityUrgent': {
                        route = '/jobs?priority=urgent&status=allocated_to_arc'
                        break;
                    }
                }
            }

            if (key === 'archived' || key.indexOf('archived') > -1) {
                route = '/archives?'
            }

            if (key === 'invoiceArchived') {
                route = '/archives?tab=invoices'
            }

            if (startAt) {
                route = route + `&startAt=${startAt}`
            }
            if (endAt) {
                route = route + `&endAt=${endAt}`
            }
            if (appointmentAt) {
                route = route + `&appointmentAt=${appointmentAt}`
            }
            route = route + `&dateColumn=${this.state.filter}`

            var state = { tab: 0, dateColumn: this.state.filter }

            this.props.history.push(route, state)
        }
    }

    toggle(title) {
        const state = {
            showDateColumnFilter: false
        }
        if (title) {
            state[title] = !this.state[title]
        }
        this.setState(state);
    }

    render() {
        var { statuses1, statuses2, statuses3, statuses4, statuses5, priorities, types, invoices, filed } = {}
        if (this.props.metrics) {
            statuses1 = this.props.metrics.statuses ? this.props.metrics.statuses[0] : {}
            statuses2 = this.props.metrics.statuses ? this.props.metrics.statuses[1] : {}
            statuses3 = this.props.metrics.statuses ? this.props.metrics.statuses[2] : {}
            statuses4 = this.props.metrics.statuses ? this.props.metrics.statuses[3] : {}
            statuses5 = this.props.metrics.statuses ? this.props.metrics.statuses[4] : {}
            priorities = this.props.metrics.priorities
            types = this.props.metrics.types
            invoices = this.props.metrics.invoices
            filed = this.props.metrics.filed
        }

        var appointmentDate = this.state.appointmentDate || ''
        if (this.state.appointmentDate) {
            appointmentDate = moment(this.state.appointmentDate).format('DD MMM YYYY')
        }

        return (
            <Container fluid className='main-content-container px-4'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle title='Overview' subtitle='Dashboard' className='text-sm-left mb-3' />
                </Row>
                <Row noGutters className='mb-4 py-2 px-3 border bg-light'>
                    <Col sm='12' md='4' lg='4' className='d-flex'>
                        <RangeDatePicker
                            style={{ flex: 10 }}
                            placeholderStartAt='Filter Start Date'
                            placeholderEndAt='Filter End Date'
                            onStartAt={(startAt) => {
                                this.setState({ startAt })
                            }}
                            onEndAt={(endAt) => {
                                this.setState({ endAt })
                            }}
                        />
                    </Col>
                    <Col sm='12' md='2' lg='3' className='d-flex pl-2'>
                        <InputGroup className='d-flex my-auto date-range'>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={this.state.appointmentDate}
                                onChange={(value) => this.setState({
                                    appointmentDate: value
                                })}
                                placeholderText={this.state.startDate || 'Appointment Date'}
                                dropdownMode='select'
                                value={appointmentDate}
                                className='text-center'
                            />
                            <InputGroupAddon type='append'>
                                <InputGroupText>
                                    <i className='material-icons'>&#xE916;</i>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col sm='12' md='3' lg='3' className='d-flex pl-2'>
                        {/* Additional Line Filter */}
                        <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                            <InputGroup>
                                <InputGroupAddon type="prepend">
                                    <InputGroupText>Query</InputGroupText>
                                </InputGroupAddon>
                                <InputGroupAddon type="append">
                                    <Button
                                        size='sm'
                                        className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                        onClick={() => this.toggle('showDateColumnFilter')}
                                    >
                                        <span style={{ fontSize: '0.75rem' }}>{this.state.filterName}</span>
                                        <span style={{ fontSize: '0.75rem' }}></span>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <DateColumnFilter visible={this.state.showDateColumnFilter} onSelect={(filter) => {
                                if (filter.value) {
                                    this.onFilter('view', filter.value)
                                    this.setState({ showDateColumnFilter: false, filter: filter.value, filterName: filter.label })
                                }

                            }} />
                        </div>
                    </Col>
                    <Col sm='12' md='3' lg='2' className='d-flex pl-2'>
                        <ButtonGroup>
                            <Button
                                size='md'
                                className='btn-primary ml-auto mr-0 mt-3 mt-sm-0 px-4 w-100'
                                onClick={() => this.onFilter('view')}
                            >
                                <span style={{ fontSize: '0.75rem' }}>View</span>
                            </Button>
                            <Button
                                size='md'
                                className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0 px-2 w-100  px-4'
                                onClick={() => this.onFilter('search')}
                            >
                                <span style={{ fontSize: '0.75rem' }}>Search</span>
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    {priorities && Object.keys(priorities).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex' style={{ background: this.fetchColour(val).color }} >
                                    <div className='d-flex flex-column m-auto' >
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase' style={{ color: 'white' }}>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: 'white' }}>{priorities[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {statuses1 && Object.keys(statuses1).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex'>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{statuses1[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {statuses2 && Object.keys(statuses2).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex'>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{statuses2[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {statuses3 && Object.keys(statuses3).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex'>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{statuses3[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {statuses4 && Object.keys(statuses4).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex'>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{statuses4[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {invoices && Object.keys(invoices).map((val, idx) => {
                        return (
                            <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                                <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                    <CardBody className='p-0 d-flex'>
                                        <div className='d-flex flex-column m-auto'>
                                            <div className='stats-small__data text-center'>
                                                <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                                <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{invoices[val]}</h6>
                                            </div>
                                            <div className='stats-small__data'>
                                                <span className='text-right align-items-center'></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    {statuses5 && Object.keys(statuses5).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex'>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{statuses5[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {types && Object.keys(types).map((val, idx) => {
                        const label = val === 'allJobs' ? 'All Jobs (Active + Archived)' : lodash.startCase(val)
                        return (
                            <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                                <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                    <CardBody className='p-0 d-flex'>
                                        <div className='d-flex flex-column m-auto'>
                                            <div className='stats-small__data text-center'>
                                                <span className='stats-small__label text-uppercase'>{label}</span>
                                                <h6 className='stats-small__value count my-3' style={{ color: this.fetchColour(val).color }}>{types[val]}</h6>
                                            </div>
                                            <div className='stats-small__data'>
                                                <span className='text-right align-items-center'></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    {filed && Object.keys(filed).map((val, idx) => (
                        <Col className='col-lg mb-4' key={idx} md='6' sm='6'>
                            <Card small className='stats-small stats-small--1 cursor-pointer' onClick={() => this.onFilter(val)}>
                                <CardBody className='p-0 d-flex' style={{ background: '#323539' }}>
                                    <div className='d-flex flex-column m-auto'>
                                        <div className='stats-small__data text-center'>
                                            <span className='stats-small__label text-uppercase'>{lodash.startCase(val)}</span>
                                            <h6 className='stats-small__value count my-3' style={{ color: '#FFFFFF' }}>{filed[val]}</h6>
                                        </div>
                                        <div className='stats-small__data'>
                                            <span className='text-right align-items-center'></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        metrics: state.metrics
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_metrics: (params) => dispatch(fetch_metrics(params)),
        fetch_service_areas: () => dispatch(fetch_service_areas()),
        fetch_agents: () => dispatch(fetch_agents())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

