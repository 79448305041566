import React from 'react';
import { Col, Card, CardBody, Button, ButtonToolbar, InputGroup, Row, FormInput, ButtonGroup, Alert } from 'shards-react';
import { withRouter } from 'react-router-dom';

class UserActions extends React.Component {
    state = {
        open: false
    }

    render() {
        return (
            <div className='job-actions'>
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='6' lg='8' className='mb-2 mb-sm-2'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search by first name" style={{ width: '100%' }} onChange={e => this.props.onSearch(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col sm='12' md='6' lg='4' className='mb-2 mb-sm-2'>
                                <ButtonToolbar>
                                    <ButtonGroup size="sm">
                                        <Button size='md' theme='accent'
                                            type='button'
                                            className='btn-green border-accent py-0 ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onAddUser()}
                                        >
                                            <div className='d-flex text-white'>
                                                <span className='text-white py-0 my-0'>Create User</span>
                                                <div
                                                    className='d-inline-block'
                                                    style={{ marginLeft: '4px', marginTop: '-1px' }}
                                                    dangerouslySetInnerHTML={{ __html: '<i class="material-icons">add</i>' }}
                                                />
                                            </div>
                                        </Button>
                                        <Button
                                            size='md'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                        </Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default withRouter(UserActions);
