import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import AgentTable from '../components/libs/agents/AgentTable';
import AgentActions from '../components/libs/agents/AgentActions';
import AgentPopup from '../components/libs/agents/AgentPopup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_agents } from '../store/action';

class Agents extends React.Component {
    state = {
        agents: [],
        pagination: {},
        showModel: false,
        viewOnly: false,
        page: 1
    }

    async componentDidMount() {
        if (this.props.fetch_agents) {
            this.props.fetch_agents(1)
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            pagination: props.pagination || {},
            agents: props.agents || []
        }
    }

    onPage(next = false, prev = false) {
        const state = this.state
        var page = null
        if (next) {
            state['page'] = state.page + 1
            page = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
            page = state.page - 1
        }
        this.setState(state)
        delete state['agents']
        this.props.fetch_agents(state['page'])
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onFilter(key, value) {
        const state = this.state
        state[key] = value

        state['page'] = 1

        this.setState(state)
        delete state['agents']
        this.props.fetch_agents(1, state)
    }

    render() {
        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                {this.state.showModel && <AgentPopup
                    data={this.state.agent}
                    viewOnly={this.state.viewOnly}
                    onCancel={() => {
                        this.setState({ showModel: false, page: 1 })
                        this.props.fetch_agents(1)
                    }}
                    onSuccess={() => {
                        this.setState({ showModel: false })
                        this.props.fetch_agents(1)
                    }}
                />}
                <AgentActions
                    onAddAgent={() => this.setState({ agent: null, showModel: true })}
                    onSearch={search => this.onFilter('search', search)}
                    onFilterState={state => this.onFilter('state', state)}
                    onFilterService={area => this.onFilter('service', area)}
                    onClearAll={() => this.props.history.push('/refresh-agents', { clear: true })}
                />
                <Row className='mx-0'>
                    <Col>
                        <div className='job-table'>
                            <Card small className="mb-4" style={{ position: 'relative' }}>
                                <AgentTable
                                    data={this.state.agents}
                                    onSelect={(agent) => this.setState({ agent, showModel: true, viewOnly: false })}
                                    onSelectView={(agent) => this.setState({ agent, showModel: true, viewOnly: true })}
                                    onPrev={() => this.onPage(false, true)}
                                    onNext={() => this.onPage(true)}
                                    page={this.state.page}
                                    count={this.state.pagination.count}
                                    onSuccess={() => this.props.fetch_agents(1)}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        agents: state.agents,
        pagination: state.pagination
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_agents: (page, params) => dispatch(fetch_agents(page, params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Agents));
