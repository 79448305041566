import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import { isProduction, isStaging } from './config';
if (isProduction || isStaging) {
    console.error = () => { }
    console.info = () => { }
    console.log = () => { }
    console.warn = () => { }
    console.debug = () => { }
} else {
    console.error = () => { }
    // console.info = () => { }
    // console.log = () => { }
    console.warn = () => { }
    console.debug = () => { }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        ReactDOM.render(
            <NextApp />,
            document.getElementById('root')
        )
    })
}
