import React from "react";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../menus/MainNavbar/MainNavbar";
import MainSidebar from "../menus/MainSidebar/MainSidebar";
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class DefaultLayout extends React.Component {
    state = {
        user: null
    }

    componentDidMount() {
        const isLoggedInToday = this.props.user && this.props.lastLoggin && this.props.lastLoggin === moment().format('YYYY-MM-DD')

        if (this.props.user && this.props.isLoggedIn && isLoggedInToday) {
            // do nothing
        } else {
            this.props.history.push('/auth/signin')
        }
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <MainSidebar />
                    <Col
                        className="main-content p-0"
                        lg={{ size: 10, offset: 2 }}
                        md={{ size: 9, offset: 3 }}
                        sm="12"
                        tag="main"
                    >
                        {!this.props.noNavbar && <MainNavbar />}
                        <Row>
                            <Col md='12' lg='12'>
                                <div style={{ height: '100%', overflowY: 'hidden', position: 'relative' }}>
                                    {this.props.children}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        lastLoggin: state.lastLoggin
    }
}

export default connect(mapStateToProps, null)(withRouter(DefaultLayout));

