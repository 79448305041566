import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormInput,
    FormCheckbox,
    FormSelect,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../../utils/colors';
import { fetchPost } from '../../../utils/api';

class UserPopup extends React.Component {
    state = {
        data: {},
        viewOnly: false,
        loading: false
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ data: this.props.data, viewOnly: this.props.viewOnly, loading: false })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data
            })
        }

        if (this.props.viewOnly !== prevProps.viewOnly) {
            this.setState({
                viewOnly: this.props.viewOnly
            })
        }
    }

    render() {
        const data = this.state.data || {}
        const title = this.state.viewOnly ? 'View User' : data['uuid'] ? 'Update User' : 'Create User'
        const mobile = data['contact_no'] && data['contact_no'].startsWith('+27') ? data['contact_no'].replace('+27', '0') : data['contact_no']

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>{title}</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        var api = '/users/create'
                                                        if (data['uuid']) {
                                                            api = '/users/update/' + data['uuid']
                                                        }

                                                        this.setState({ loading: true })
                                                        fetchPost(api,
                                                            {
                                                                first_name: this.state.firstName || data['first_name'],
                                                                last_name: this.state.lastName || data['last_name'],
                                                                email: this.state.email || data['email'],
                                                                contact_no: this.state.mobile || data['contact_no'],
                                                                temp_password: this.state.temp,
                                                                reset_password: this.state.temp ? true : false,
                                                                user_type: this.state.userType || data['user_type'],
                                                                service_org: this.state.service_org || data['service_org'],

                                                            },
                                                            (response) => {
                                                                if (response && response.status === 200) {
                                                                    this.setState({ message: response.message, loading: false, success: true ,temp:response.temp_password})
                                                                } else {
                                                                    this.setState({ message: response.message, loading: false, success: false })
                                                                }
                                                            },
                                                            (response) => {
                                                                this.setState({ message: response.message, loading: false, success: false})
                                                            },
                                                            true
                                                        )
                                                    }}
                                                >
                                                    <Row>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>User ID</label>
                                                            <FormInput
                                                                id='user_id'
                                                                disabled={true}
                                                                placeholder='Autogenerated'
                                                                value={data['user_id'] || ''}
                                                            />
                                                        </Col>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>First name</label>
                                                            <FormInput
                                                                id='firstName'
                                                                placeholder={data['first_name'] || 'First Name'}
                                                                value={this.state.firstName || ''}
                                                                disabled={this.state.viewOnly}
                                                                required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>Last Name</label>
                                                            <FormInput
                                                                id='lastName'
                                                                placeholder={data['last_name'] || 'Last Name'}
                                                                value={this.state.lastName || ''}
                                                                disabled={this.state.viewOnly}
                                                                required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Email</label>
                                                            <FormInput
                                                                id='email'
                                                                placeholder={data['email'] || 'email'}
                                                                value={this.state.email || ''}
                                                                type='email'
                                                                disabled={this.state.viewOnly}
                                                                // required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>User Type</label>
                                                            <FormSelect
                                                                id='userType'
                                                                value={this.state.userType || data['user_type']}
                                                                onChange={(e) => this.setState({ userType: e.target.value })}
                                                            >
                                                                <option value='operator'>Operator</option>
                                                                <option value='manager'>Manager</option>
                                                                <option value='admin'>Admin</option>
                                                                <option value='owner'>Owner</option>
                                                                <option value='super'>Super</option>
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Mobile Number</label>
                                                            <FormInput
                                                                id='contact_mobile'
                                                                placeholder={mobile || 'Mobile Number eg. 0123456789'}
                                                                required={!!!data['uuid']}
                                                                disabled={this.state.viewOnly}
                                                                maxlength={10}
                                                                minlength={10}
                                                                type='number'
                                                                value={this.state.mobile || ''}
                                                                onChange={(e) => {
                                                                    this.setState({ mobile: e.target.value })
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Temp Password</label>
                                                            <FormInput
                                                                id='temp_password'
                                                                placeholder='Temp Password'
                                                                value={this.state.temp || data['temp_password'] || ''}
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {!this.state.viewOnly && (
                                                        <Row>
                                                            <Col md='6' className='form-group'>
                                                                <div className='d-block my-2'>
                                                                    Create a new password for this user? System will autogenerate a password when you select reset.
                                                                </div>
                                                            </Col>
                                                            <Col md='6' className='form-group'>
                                                                <div className='d-block my-2'>
                                                                    <Button
                                                                        size='md'
                                                                        className='btn-yellow ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            const api = '/users/generate_password/' + data['uuid']
                                                                            fetchPost(api,
                                                                                {},
                                                                                (response) => {
                                                                                    this.setState({
                                                                                        message: 'New password generated: ' + response,
                                                                                        success: true,
                                                                                        temp: response
                                                                                    })
                                                                                },
                                                                                (response) => {
                                                                                    this.setState({ message: 'Error resetting password', loading: false })
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: this.state.success ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    {this.state.loading && <p>...Submitting</p>}

                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        {this.state.success ? 'Done' : 'Cancel'}
                                                    </Button>
                                                    {!this.state.viewOnly && (<Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type='submit'
                                                    >
                                                        Submit
                                                    </Button>)}
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect()(withRouter(UserPopup));
