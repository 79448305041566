import React from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, InputGroup, FormInput } from 'shards-react';

import RangeDatePicker from '../../common/RangeDatePicker';
import ServiceAreaFilter from '../ServiceAreaFilter';
import JobStatusFilter from '../jobs/JobStatusFilter';
import AgentFilter from '../agents/AgentFilter';
import ArchiveFilter from '../ArchiveFilter';

class NotificationActions extends React.Component {
    state = {
        open: false,
        title: '',
        showServiceFilter: false,
        showAgentFilter: false,
        showSponsorFilter: false,
        showArchiveFilter: false,
        isArchived: null,
        areas: [],
        sponsors: [],
        status: null,
        area: null,
        agent: null,
        dates: null,
        length: 0
    }

    componentDidUpdate(props) {
        if (this.props.sponsors !== props.sponsors) {
            this.setState({
                sponsors: this.props.sponsors
            })
        }

        if (this.props.areas !== props.areas) {
            this.setState({
                areas: this.props.areas
            })
        }

        if (this.props.length !== props.length) {
            this.setState({
                length: this.props.length
            })
        }

        if (this.props.currentState && this.props.currentState !== props.currentState) {
            const currentState = this.props.currentState
            this.setState({
                serviceArea: currentState.service || null,
                status: currentState.status || null,
                // agent: currentState.agent || null,
                dates: currentState.dates || null,
            })
        }

        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({
                serviceArea: null,
                status: null,
                agent: null,
                dates: null
            })
        }
    }

    toggle(title) {
        if (title === 'showUpdate') {
            this.props.onUpdateMultiple()
        } else {
            const state = {
                showServiceFilter: false,
                showStatusFilter: false,
                showAgentFilter: false,
                showSponsorFilter: false,
                showArchiveFilter: false
            }
            if (title) {
                state[title] = !this.state[title]
            }
            this.setState(state);
        }
    }

    render() {
        return (
            <div className='job-actions'>
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='6' lg='5' className='d-flex'>
                                <RangeDatePicker
                                    dates={this.state.dates}
                                    onStartAt={(startAt) => {
                                        this.props.onStartAt(startAt)
                                    }}
                                    onEndAt={(endAt) => {
                                        this.props.onEndAt(endAt)
                                    }}
                                />
                            </Col>
                            <Col sm='12' md='6' lg='5'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search job no." style={{ width: '100%' }} onChange={e => this.props.onSearch(e.target.value)} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='6' className='mb-2'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Agent Filter */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showAgentFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.agent || `Agent Filter`}</span>
                                            {this.state.agent ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <AgentFilter visible={this.state.showAgentFilter} selected={this.state.selected} onSelect={(filter) => {
                                            this.props.onFilterAgent(filter)
                                            this.setState({ showAgentFilter: false, agent: filter.value ? filter.label : null })
                                        }} />
                                    </div>
                                    {/* Job Status */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showStatusFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.status || 'Status Filter'}</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <JobStatusFilter visible={this.state.showStatusFilter} onSelect={(filter) => {
                                            this.props.onFilterStatus(filter.value)
                                            this.setState({ showStatusFilter: false, status: filter.value ? filter.label : null })
                                        }} status={this.state.status} />
                                    </div>
                                    {/* Service Area Filter */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showServiceFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.serviceArea || 'Service Area Filter'}</span>
                                            {this.state.serviceArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <ServiceAreaFilter visible={this.state.showServiceFilter} areas={this.state.areas} onSelect={(filter) => {
                                            this.props.onFilterService(filter.value)
                                            this.setState({ showServiceFilter: false, serviceArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>
                                </ButtonGroup>
                            </Col>
                            <Col sm='12' md='12' lg='6' className='mb-2'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Archive Filter */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showArchiveFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.isArchived || `Archive Filter`}</span>
                                            <span style={{ fontSize: '0.75rem' }}></span>
                                        </Button>
                                        <ArchiveFilter visible={this.state.showArchiveFilter} onSelect={(filter) => {
                                            const label = filter.value !== null ? filter.label : null
                                            this.props.onFilterArchived(filter.value)
                                            this.setState({ showArchiveFilter: false, isArchived: label })
                                        }} />
                                    </div>
                                    {/* Export CSV */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onExport()}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Export &rarr;</span>
                                        </Button>
                                    </div>
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.toggle()
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                        </Button>
                                    </div>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default NotificationActions;
