import React from "react";
import { Container, Button } from "shards-react";
import { withRouter } from 'react-router-dom';

const IMG = require('../assets/images/construction.png').default;

const NoticePage = (props) => (
    <Container fluid className="main-content-container px-4 py-0">
        <div className="error">
            <div className="error__content">
                <img
                    id='main-logo'
                    className='d-inline-block align-top mr-1'
                    style={{ maxWidth: '140px' }}
                    src={IMG}
                    alt='Under Construction'
                />
                <h2>503</h2>
                <h3>Development In Progress</h3>
                <p className='larger'>Page is under construction </p>
            </div>
        </div>
    </Container>
);

export default withRouter(NoticePage);
