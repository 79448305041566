import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormInput,
    FormCheckbox,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../../utils/colors';
import { fetchPost } from '../../../utils/api';



class AgentPopup extends React.Component {
    state = {
        areas: [],
        data: {},
        viewOnly: false,
        loading: false
    }

    componentDidMount() {
        if (this.props.data) {
            const areas = this.props.data['areas'].map(area => {
                return {
                    service_area: area.code,
                    sponsor_area: area.sponsor,
                    description: area.description.replace(/_/g, ' ')
                }
            })
            this.setState({ areas: areas, data: this.props.data, viewOnly: this.props.viewOnly, loading: false })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            const areas = this.props.data['areas'].map(area => {
                return {
                    service_area: area.code,
                    sponsor_area: area.sponsor,
                    description: area.description.replace(/_/g, ' ')
                }
            })
            this.setState({
                data: this.props.data, areas: areas
            })
        }

        if (this.props.viewOnly !== prevProps.viewOnly) {
            this.setState({
                viewOnly: this.props.viewOnly
            })
        }
    }

    render() {

        var serviceAreas = []
        if(this.props.user && this.props.user.sponsor_name === 'ARC'){
             serviceAreas = [
                {
                    service_area: '01',
                    sponsor_area: 'k19',
                    description: 'country'
                },
                {
                    service_area: '02',
                    sponsor_area: 'k19',
                    description: 'local'
                },
                {
                    service_area: '03',
                    sponsor_area: 'k19',
                    description: 'paarl | stellenbosh | somerset west | township'
                },
                {
                    service_area: '04',
                    sponsor_area: 'k19',
                    description: "hermanus | pringle bay | betty's bay | kleinmond"
                },
                {
                    service_area: '05',
                    sponsor_area: 'k19',
                    description: 'gansbaai'
                },
                {
                    service_area: '06',
                    sponsor_area: 'k20',
                    description: 'pearly beach'
                },
            
                {
                    service_area: '07',
                    sponsor_area: 'k24',
                    description: 'distance from 341km to 400km'
                },
                {
                    service_area: '08',
                    sponsor_area: 'k21',
                    description: 'walk in small appliance'
                },
                {
                    service_area: '09',
                    sponsor_area: 'k22',
                    description: 'walk in large appliance'
                },
                {
                    service_area: '10',
                    sponsor_area: 'k23',
                    description: 'call out'
                }
            ]
        }else{
            serviceAreas = [
                {
                    service_area: '01',
                    sponsor_area: 'k19',
                    description: 'country'
                },
                {
                    service_area: '02',
                    sponsor_area: 'k19',
                    description: 'local'
                },
                {
                    service_area: '03',
                    sponsor_area: 'k19',
                    description: 'paarl | stellenbosh | somerset west | township'
                },
                {
                    service_area: '04',
                    sponsor_area: 'k19',
                    description: "hermanus | pringle bay | betty's bay | kleinmond"
                },
                {
                    service_area: '05',
                    sponsor_area: 'k19',
                    description: 'gansbaai'
                },
                {
                    service_area: '06',
                    sponsor_area: 'k20',
                    description: 'pearly beach'
                },
            
                {
                    service_area: '07',
                    sponsor_area: 'k24',
                    description: 'distance from 341km to 400km'
                }
            ]
        }
        const data = this.state.data || {}
        const title = this.state.viewOnly ? 'View Agent' : data['uuid'] ? 'Update Agent' : 'Create Agent'
        const mobile = data['contact_mobile'] && data['contact_mobile'].startsWith('+27') ? data['contact_mobile'].replace('+27', '0') : data['contact_mobile']
        const mobileAlt = data['contact_work'] && data['contact_work'].startsWith('+27') ? data['contact_work'].replace('+27', '0') : data['contact_work']

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>{title}</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        if (this.state.areas.length < 1) {
                                                            this.setState({
                                                                message: 'Select at least 1 service area'
                                                            })
                                                        } else {
                                                            var api = '/agents/create'
                                                            if (data['uuid']) {
                                                                api = '/agents/update/' + data['uuid']
                                                            }
                                                            const sponsorAreas = this.state.areas.map(x => x.sponsor_area).filter((value, index, self) => self.indexOf(value) === index)
                                                            this.setState({ loading: true })
                                                            fetchPost(api,
                                                                {
                                                                    first_name: this.state.firstName || data['first_name'],
                                                                    last_name: this.state.lastName || data['last_name'],
                                                                    email: this.state.email || data['email'],
                                                                    contact_mobile: this.state.mobile || data['contact_mobile'],
                                                                    contact_alt: this.state.mobileAlt || data['contact_alt'],
                                                                    id_number: this.state.idNumber || data['id_number'],
                                                                    temp_password: this.state.temp,
                                                                    reset_password: this.state.temp ? true : false,
                                                                    sponsor_areas: sponsorAreas,
                                                                    service_org_areas: this.state.areas.map(x => x.service_area),
                                                                },
                                                                (response) => {
                                                                    if (response && response.status === 200) {
                                                                        this.setState({ message: response.message,success: true, loading: false,temp:response.temp_password })
                                                                    } else {
                                                                        this.setState({ message: response.message, loading: false })
                                                                    }
                                                                },
                                                                (response) => {
                                                                    if (response) {
                                                                        this.setState({ message: response.message,success: true, loading: false,temp:response.temp_password })
                                                                    } else {
                                                                        this.setState({ message: response.message,success: false, loading: false})
                                                                    }
                                                                },
                                                                true
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <Row>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>Agent ID</label>
                                                            <FormInput
                                                                id='remote_id'
                                                                disabled={true}
                                                                placeholder='Autogenerated'
                                                                value={this.state.remoteId || data['remote_id'] || ''}
                                                                onChange={(e) => this.setState({ remoteId: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>First name</label>
                                                            <FormInput
                                                                id='firstName'
                                                                placeholder={data['first_name'] || 'First Name'}
                                                                value={this.state.firstName || ''}
                                                                disabled={this.state.viewOnly}
                                                                required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='4' className='form-group'>
                                                            <label htmlFor='customer'>Last Name</label>
                                                            <FormInput
                                                                id='lastName'
                                                                placeholder={data['last_name'] || 'Last Name'}
                                                                value={this.state.lastName || ''}
                                                                disabled={this.state.viewOnly}
                                                                required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Email</label>
                                                            <FormInput
                                                                id='email'
                                                                placeholder={data['email'] || 'email'}
                                                                value={this.state.email || ''}
                                                                type='email'
                                                                disabled={this.state.viewOnly}
                                                                // required={!!!data['uuid']}
                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>ID Number</label>
                                                            <FormInput
                                                                id='idNumber'
                                                                placeholder={data['id_number'] || '(Optional) ID Numbers'}
                                                                value={this.state.idNumber || ''}
                                                                disabled={this.state.viewOnly}
                                                                onChange={(e) => this.setState({ idNumber: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Mobile Number</label>
                                                            <FormInput
                                                                id='contact_mobile'
                                                                placeholder={mobile || 'Mobile Number eg. 0123456789'}
                                                                required={!!!data['uuid']}
                                                                disabled={this.state.viewOnly}
                                                                maxlength={10}
                                                                minlength={10}
                                                                type='number'
                                                                value={this.state.mobile || ''}
                                                                onChange={(e) => {
                                                                    this.setState({ mobile: e.target.value })
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Alt Mobile Number</label>
                                                            <FormInput
                                                                id='contact_mobile_alt'
                                                                placeholder={mobileAlt || '(Optional) Mobile Number eg. 0123456789'}
                                                                disabled={this.state.viewOnly}
                                                                maxlength={10}
                                                                minlength={10}
                                                                type='number'
                                                                value={this.state.mobileAlt || ''}
                                                                onChange={(e) => this.setState({ mobileAlt: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Invoices</label>
                                                            <FormInput
                                                                id='invoices'
                                                                placeholder='Invoices'
                                                                disabled={true}
                                                                value={data['invoice_count'] || 0}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='customer'>Temp Password</label>
                                                            <FormInput
                                                                id='temp_password'
                                                                placeholder='Temp Password'
                                                                value={this.state.temp || data['temp_password'] || ''}
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {!this.state.viewOnly && (
                                                        <Row>
                                                            <Col md='6' className='form-group'>
                                                                <div className='d-block my-2'>
                                                                    Create a new password for this agent? System will autogenerate a password when you select reset.
                                                                </div>
                                                            </Col>
                                                            <Col md='6' className='form-group'>
                                                                <div className='d-block my-2'>
                                                                    <Button
                                                                        size='md'
                                                                        className='btn-yellow ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            const api = '/agents/generate_password/' + data['uuid']
                                                                            fetchPost(api,
                                                                                {},
                                                                                (response) => {
                                                                                    this.setState({
                                                                                        message: 'New password generated: ' + response,
                                                                                        success: true,
                                                                                        temp: response
                                                                                    })
                                                                                },
                                                                                (response) => {
                                                                                    this.setState({ message: 'Error resetting password', loading: false })
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='customer'>Service Areas</label>
                                                            <table>
                                                                <tbody>
                                                                    {serviceAreas.map(area => {
                                                                        const checked = this.state.areas.find(x => x.service_area === area.service_area)
                                                                        var areas = this.state.areas
                                                                        return (
                                                                            <tr className='py-1'>
                                                                                <td className='py-1'>{' '}</td>
                                                                                <td className='py-1'>
                                                                                    <FormCheckbox
                                                                                        id={area.service_area}
                                                                                        checked={!!checked}
                                                                                        disabled={this.state.viewOnly}
                                                                                        onChange={(e) => {
                                                                                            if (checked) {
                                                                                                areas = areas.filter(x => x['service_area'] !== area['service_area'])
                                                                                            } else {
                                                                                                areas.push(area)
                                                                                            }
                                                                                            this.setState({ areas: areas })
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td className='py-1 px-3 text-uppercase'>{area.sponsor_area}</td>
                                                                                <td className='py-1 px-3'>{area.service_area}</td>
                                                                                <td className='py-1 px-3' style={{ textTransform: 'capitalize' }} colSpan={3}>{area.description}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: this.state.success ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    {this.state.loading && <p>...Submitting</p>}

                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        {this.state.success ? 'Done' : 'Cancel'}
                                                    </Button>
                                                    {!this.state.viewOnly && (<Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type='submit'
                                                    >
                                                        Submit
                                                    </Button>)}
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps) (withRouter(AgentPopup));