export function setCookie(name, value, domain) {
    const exDate = new Date();
    exDate.setDate(exDate.getDate() + 1);

    document.cookie = `${name}=${value};path=/;domain=${domain};expires=${exDate.toGMTString()};`;
}

export function removeCookie(name, domain) {
    const exDate = new Date();
    exDate.setDate(exDate.getDate() - 10);

    document.cookie = `${name}=;path=/;domain=${domain};expires=${exDate.toGMTString()};`;
}

export function getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i + 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return '';
}

export function saveSession(user) {
    if (window) {
        let domain = window.location.hostname;
        if (domain.indexOf('.') > -1) {
            domain = window.location.hostname.substr(domain.indexOf('.'));
        }

        setCookie('uuid', user.uuid, domain);
        window.localStorage.setItem('uuid', user.uuid);
    }
}

export function clearSession() {
    if (window) {
        let domain = window.location.hostname;
        if (domain.indexOf('.') > -1) {
            domain = window.location.hostname.substr(domain.indexOf('.'));
        }

        removeCookie('uuid', domain);
        window.localStorage.clear();
    }
}
