import React from "react";
import { Container, Row, Col, Card, Alert } from "shards-react";
import Loader from '../components/common/Loader';
import moment from 'moment-timezone';
import JobActions from '../components/libs/jobs/JobActions';
import JobTable from '../components/libs/jobs/JobTable';
import MultiUpdatePopup from '../components/libs/MultiUpdatePopup';
import ImportPopup from '../components/libs/ImportPopup';
import ExportPopup from '../components/libs/ExportPopup';
import { colors } from '../utils/colors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch_jobs, fetch_agents } from '../store/action';
import { fetchDownload } from '../utils/api';
import { createRequestParams } from "../utils/functions";

class Jobs extends React.Component {
    state = {
        jobs: [],
        pagination: {},
        loading: true,
        exportMessage: null,
        search: null,
        status: null,
        startAt: null,
        endAt: null,
        appointmentAt: null,
        invoiceState: null,
        internalState: null,
        jobType: null,
        area: null,
        insureType: null,
        page: 1,
        selected: [],
        refresh: false,
        showPopup: false,
        showExportPopup: false,
        showUpdatePopup: false,
        showAddWalkIns: false,
        retrieveAll: false,
        isArchived : false

    }

    componentDidMount() {
        if (this.props.fetch_agents) {
            this.props.fetch_agents()
        }

        if (this.props.location.state && this.props.location.state.clear) {
            this.onClearAll()
        }

        if (this.props.location.search) {
            const params = this.props.location.search.substring(1).split('&')
            const state = this.props.location.state ? this.props.location.state.currentState || {} : {}
            params.forEach(param => {
                state[param.split('=')[0]] = param.split('=')[1]
            })
            this.setState(state)
            state['isArchived'] = false
            this.props.fetch_jobs(state, state.page || 1)
        } else {
            const state = this.props.location.state ? this.props.location.state.currentState || {} : {}
            this.setState(state)
            state['isArchived'] = false
            this.props.fetch_jobs(state, state.page || 1)
        }

        if (this.props.location.state) {
            if (this.props.location.state.scroll !== null) {
                if (window.document.getElementById(this.props.location.state.scroll)) {
                    setTimeout(() => {
                        const element = window.document.getElementById(this.props.location.state.scroll)
                        const top = element ? element.offsetTop - 170 : 0
                        window.scrollTo({ top: top, behavior: 'smooth' });
                    }, 1200)
                }
            }
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            jobs: props.jobs || [],
            pagination: props.pagination || {},
            loading: false
        }
    }

    onPage(next = false, prev = false) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const state = this.state
        var page = null
        if (next) {
            state['page'] = state.page + 1
            page = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
            page = state.page - 1
        }
        state['isArchived'] = false
        this.setState(state)
        delete state['jobs']
        this.props.fetch_jobs(state, state['page'])
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onClearAll() {
        this.setState({
            jobs: [],
            loading: true,
            search: null,
            status: null,
            startAt: null,
            endAt: null,
            appointmentAt: null,
            invoiceState: null,
            internalState: null,
            jobType: null,
            area: null,
            insurerType: null,
            page: 1,
            selected: [],
            refresh: true,
            message: null,
            retrieveAll: false
            
        })
        this.props.fetch_jobs()
    }

    onFilter(key, value) {
        const state = this.state
        if (typeof key === 'object') {
            state[key[0]] = value[0]
            state[key[1]] = value[1]
        } else {
            state[key] = value
        }

        state['page'] = 1

        this.setState(state)
        delete state['jobs']
        this.props.fetch_jobs(state, null)
    }

    onExport() {
        this.setState({
            showExportPopup: !this.state.showExportPopup,
        })
    }

    onExportAdminReport() {
        const params = this.state
        var api = createRequestParams('/utils/export/admin/jobs', this.state)

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Calls_Admin_${moment().format('DD-MM-YYYY')}.xlsx`
                if (params.startAt && params.endAt) {
                    name = `Export_Calls_Admin_${params.startAt}-to-${params.endAt}.xlsx`
                }
                a.download = name;
                a.click();
                this.setState({ showExportPopup: false })
            },
            (response) => {
                this.setState({ exportMessage: response.message })
            }
        );
    }

    onExportSimpleReport() {
        const params = this.state
        var api = createRequestParams('/utils/export/simple/jobs', this.state)

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Calls_Simple_${moment().format('DD-MM-YYYY')}.csv`
                if (params.startAt && params.endAt) {
                    name = `Export_Calls_Simple_${params.startAt}-to-${params.endAt}.csv`
                }
                a.download = name;
                a.click();
                this.setState({ showExportPopup: false })
            },
            (response) => {
                this.setState({ exportMessage: response.message })
            }
        );
    }

    onExportHistoricReport() {
        this.state.isArchived = true
        const params = this.state
        var api = createRequestParams('/utils/export/historic/jobs', this.state)

        fetchDownload(api,
            (blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var name = `Export_Calls_Historic_${moment().format('DD-MM-YYYY')}.csv`
                if (params.startAt && params.endAt) {
                    name = `Export_Calls_Historic_${params.startAt}-to-${params.endAt}.csv`
                }
                a.download = name;
                a.click();
                this.setState({ showExportPopup: false })
            },
            (response) => {
                this.setState({ exportMessage: response.message })
            }
        );
    }

    onImport() {
        this.setState({
            showPopup: !this.state.showPopup,
            jobs: this.state.jobs
        })
        this.props.fetch_jobs({})
    }

    onSuccess() {
        this.setState({
            showPopup: false,
            showUpdatePopup: false,
            selected: [],
            refresh: true
        })
        this.props.fetch_jobs({})
    }

    render() {
        const dates = {
            priorityEndAt: this.state.priorityEndAt,
            priorityStartAt: this.state.priorityStartAt,
            endAt: this.state.endAt,
            startAt: this.state.startAt,
        }

        const currentState = {
            ...this.state
        }
        delete currentState['jobs']
        delete currentState['loading']
        delete currentState['showPopup']
        delete currentState['showUpdatePopup']
        delete currentState['user']

        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                <JobActions
                    onSearch={search => this.onFilter('search', search)}
                    onFilterStatus={filter => this.onFilter('status', filter)}
                    onStartAt={startAt => this.onFilter('startAt', startAt)}
                    onEndAt={endAt => this.onFilter('endAt', endAt)}
                    onAppointmentAt={apptAt => this.onFilter('appointmentAt', apptAt)}
                    onFilterService={area => this.onFilter('service', area)}
                    onFilterInsurerType={insureType => this.onFilter('insureType', insureType)}
                    onFilterSponsor={area => {
                        const sponsors = []
                        sponsors.push(area)
                        this.setState({ sponsors: sponsors })
                        this.onFilter('sponsor', area)
                    }}
                    onFilterAgent={agent => {
                        if (agent && agent.value) {
                            this.setState({ areas: agent.value['areas'], sponsors: agent.value['sponsors'] })
                            this.onFilter('agent', agent.value['uuid'])
                        } else {
                            this.onFilter('agent', null)
                        }
                    }}
                    onFilterPriority={priority => this.onFilter(priority.key, priority.value)}
                    onExport={() => this.onExport()}
                    onImport={() => this.onImport()}
                    onAddWalkIn={() => this.setState({ showAddWalkIns: true })}
                    onUpdateMultiple={() => this.setState({ showUpdatePopup: true })}
                    onClearAll={() => this.props.history.push('/refresh-jobs', { clear: true })}
                    selected={this.state.selected}
                    dates={dates}
                    currentState={currentState}
                    areas={this.state.areas}
                    insurerTypes={this.state.insurerTypes}
                    sponsors={this.state.sponsors}
                    refresh={this.state.refresh}
                    length={this.state.jobs.length}
                />
                <Row className='mx-0'>
                    {!this.state.loading && (
                        <Col>
                            <div className='job-table'>
                                <Card small className="mb-4" style={{ position: 'relative' }}>
                                    {this.state.message && (
                                        <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                            {this.state.message}
                                        </Alert>
                                    )}
                                    {this.state.successMessage && (
                                        <Alert style={{ backgroundColor: colors.GREEN, color: '#ffffff' }}>
                                            {this.state.successMessage}
                                        </Alert>
                                    )}
                                    <JobTable
                                        onSelected={(selected) => this.setState({ selected: selected })}
                                        data={this.state.jobs}
                                        onPrev={() => this.onPage(false, true)}
                                        onNext={() => this.onPage(true)}
                                        page={this.state.page}
                                        currentState={currentState}
                                        count={this.state.pagination.count}
                                        refresh={this.state.refresh}
                                        scroll={this.props.location.state ? this.props.location.state.scroll : null}
                                    />
                                </Card>
                            </div>
                        </Col>
                    )}
                    {this.state.showUpdatePopup && <MultiUpdatePopup selected={this.state.selected} onCancel={() => this.setState({ showUpdatePopup: false })} onSuccess={() => this.onSuccess()} />}
                    {this.state.showPopup && <ImportPopup onCancel={() => this.onImport()} onSuccess={() => this.onSuccess()} />}
                    {this.state.showExportPopup && <ExportPopup
                        exportMessage={this.state.exportMessage}
                        startDate={this.state.startAt}
                        endDate={this.state.endAt}
                        onCancel={() => this.setState({ showExportPopup: false })}
                        onExportAdminReport={() => this.onExportAdminReport()}
                        onExportSimpleReport={() => this.onExportSimpleReport()}
                        onExportHistoricReport={() => this.onExportHistoricReport()}
                    />}
                    {this.state.loading && <Loader title='Service Calls' />}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        jobs: state.jobs,
        pagination: state.pagination
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_jobs: (params, page) => dispatch(fetch_jobs(params, page)),
        fetch_agents: () => dispatch(fetch_agents())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jobs));
