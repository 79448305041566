export function createRequestParams(api, state) {
    const params = state

    if (params.search) {
        api = api + '?search=' + params.search
    }
    if (params.status) {
        api = api.indexOf('?') > -1 ? api + '&status=' + params.status : api + '?status=' + params.status
    }
    if (params.priorityEndAt) {
        api = api.indexOf('?') > -1 ? api + '&endAt=' + params.priorityEndAt : api + '?endAt=' + params.priorityEndAt
    }
    if (params.priorityStartAt) {
        api = api.indexOf('?') > -1 ? api + '&startAt=' + params.priorityStartAt : api + '?startAt=' + params.priorityStartAt
    }

    if (params.endAt) {
        if (api.indexOf('endAt') > -1) {
            const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
            api = api.replace(replace, 'endAt=' + params.endAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
        }
    }
    if (params.startAt) {
        if (api.indexOf('startAt') > -1) {
            const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
            api = api.replace(replace, 'startAt=' + params.startAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
        }
    }
    if (params.appointmentAt) {
        if (api.indexOf('appointmentAt') > -1) {
            const replace = api.slice(api.indexOf('appointmentAt'), api.indexOf('appointmentAt') + 16)
            api = api.replace(replace, 'appointmentAt=' + params.appointmentAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&appointmentAt=' + params.appointmentAt : api + '?appointmentAt=' + params.appointmentAt
        }
    }
    if (params.area) {
        api = api.indexOf('?') > -1 ? api + '&area=' + params.area : api + '?area=' + params.area
    }
    if (params.jobType) {
        api = api.indexOf('?') > -1 ? api + '&jobType=' + params.jobType : api + '?jobType=' + params.jobType
    }
    if (params.agent) {
        api = api.indexOf('?') > -1 ? api + '&agent=' + params.agent : api + '?agent=' + params.agent
    }

    if (params.isArchived) {
        api = api.indexOf('?') > -1 ? api + '&isArchived=' + params.isArchived : api + '?isArchived=' + params.isArchived
    }

    if (state.page) {
        api = api.indexOf('?') > -1 ? api + '&page=' + state.page : api + '?page=' + state.page
    }
    if (params.insure_type) {
        api = api.indexOf('?') > -1 ? api + '&insure_type=' + params.insure_type : api + '?insure_type=' + params.insure_type
    }
    if (params.insureType) {
        var insurer_type = params.insureType.value
        api = api.indexOf('?') > -1 ? api + '&insure_type_all=' + insurer_type : api + '?insure_type_all=' + insurer_type
        
    }


    return api;
}