import React from 'react';
import {
  Dropdown,
  NavItem
} from 'shards-react';

import { connect } from 'react-redux';

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <div className='d-flex text-nowrap px-3' style={{ padding: '.625rem .625rem' }}>
          <img
            className='user-avatar rounded-circle mr-2'
            style={{ maxWidth: '2.5rem', maxHeight: '2.5rem' }}
            src={require('./../../../../assets/images/avatars/demo.png').default}
            alt='User Avatar'
          />{' '}
          <span className='d-none d-md-inline-block' style={{ marginTop: '.625rem' }}>{this.props.user ? this.props.user['full_name'] : ''}</span>
        </div>
      </NavItem>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user
  }
}

export default connect(mapStateToProps)(UserActions)
