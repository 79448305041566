import React from 'react';
import { Col, Card, CardBody, Button, ButtonToolbar, InputGroup, Row, FormInput, ButtonGroup, Alert } from 'shards-react';
import { withRouter } from 'react-router-dom';

import ServiceAreaFilter from '../ServiceAreaFilter';
import EnabledFilter from '../EnabledFilter';

class AgentActions extends React.Component {
    state = {
        open: false
    }

    toggle(title) {
        this.setState({
            open: !this.state.open,
            title: title || 'Show All'
        });
    }


    toggle(title) {
        const state = {
            showEnabledFilter: false,
            showServiceFilter: false
        }
        if (title) {
            state[title] = !this.state[title]
        }
        this.setState(state);
    }

    render() {
        return (
            <div className='job-actions'>
                <a id='download' href='#' style={{ display: 'none' }} />
                <Card small className='h-100 no-border w-100'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 px-3 bg-light'>
                            <Col sm='12' md='6' lg='8' className='mb-2 mb-sm-2'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search by name" style={{ width: '100%' }} onChange={e => this.props.onSearch(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col sm='12' md='6' lg='4' className='mb-2 mb-sm-2'>
                                <ButtonToolbar>
                                    <ButtonGroup size="sm">
                                        <Button size='sm' theme='accent'
                                            type='button'
                                            className='btn-primary border-accent py-0 ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.props.onAddAgent()}
                                        >
                                            <div className='d-flex text-white'>
                                                <span className='text-white py-0 my-0'>Create Agent</span>
                                                <div
                                                    className='d-inline-block'
                                                    style={{ marginLeft: '4px', marginTop: '-1px' }}
                                                    dangerouslySetInnerHTML={{ __html: '<i class="material-icons">add</i>' }}
                                                />
                                            </div>
                                        </Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='5' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showEnabledFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.enabled === true ? 'Show Enabled' : this.state.enabled === false ? 'Show Disabled' : 'Show All'}</span>
                                            {this.state.enabled ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <EnabledFilter visible={this.state.showEnabledFilter} onSelect={(filter) => {
                                            this.props.onFilterState(filter.value)
                                            this.setState({ showEnabledFilter: false, enabled: filter.value })
                                        }} />
                                    </div>
                                    {/* Service Area Filter */}
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showServiceFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.serviceArea || 'Service Area Filter'}</span>
                                            {this.state.serviceArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <ServiceAreaFilter visible={this.state.showServiceFilter} areas={this.state.areas} onSelect={(filter) => {
                                            this.props.onFilterService(filter.value)
                                            this.setState({ showServiceFilter: false, serviceArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>
                                </ButtonGroup>
                            </Col>
                            <Col sm='12' md='12' lg='6' className='mb-2 d-flex'>
                                <ButtonGroup className='d-inline-flex'>
                                    <div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.toggle()
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                        </Button>
                                    </div>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default withRouter(AgentActions);
