import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormInput,
    FormTextarea,
    Button,
    Alert
} from "shards-react";
import { connect } from 'react-redux';
import { colors } from '../../../utils/colors';
import { fetchPost, fetchGet } from '../../../utils/api';
import * as moment from 'moment-timezone';

class InvoicePopup extends React.Component {
    state = {
        quantity: null,
        batch: '',
        invoice: '',
        description: '',
        amount: null,
        items: []
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                items: this.props.data['invoice_items'],
                quantity: null,
                batch: '',
                invoice: '',
                description: '',
                amount: null
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                items: this.props.data['invoice_items'],
                quantity: null,
                batch: '',
                invoice: '',
                description: '',
                amount: null
            })
        }
    }

    submit(data) {
        var api = `/invoices/update/${data['uuid']}`

        fetchPost(api,
            {
                invoice_no: this.props.data['invoice_no'],
                batch_no: this.state.batch,
                invoice_items: this.state.items,
                prev_items: this.props.data['invoice_items']
            },
            (response) => {
                this.setState({
                    items: [],
                    quantity: null,
                    batch: '',
                    invoice: '',
                    description: '',
                    amount: null
                })
                this.props.onSuccess()
            },
            (response) => {
                this.setState({ message: response.message, loading: false })
            }
        )
    }

    render() {
        const data = this.props.data || {}
        const hasItems = this.state.items && this.state.items.length > 0

        return (
            <Container fluid className='popup'>
                <Row>
                    <Col
                        className="main-content p-0"
                        sm="12"
                        md='12'
                        tag="main"
                    >
                        <div className='popup-block'>
                            <Row>
                                <Col className="mb-4">
                                    <Card small className="mb-4">
                                        <CardBody className="p-4 pb-3">
                                            <Col
                                                sm='12' md='12'
                                            >
                                                <h4><strong className='text-muted d-block mb-2'>Job No. {data['job_no']}</strong></h4>
                                                <Form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault()
                                                        this.submit(data)
                                                    }}
                                                >
                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Invoice No.</label>
                                                            <FormInput
                                                                id='invoiceNo'
                                                                placeholder='Invoice No.'
                                                                value={this.state.invoice || data['invoice_no']}
                                                                onChange={(e) => this.setState({ invoice: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Batch No.</label>
                                                            <FormInput
                                                                id='batchNo'
                                                                placeholder='Batch No.'
                                                                value={this.state.batch || data['batch_no']}
                                                                onChange={(e) => this.setState({ batch: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row form>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Generated At</label>
                                                            <FormInput
                                                                id='generated'
                                                                placeholder='Generated At'
                                                                value={moment(data['created_at']).format('YYYY-MM-DD HH:mm')}
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Col md='6' className='form-group'>
                                                            <label htmlFor='feAddress'>Submitted At</label>
                                                            <FormInput
                                                                id='submitted'
                                                                placeholder='Submitted At'
                                                                value={data['submitted_at'] ? moment(data['submitted_at']).format('YYYY-MM-DD HH:mm') : '--'}
                                                                disabled
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='notes'>Job Completion Description</label>
                                                            <FormTextarea disabled={true} placeholder='Completion Description' value={data['completion_description']} rows='3' />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <p style={{ fontSize: '16px' }}>Add Additional Items</p>
                                                    <Row form>
                                                        <Col md='12' className='form-group'>
                                                            <label htmlFor='feAddress'>Description</label>
                                                            <FormInput
                                                                id='description'
                                                                placeholder='Description'
                                                                value={this.state.description}
                                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col md='5' className='form-group'>
                                                            <label htmlFor='feAddress'>Quantity</label>
                                                            <FormInput
                                                                id='quantity'
                                                                type='number'
                                                                min="1"
                                                                placeholder='Quantity'
                                                                value={this.state.quantity}
                                                                pattern="[1-100]"
                                                                onChange={(e) => this.setState({ quantity: e.target.value || 0 })}
                                                            />
                                                        </Col>
                                                        <Col md='5' className='form-group'>
                                                            <label htmlFor='feAddress'>Unit Price</label>
                                                            <FormInput
                                                                id='amount'
                                                                min="1"
                                                                type='number'
                                                                placeholder='Unit Price'
                                                                pattern="[1-500]"
                                                                value={this.state.amount}
                                                                onChange={(e) => this.setState({ amount: e.target.value || 0 })}
                                                            />
                                                        </Col>
                                                        <Col md='2' className='form-group' style={{ paddingTop: '28px' }}>
                                                            <Button
                                                                size='md'
                                                                className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                                type='button'
                                                                onClick={() => {
                                                                    if (this.state.description && this.state.amount && this.state.quantity) {
                                                                        var list = this.state.items || []
                                                                        list.push({
                                                                            description: this.state.description,
                                                                            amount: this.state.amount,
                                                                            quantity: this.state.quantity
                                                                        })
                                                                        this.setState({
                                                                            items: list,
                                                                            description: '',
                                                                            amount: '',
                                                                            quantity: ''
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            message: 'Description, quantity and unit price required'
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                Add
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        {hasItems && (
                                                            <Col md='12' className='form-group'>
                                                                <div style={{ fontSize: '16px', lineHeight: '20px' }}>Additional Invoice Items</div>
                                                                <table>
                                                                    <tbody>
                                                                        {this.state.items.map((item, index) => {
                                                                            return (
                                                                                <tr className='py-1' key={index}>
                                                                                    <td className='py-1 px-2'>
                                                                                        {item.quantity}
                                                                                    </td>
                                                                                    <td className='py-1 px-5' style={{ textTransform: 'capitalize' }}>{item.description}</td>
                                                                                    <td className='py-1 px-5 text-right'>R {item.amount}</td>
                                                                                    <td className='py-1 px-3 text-right'>
                                                                                        <Button
                                                                                            size='md'
                                                                                            className='btn-danger'
                                                                                            type='button'
                                                                                            onClick={() => {
                                                                                                var list = this.state.items
                                                                                                list.splice(index, 1);
                                                                                                this.setState({
                                                                                                    items: list
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            Remove
                                                                                        </Button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        )}
                                                    </Row>

                                                    {this.state.message && (
                                                        <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                    <Button
                                                        size='md'
                                                        className='btn-white mr-3 mt-3 mt-sm-0'
                                                        onClick={() => this.props.onCancel()}
                                                        type='button'
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size='md'
                                                        className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                                        type='submit'
                                                    >
                                                        Update Invoice
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect()(InvoicePopup);
