import React from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    ButtonGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    FormFeedback,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DatePicker,
    Button,
    Badge,
    Alert
} from 'shards-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { fetchPost, fetchGet } from '../../../utils/api';
import { colors } from '../../../utils/colors';

class JobDetails extends React.Component {
    state = {
        disabled: false,
        agent: '',
        state: '',
        customer: null,
        user: null,
        noteRequired: false,
        agents: [],
        appointmentDate: null,
        submitting: false,
        serviceArea: '',
        sponsorArea: '',
        notes: [],
        areas: [],
        rate: '',
        serialNo: '',
        model: '',
        serviceRate: '',
        additionalLine: '',
        stateNotes: null,
        serialChanged: false,
        showClose: false,
        history: [],
        options: [],
        dateOfPurchase: null,
        selectedDateOfPurchase: null,
        isAppointmentChange: false,
        notAllocated: false
    }

    async componentDidMount() {
        fetchGet(`/jobs/notes/${this.props.data['job_no']}`,
            (response) => {
                this.setState({ notes: response || [] })
            },
            (response) => {
            }
        )
        fetchGet(`/jobs/fetch_history/${this.props.data['job_no']}`,
            (response) => {
                this.setState({ history: response || [] })
            },
            (response) => {
            }
        )
        this.fetchAgentOptions()
        if (this.props.data) {
            if (this.props.data['rate']) {
                this.setState({
                    rate: this.props.data['rate']['uuid']
                })
            }
            if (this.props.data['customer']) {
                this.setState({
                    customer: this.props.data['customer']
                })
            }
            if (this.props.data['agent']) {
                this.setState({
                    agent: this.props.data['agent']['uuid'] || this.props.data['agent']
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                agent: '',
                state: '',
                user: null,
                agents: [],
                appointmentDate: null,
                serviceArea: '',
                sponsorArea: '',
                notes: [],
                rate: '',
                serialNo: '',
                serviceRate: '',
                additionalLine: '',
                history: [],
                options: [],
                customer: null
            })
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            agents: props.agents || []
        }
    }

    handleStartDateChange(value) {
        this.setState({
            isAppointmentChange: true,
            startDate: value,
            startTime: null
        });
    }

    handleStartTimeChange(value) {
        this.setState({
            isAppointmentChange: true,
            startTime: moment(this.state.startDate).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format()
        });
    }

    handleSelctedDateOfPurchaseChange(value) {
        this.setState({
            selectedDateOfPurchase: moment(value).tz('Africa/Johannesburg').add(value.getHours(), 'hours').add(value.getMinutes(), 'minutes').format()
        });
    }

    fetchAgentOptions() {
        var agents = []
        var serviceAreas = {}
        const areas = this.props.data ? this.props.data['areas'] : []
        this.state.agents.forEach(agent => {
            var agentServiceAreas = []
            if (!agent['deleted']) {
                var inArea = false
                agent['areas'].forEach(agentInArea => {
                    if (agentInArea['sponsor'].toUpperCase() === areas[0].sponsor.toUpperCase()) {
                        inArea = true
                    }
                })
                if (inArea) {
                    agents.push({
                        label: `${agent['full_name']}`,
                        value: agent['uuid']
                    })
                    agent['areas'].forEach(agentInArea => {
                        if (areas[0].sponsor.toUpperCase() === agentInArea['sponsor'].toUpperCase()) {
                            agentServiceAreas.push({
                                code: agentInArea['code'],
                                label: `${agentInArea['code'].toUpperCase()} ${agentInArea['description']}`,
                                value: JSON.stringify({ uuid: agent['uuid'], rate: agentInArea['rate_code'], amount: agentInArea['amount'], code: agentInArea['code'], description: agentInArea['description'] })
                            })
                        }
                    })
                    serviceAreas[agent['uuid']] = agentServiceAreas
                }
            }
        })

        if (agents.length === 0) {
            agents.push({ label: 'None Available', value: null })
        }

        this.setState({
            options: agents,
            areas: serviceAreas
        })
    }

    fetchUpdateStates() {

        var states = []
        if (this.props.user && this.props.user.sponsor_name === 'ARC') {
            states = [
                { label: 'Allocated to ARC', value: 'allocated_to_arc' },
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                { label: 'Refer to Management', value: 'refer_to_management' },
                { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                { label: 'Completed', value: 'completed' },
                { label: 'Cancelled', value: 'cancelled' },
            ]

            if (this.props.data && this.props.data['state'] === 'allocated_to_arc') {
                states = [
                    { label: 'Allocated to ARC', value: 'allocated_to_arc' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_not_ordered') {
                states = [
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_ordered') {
                states = [
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }


            if (this.props.data && this.props.data['state'] === 'awaiting_parts_refer_to_management') {
                states = [
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }


            if (this.props.data && this.props.data['state'] === 'pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'not_pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'quotation') {
                states = [
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Allocated to ARC', value: 'allocated_to_arc' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }
            if (this.props.data && this.props.data['state'] === 'technical_report') {
                states = [
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Allocated to ARC', value: 'allocated_to_arc' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Management)', value: 'awaiting_parts_refer_to_management' },
                    { label: 'Refer to Management', value: 'refer_to_management' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Pickup for Workshop picked up', value: 'pickup_for_workshop' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

        } else {
            states = [
                { label: 'Allocated to ASC', value: 'allocated_to_asc' },
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                { label: 'Refer to Defy', value: 'refer_to_defy' },
                { label: 'Unable to Contact', value: 'unable_to_contact' },
                { label: 'Completed', value: 'completed' },
                { label: 'Cancelled', value: 'cancelled' },
            ]

            if (this.props.data && this.props.data['state'] === 'allocated_to_asc') {
                states = [
                    { label: 'Allocated to ASC', value: 'allocated_to_asc' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'refer_to_defy') {
                states = [
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'not_pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'pickup_for_workshop') {
                states = [
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_not_ordered') {
                states = [
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_ordered') {
                states = [
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'awaiting_parts_refer_to_defy') {
                states = [
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'In Hand', value: 'in_hand_by_agent' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'quotation') {
                states = [
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

            if (this.props.data && this.props.data['state'] === 'technical_report') {
                states = [
                    { label: 'Technical Report', value: 'technical_report' },
                    { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                    { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                    { label: 'Awaiting Parts (Refer To Defy)', value: 'awaiting_parts_refer_to_defy' },
                    { label: 'Pickup for Workshop', value: 'pickup_for_workshop' },
                    { label: 'Refer to Defy', value: 'refer_to_defy' },
                    { label: 'Unable to Contact', value: 'unable_to_contact' },
                    { label: 'Quotation', value: 'quotation' },
                    { label: 'Pickup for Workshop not picked up', value: 'not_pickup_for_workshop' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Cancelled', value: 'cancelled' },
                ]
            }

        }


        if (this.props.data && this.props.data['state'] === 'closed') {
            states = [
                { label: 'Closed', value: 'closed' },
            ]
        }

        return states.map(i => {
            return <option value={i.value}>{i.label}</option>
        })
    }

    onAddNote(message = '') {
        if (this.state.note) {
            fetchPost(`/jobs/add_note/${this.props.data.job_no}`,
                {
                    "note": message + this.state.note,
                    "user": this.state.user.uuid,
                    "agent": (this.props.data || {}).agent || null
                },
                (response) => {
                    this.setState({
                        messageNote: 'Note has been added',
                        message: null,
                        passed: true,
                        disabled: false,
                        notes: response || [],
                        note: '',
                        isAppointmentChange: false
                    })
                },
                (response) => {
                    this.setState({
                        message: response.message
                    })
                }
            )
        } else {
            this.setState({
                message: 'Note has not been added'
            })
        }
    }

    fetchColour(key) {
        switch (key) {
            case 'closed':
            case 'completed':
                return { background: '#81A94F' }
            case 'cancelled':
                return { background: '#DD5757' }
            case 'in_hand_by_agent':
                return { background: '#EBB748' }

            case 'awaiting_parts':
            case 'awaiting_parts_refer_to_defy':
            case 'awaiting_parts_refer_to_defy':
            case 'awaiting_parts_refer_to_defy':
            case 'refer_to_defy':
            case 'pickup_for_workshop':
                return { background: '#818EA3' }
            case '':
                return { background: 'rgb(0,123,255)' }
            case 'assigned_to_asc':
                return { background: '#DDDFE1' }
            case 'assigned_to_arc':
                return { background: '#DDDFE1' }
            default:
                return { color: '#495057', background: 'white', borderColor: '#e1e5eb' }
        }
    }

    renderAgentOptions() {
        return this.state.options.map((agent, i) => {
            const value = agent['value']
            const label = agent['label']

            return <option key={`${i}-${moment().format('ssSSSS')}`} value={value}>{label}</option>
        })
    }

    renderAgentAreaOptions() {
        var agent = this.props.data ? this.props.data['agent'] : []
        var serviceArea = this.props.data ? this.props.data['service_area'] : []
        if (agent && typeof agent === 'object') {
            agent = agent['uuid']
        }
        if (this.state.agent) {
            agent = this.state.agent
        }
        if (this.state.areas && agent && this.state.areas[agent]) {
            const areas = this.state.areas[agent].map((area, i) => {
                const value = area['value']
                const label = area['label']
                return <option key={`${i}-${moment().format('ssSSSS')}`} value={value}>{label}</option>
            })

            if (serviceArea) {
                this.state.areas[agent].forEach((area, i) => {
                    const sa = JSON.parse(area.value).code
                    if (sa === serviceArea) {
                        // areas.unshift(<option key={`${i}-${moment().format('ssSSSS')}`} value={area['value']}>{area['label']}</option>)
                    }
                })
            } else {
                areas.unshift(<option value=''>---</option>)
            }

            return areas
        }
        return <option value=''>---</option>
    }

    renderHistory() {
        return (
            <div className='bg-light p-3 card-small'>
                <div className='px-2 py-1'>
                    Status History
                </div>
                <div className='px-0'>
                    {this.state.history.map((item, i) => {
                        const state = item['state_to'] === 'allocated_to_asc' ? 'Allocated to ASC' : item['state_to']

                        const diff = Math.abs(moment.duration(moment().diff(moment(item['awaiting_parts_at']))).asDays())
                        const timer = <Badge theme="secondary" className='mr-2'>Day {parseInt(diff)}</Badge>

                        var showAwaitingPartsTimer = false
                        if (this.state.history.length - 1 === i) {
                            showAwaitingPartsTimer = item['state_to'] === 'awaiting_parts_ordered' || item['state_to'] === 'awaiting_parts_refer_to_defy'
                        }

                        return (
                            <div className='py-1 d-flex-to-block' key={item.uuid} style={{ fontSize: '0.8rem' }}>
                                <div className='px-2' style={{ minWidth: '130px' }}>
                                    <span>{moment(item['created_at']).format('YYYY-MM-DD HH:mm')}</span>{'  '}
                                </div>
                                <div className='px-2' style={{ minWidth: '198px', fontWeight: 500 }}>
                                    <span className='text-capitalize'>{state.replace(/_/g, ' ')}</span>
                                </div>
                                <div className='px-2'>
                                    <span>{item['updated_by_name']}</span>
                                </div>
                                {showAwaitingPartsTimer && (
                                    <div className='px-2'>
                                        {timer}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderNotes() {
        return this.state.notes.map((note, i) => {
            if (note.user) {
                return (
                    <div key={i}>
                        <Alert style={{ borderRadius: '2%', background: '#EEF3F6', color: '#3d5170' }}>
                            {note.note}<br /><b>{note.user['first_name']}{': '}{moment(note['created_at']).format('YYYY-MM-DD HH:mm')}</b>
                        </Alert>
                    </div>
                )
            }
            if (note.agent) {
                return (
                    <div key={i}>
                        <Alert style={{ borderRadius: '2%', background: colors.LIGHT_YELLOW, color: '#3d5170' }}>
                            {note.note}<br /><b>{note.agent['full_name']}{': '}{moment(note['created_at']).format('YYYY-MM-DD HH:mm')}</b>
                        </Alert>
                    </div>
                )
            }
            return undefined
        })
    }

    onGenerateInvoice(uuid) {
        var api = `/invoices/regenerate/${uuid}`

        fetchGet(api,
            (response) => {
                console.info('Invoice generated')
            },
            (response) => {
                console.info('Invoice could not be generated')
            }
        )
    }

    submit(data) {
        const isCancelling = this.state.state === 'cancelled'
        const notes = data['notes'] || this.state.stateNotes || {}
        var oldAppointment = data['appointment_at']
        const appointmentChanged = this.state.startTime && oldAppointment && oldAppointment !== this.state.startTime
        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }
        if(data['state'] === 'allocated_to_asc' || data['state'] === 'allocated_to_arc'){
            this.state.notAllocated = true
        }
        if ((!this.state.agent && !data['agent']) && !isCancelling) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Select an agent'
            })
        } else if (this.state.state !== 'in_hand_by_agent' && data['allocated_to_asc']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'You can only update this state to "In Hand"'
            })
        } else if (!this.state.serviceArea && !data['service_area'] && !isCancelling) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Select a service area'
            })
        } else if (this.state.state === 'assigned_to_agent' && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Changing the state to "Assigned" requires an appointment date. Please enter an appointment date and time'
            })
        } else if (this.state.state === 'in_hand_by_agent' && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Changing the state to "In Hand" requires an appointment date. Please enter an appointment date and time'
            })
        } else if (this.state.state === 'completed' && (this.state.additionalLine === 'spaces' || !this.state.additionalLine)) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must select yes or no for Additional Line Items'
            })
        } else if (this.state.state === 'completed' && !notes['complete_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Job Completion Note'
            })
        } else if (this.state.state === 'awaiting_parts_not_ordered' && !notes['awaiting_parts_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Awaiting Parts Note and indicate which part is required'
            })
        } else if (this.state.state === 'refer_to_defy' && !notes['refer_to_defy_note']) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'To complete this call you must add a Refer to Defy Note'
            })
        } else if (this.state.startDate && !this.state.startTime) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Select a date AND time'
            })
        } else if ((this.state.startDate && this.state.selectedDateOfPurchase) || (appointmentDate && this.state.selectedDateOfPurchase)
            && (moment(this.state.selectedDateOfPurchase).format('YYYY-MM-DD') > moment(this.state.startDate).format('YYYY-MM-DD'))) {
            this.setState({
                warn: true,
                submitting: false,
                message: 'Date of purchase can not be greater than the appointment date'
            })
        } else if (this.state.isAppointmentChange && !this.state.notAllocated) {
            this.setState({
                warn: true,
                noteRequired: true,
                disabled: true,
                submitting: false,
                isAppointmentChange: false,
                message: `Please indicate why the appointment change in the 'Internal Note' section below`
            })
        } else {
            this.setState({ passed: true, message: 'Updating', disabled: true })
            if (appointmentChanged) {
                const message = `Appointment Changed from ${moment(oldAppointment).format('YYYY-MM-DD HH:mm')}: Reason: `
                this.onAddNote(message)
            }

            const serialNo = this.state.serialNo && this.state.serialNo !== '' ? this.state.serialNo : data['serial_no']

            var api = `/jobs/update/${data['uuid']}`
            var agent = this.state.agent || null
            if (data['agent'] && typeof data['agent'] === 'object') {
                agent = this.state.agent ? this.state.agent : data['agent']['uuid']
            }

            var rate = this.state.rate || null
            if (data['rate'] && typeof data['rate'] === 'object') {
                rate = this.state.rate ? this.state.rate : data['rate']['uuid']
            }

            var hours = this.state.hours ? this.state.hours : 0;
            var minutes = this.state.minutes ? this.state.minutes : 0;

            var request = {
                agent: agent,
                rate: rate,
                "appointment": this.state.startTime,
                "old_appointment": oldAppointment,
                "contact_mobile": data['customer']['contact_mobile'] || data['customer']['contact_home'] || data['customer']['contact_work'],
                "job_no": data['job_no'],
                "job_type": data['job_type'],
                "state_from": data['state'],
                "customer": this.state.customer,
                "original_job_no": this.state.original_job_no || data['original_job_no'],
                "state": this.state.state,
                "internal_state": this.state.internalState,
                "job_origin": this.state.jobOrigin || data['job_origin'],
                "serial": serialNo,
                "model": this.state.model || data['model'],
                "service_area": this.state.serviceArea || data['service_area'],
                "additional_line_item": this.state.additionalLine,
                notes: this.state.stateNotes,
                "no_of_hours": hours + ':' + minutes,
                "date_of_purchase": this.state.selectedDateOfPurchase
            }

            fetchPost(api,
                request
                ,
                (response) => {
                    this.setState({ message: null, disabled: false })
                    this.props.onBack()
                },
                (response) => {
                    this.setState({ message: response.message, loading: false, disabled: false, isContinue: true })
                }
            )
        }
    }

    render() {

        const data = this.props.data || {}

        var agentUUID = ''
        if (data && data['agent'] && data['agent']['uuid']) {
            agentUUID = data['agent']['uuid']
        }

        var selectedArea = ''
        var agent = this.state.agent || null
        if (agent && typeof agent === 'object') {
            agent = agent['uuid']
        }
        if (this.state.areas && this.state.areas.length > 0 && data['service_area']) {
            const uuid = agent || agentUUID
            selectedArea = this.state.areas[uuid].find(option => {
                return option['code'] === data['service_area']
            })
            selectedArea = selectedArea.value
        }

        var serviceRate = ''
        if (data['rate'] && data['rate'] !== null && typeof data['rate'] === 'object') {
            serviceRate = `${data['rate'].code.toUpperCase()}: R ${data['rate'].amount}`
        }
        if (data['job_type'] === 'cash_call') {
            serviceRate = `CASH: R 630.00`
        }

        var sponsorArea = ''
        if (data['service_area']) {
            const area = data['areas'].find(x => x['code'] === data['service_area'])
            sponsorArea = `${area['code']} - ${area['description']}`
        } else {
            sponsorArea = `${data['sponsor_area']} ${data['areas'].map(area => area['code'])}`
        }

        var dateOfPurchase = data['date_of_purchase'] ? moment(data['date_of_purchase']).format('DD MMM YYYY') : ''
        if (this.state.selectedDateOfPurchase) {
            dateOfPurchase = moment(this.state.selectedDateOfPurchase).format('DD MMM YYYY')
        }

        var appointmentDate = data['appointment_at'] ? moment(data['appointment_at']).format('DD MMM YYYY') : ''
        if (this.state.startDate) {
            appointmentDate = moment(this.state.startDate).format('DD MMM YYYY')
        }

        var appointmentTime = data['appointment_at'] ? moment(data['appointment_at']).format('HH:mm') : ''
        if (this.state.startTime) {
            appointmentTime = moment(this.state.startTime).format('HH:mm')
        }

        const isToday = (someDate) => {
            const today = new Date()
            return someDate.getDate() === today.getDate() &&
                someDate.getMonth() === today.getMonth() &&
                someDate.getFullYear() === today.getFullYear()
        }

        var minStartTime = new Date().setHours(7, 0, 0)
        if (this.state.startDate && isToday(this.state.startDate)) {
            const curHour = new Date().getHours() + 1
            minStartTime = new Date().setHours(curHour, 0, 0)
        }

        var isDisabled = data.state === 'completed' || data.state === 'closed' || data.state === 'cancelled'
        const disabledNotCompleted = this.state.state !== 'completed' && this.state.state !== 'closed'

        var isCancelledDisabled = true
        var serialRequired = ['awaiting_parts', 'awaiting_parts_not_ordered', 'pickup_for_workshop', 'refer_to_defy', 'completed'].indexOf(this.state.state) > -1
        if (data['job_type'] === 'cash_call') {
            serialRequired = false
        }

        if (this.state.state === 'cancelled') {
            isCancelledDisabled = false
            serialRequired = false
            isDisabled = true
        }

        var modelNo = this.state.model || ''
        if (!this.state.model) {
            modelNo = this.state.modelCleared ? '' : data['model']
        }

        const isArchived = data['is_archived'] || false
        const isReferToDefy = this.state.state === 'refer_to_defy' || data['state'] === 'refer_to_defy' || false

        var disableAwaitingParts = isDisabled
        if (this.state.state === 'awaiting_parts_not_ordered' || data['state'] === 'awaiting_parts_not_ordered') {
            disableAwaitingParts = false
        } else {
            disableAwaitingParts = true
        }

        const canManualClose = data['state'] === 'in_hand_by_agent' || data['state'] === 'allocated_to_asc' || false
        const canRevert = (data['state'] === 'completed' && !isArchived) || (data['state'] === 'cancelled' && !isArchived) || data['state'] === 'closed'

        var btnSubmitLabel = this.state.isContinue ? 'Continue' : 'Update Call'

        var customer = this.state.customer || data['customer'] || {}
        var notes = this.state.stateNotes || data['notes'] || {}

        return (
            <div>
                <Card small className='mb-4'>
                    <CardHeader className='border-bottom'>
                        <Row>
                            <Col md='6'><h6 className='m-0'>Call Details</h6></Col>
                            <Col md='6'>
                                <ButtonGroup style={{ float: 'right' }}>
                                    {canRevert && (<Button theme='warning' style={{ color: '#ffffff' }} type='button' onClick={() => this.props.onShowReneratePopup()}>Regenerate Invoice</Button>)}
                                    {canManualClose && (<Button theme='warning' style={{ color: '#ffffff' }} type='button' onClick={() => this.props.onShowPopup()}>Manually Close</Button>)}
                                    {canRevert && (<Button theme='danger' style={{ color: '#ffffff' }} type='button' onClick={() => this.props.onShowRevertPopup()}>Revert In-Hand</Button>)}
                                    <Button theme='secondary' type='button' onClick={() => this.props.onBack()}> Go Back</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className='p-3'>
                            <Row>
                                <Col>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            if (this.state.isContinue) {
                                                this.props.onBack()
                                            } else if (this.state.serialChanged && serialRequired && !this.state.serialNo) {
                                                this.setState({
                                                    warn: true,
                                                    message: 'Serial number has changed, please ensure you enter it'
                                                })
                                            } else {
                                                this.submit({
                                                    submitting: true,
                                                    customer: customer,
                                                    ...data,
                                                    notes: this.state.stateNotes
                                                })
                                            }
                                        }}
                                    >
                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='job_no'>Job No.</label>
                                                <FormInput
                                                    id='job_no'
                                                    placeholder='Autogenerated'
                                                    disabled
                                                    value={data['job_no']}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='status'>Status</label>
                                                <FormSelect
                                                    style={{ backgroundColor: this.fetchColour(this.state.state || data['state']).background, color: this.fetchColour(this.state.state || data['state']).color || 'white' }}
                                                    id='state' invalid={this.state.statusInvalid}
                                                    value={this.state.state || data['state']}
                                                    disabled={isDisabled}
                                                    onChange={(e) => this.setState({ state: e.target.value })}
                                                >
                                                    {this.fetchUpdateStates()}
                                                </FormSelect>
                                                {this.state.statusInvalid && <FormFeedback invalid>Call status has not been updated</FormFeedback>}
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='feAddress'>Additional Line Items</label>
                                                <FormSelect
                                                    id='additional_line_item'
                                                    value={this.state.additionalLine || data['additional_line_item']}
                                                    disabled={isDisabled || disabledNotCompleted}
                                                    onChange={(e) => this.setState({ additionalLine: e.target.value })}
                                                >
                                                    <option value='spaces'>Spaces</option>
                                                    <option value='no'>No</option>
                                                    <option value='yes'>Yes</option>
                                                </FormSelect>
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='feAddress'>Invoice No.</label>
                                                <FormInput
                                                    id='invoice_no'
                                                    placeholder='Generated on complete'
                                                    disabled
                                                    value={data['invoice_no']}
                                                />
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Agent</label>
                                                <FormSelect
                                                    id='agent'
                                                    value={this.state.agent || agentUUID}
                                                    required={isCancelledDisabled}
                                                    disabled={isDisabled}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            const update = {
                                                                agent: e.target.value,
                                                            }

                                                            if ((data['state'] === 'allocated_to_asc' && !this.state.state && !data['agent']) || (data['state'] === 'allocated_to_arc' && !this.state.state && !data['agent'])) {
                                                                update['state'] = 'in_hand_by_agent'
                                                            }
                                                            this.setState(update)
                                                        }
                                                    }}
                                                >
                                                    <option value=''>---</option>
                                                    {this.renderAgentOptions()}
                                                </FormSelect>
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Service Area</label>
                                                <FormSelect
                                                    id='serviceArea'
                                                    value={this.state.selected || selectedArea}
                                                    required={isCancelledDisabled}
                                                    placeholder={selectedArea}
                                                    disabled={!this.state.agent || isDisabled}
                                                    onChange={(e) => {
                                                        const value = JSON.parse(e.target.value)
                                                        if (value) {
                                                            const update = {
                                                                rate: value.rate,
                                                                selected: e.target.value,
                                                                serviceRate: `${value.rate.toUpperCase()}: R ${value.amount}`,
                                                                serviceArea: value.code,
                                                                sponsorArea: `${value.code} - ${value.description}`
                                                            }
                                                            if (data['job_type'] === 'cash_call') {
                                                                update['serviceRate'] = 'CASH: R 630.00'
                                                            }

                                                            if (data['state'] === 'allocated_to_asc' && !this.state.state && !data['agent']) {
                                                                update['state'] = 'in_hand_by_agent'
                                                            }
                                                            this.setState(update)
                                                        }
                                                    }}
                                                >
                                                    {this.renderAgentAreaOptions()}
                                                </FormSelect>
                                            </Col>
                                            <Col md='2' className='form-group'>
                                                <label htmlFor='appointment_at'>Appointment Date</label>
                                                <InputGroup className='d-flex my-auto date-range'>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={this.state.appointmentDate}
                                                        onChange={(value) => this.handleStartDateChange(value)}
                                                        minDate={new Date()}
                                                        placeholderText={this.state.startDate || 'Appointment Date'}
                                                        dropdownMode='select'
                                                        value={appointmentDate}
                                                        disabled={isDisabled}
                                                        className='text-center'
                                                    />
                                                    <InputGroupAddon type='append'>
                                                        <InputGroupText>
                                                            <i className='material-icons'>&#xE916;</i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col md='2' className='form-group'>
                                                <label htmlFor='appointment_at'>Appointment Time</label>
                                                <InputGroup className='d-flex my-auto date-range'>
                                                    <DatePicker
                                                        timeFormat='HH:mm'
                                                        selected={this.state.appointmentDate}
                                                        onChange={(value) => this.handleStartTimeChange(value)}
                                                        placeholderText={this.state.startTime || 'Appointment Time'}
                                                        dropdownMode='select'
                                                        timeIntervals={30}
                                                        minTime={minStartTime}
                                                        maxTime={new Date().setHours(19, 0, 0)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        disabled={!this.state.startDate || isDisabled}
                                                        value={appointmentTime}
                                                        className='text-center'
                                                    />
                                                    <InputGroupAddon type='append'>
                                                        <InputGroupText>
                                                            <i className='material-icons'>&#xE916;</i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>

                                            <Col md='2' className='form-group'>
                                                <label htmlFor='feAddress'>Appointment Confirmed ?</label>
                                                <FormSelect id='agentConfirmed' disabled={true} value={this.state.isAppointmentConfirmed || data['is_appointment_confirmed']}
                                                    onChange={(e) => this.setState({ isAppointmentConfirmed: e.target.value })}>
                                                    <option value='spaces'>Spaces</option>
                                                    <option value='yes'>Yes</option>
                                                    <option value='no'>No</option>
                                                </FormSelect>
                                            </Col>
                                        </Row>

                                        <hr />

                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='customer'>Customer</label>
                                                <FormInput
                                                    id='customer'
                                                    placeholder='Customer Name'
                                                    value={customer['full_name']}
                                                    onChange={(e) => {
                                                        const update = customer
                                                        update['full_name'] = e.target.value
                                                        this.setState({
                                                            customer: update
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Home No.</label>
                                                <FormInput
                                                    id='contact_home'
                                                    placeholder='Customer Home No.'
                                                    value={customer['contact_home']}
                                                    onChange={(e) => {
                                                        const update = customer
                                                        update['contact_home'] = e.target.value
                                                        this.setState({
                                                            customer: update
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Mobile No.</label>
                                                <FormInput
                                                    id='contact_mobile'
                                                    placeholder='Customer Mobile No.'
                                                    value={customer['contact_mobile']}
                                                    onChange={(e) => {
                                                        const update = customer
                                                        update['contact_mobile'] = e.target.value
                                                        this.setState({
                                                            customer: update
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Customer Work No.</label>
                                                <FormInput
                                                    id='contact_work'
                                                    placeholder='Customer Work No.'
                                                    value={customer['contact_work']}
                                                    onChange={(e) => {
                                                        const update = customer
                                                        update['contact_work'] = e.target.value
                                                        this.setState({
                                                            customer: update
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='feAddress'>Model No.</label>
                                                <FormInput
                                                    id='model'
                                                    placeholder={data['model']}
                                                    // disabled={true}
                                                    value={modelNo}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({
                                                                model: e.target.value
                                                            })
                                                        } else {
                                                            this.setState({
                                                                model: e.target.value,
                                                                modelCleared: true
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='feAddress'>Serial No.</label>
                                                <FormInput
                                                    id='model'
                                                    required={serialRequired}
                                                    placeholder='Enter serial no'
                                                    maxlength={20}
                                                    pattern="^[a-zA-Z0-9]{1,20}$"
                                                    title="No more than 20 alphanumeric characters"
                                                    disabled={isDisabled}
                                                    value={this.state.serialNo || data['serial_no'] || ''}
                                                    onChange={(e) => this.setState({
                                                        serialNo: e.target.value,
                                                        serialChanged: true
                                                    })}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='date_of_purchase'>Date Of Purchase</label>
                                                <InputGroup className='d-flex my-auto date-range'>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={this.state.date_of_purchase}
                                                        onChange={(value) => this.handleSelctedDateOfPurchaseChange(value)}
                                                        placeholderText={this.state.selectedDateOfPurchase || 'Date of Purchase'}
                                                        dropdownMode='select'
                                                        value={dateOfPurchase}
                                                        className='text-center'
                                                    />
                                                    <InputGroupAddon type='append'>
                                                        <InputGroupText>
                                                            <i className='material-icons'>&#xE916;</i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            {this.props.user.sponsor_name !== 'ARC' && (<Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Job Origin</label>
                                                <FormSelect
                                                    id='job_origin'
                                                    value={this.state.jobOrigin || data['job_origin']}
                                                    onChange={(e) => this.setState({ jobOrigin: e.target.value })}
                                                >
                                                    <option value='walk_in'>Store Walk In</option>
                                                    <option value='referral'>Defy Referral</option>
                                                </FormSelect>
                                            </Col>)}
                                        </Row>

                                        <Row form>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='issue'>Fault Note</label>
                                                <FormTextarea
                                                    disabled
                                                    placeholder='Issue'
                                                    value={data['issue']}
                                                    rows='4'
                                                />
                                            </Col>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='issue'>Special Instructions</label>
                                                <FormTextarea
                                                    disabled
                                                    placeholder='Special Instructions'
                                                    value={data['special_instructions']}
                                                    rows='4'
                                                />
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md='12' className='form-group'>
                                                <label htmlFor='feAddress'>Address</label>
                                                <FormInput
                                                    id='address'
                                                    placeholder='Address'
                                                    value={data['customer']['address']}
                                                    onChange={(e) => {
                                                        const update = customer
                                                        update['address'] = e.target.value
                                                        this.setState({
                                                            customer: update
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col md='6' className='form-group'>
                                                <label htmlFor='sponsor_area'>Service Area</label>
                                                <FormInput
                                                    id='sponsor_area'
                                                    disabled
                                                    placeholder='Service Area'
                                                    value={this.state.sponsorArea || sponsorArea || ''}
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='sponsor_area_rate'>Service Area Rate</label>
                                                <FormInput
                                                    id='rate'
                                                    disabled
                                                    placeholder='Service Area Rate'
                                                    value={this.state.serviceRate || serviceRate || ''}
                                                />
                                            </Col>
                                            {this.props.user.sponsor_name !== 'ARC' && (<Col md='3' className='form-group'>
                                                <label htmlFor='called_at'>Call Logged with Defy</label>
                                                <FormInput
                                                    id='called_at'
                                                    placeholder='Called Logged with Defy'
                                                    value={moment(data['called_at']).format('YYYY-MM-DD')}
                                                    disabled
                                                />
                                            </Col>)}
                                        </Row>

                                        <Row form>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='retailer_name'>Retailer</label>
                                                <FormInput
                                                    id='retailer_name'
                                                    placeholder='Retailer'
                                                    value={data['retailer_name']}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='retailer_contact'>Retailer Contact</label>
                                                <FormInput
                                                    id='retailer_contact'
                                                    placeholder='Retailer Contact'
                                                    value={data['retailer_contact']}
                                                    // onChange={() => { }}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md='3' className='form-group'>
                                                <label htmlFor='job_type'>Call Type</label>
                                                <FormInput
                                                    id='job_type'
                                                    placeholder='Call Type'
                                                    disabled
                                                    value={data['job_type'].replace(/_/g, ' ')}
                                                />
                                            </Col>
                                            {data['insure'] && (
                                                <Col md='3' className='form-group'>
                                                    <label htmlFor='insure'>Insurer</label>
                                                    <FormInput
                                                        id='insure'
                                                        placeholder='Insure'
                                                        disabled
                                                        value={data['insure_type'].replace(/_/g, ' ')}
                                                    />
                                                </Col>
                                            )}

                                            {this.props.user.sponsor_name !== 'ARC' && (<Col md='3' className='form-group'>
                                                <label htmlFor='feAddress'>Original Job No.</label>
                                                <FormInput
                                                    placeholder='Enter Job No if repeater'
                                                    required
                                                    disabled={isDisabled || data['job_type'] !== 'repeat_call'}
                                                    value={this.state.original_job_no || data['original_job_no'] || ''}
                                                    onChange={(e) => this.setState({
                                                        original_job_no: e.target.value
                                                    })}
                                                />
                                            </Col>)}



                                            {this.props.data.insure_type && (<Col md='3' className='form-group'>
                                                <label htmlFor='insure_type'>Insurer Type</label>
                                                {this.props.data.insure_type === 'bosch' && (
                                                    <FormInput
                                                        disabled
                                                        value='Bosch'
                                                    />
                                                )}
                                                {this.props.data.insure_type === 'insurance' && (
                                                    <FormInput
                                                        disabled
                                                        value='Insurance'
                                                    />
                                                )}
                                                {this.props.data.insure_type === 'candy_appliances' && (
                                                    <FormInput
                                                        disabled
                                                        value='Candy Appliances'
                                                    />
                                                )}
                                                {this.props.data.insure_type === 'samsung' && (
                                                    <FormInput
                                                        disabled
                                                        value='Samsung'
                                                    />
                                                )}
                                            </Col>)}
                                        </Row>
                                        <Row form>
                                            <Col md='12' className='form-group'>
                                                <label htmlFor='notes'>Awaiting Parts Note</label>
                                                <FormTextarea id='notes'
                                                    disabled={disableAwaitingParts}
                                                    placeholder='Add Awaiting Parts Note'
                                                    value={notes['awaiting_parts_note']}
                                                    rows='2'
                                                    onChange={(e) => {
                                                        const update = notes
                                                        update['awaiting_parts_note'] = e.target.value
                                                        this.setState({
                                                            stateNotes: update
                                                        })
                                                    }} />
                                            </Col>
                                            {this.props.user.sponsor_name !== 'ARC' && (<Col md='12' className='form-group'>
                                                <label htmlFor='notes'>Refer To Defy Note</label>
                                                <FormTextarea id='notes' disabled={isDisabled || !isReferToDefy} placeholder='Add Refer To Defy Note' value={notes['refer_to_defy_note']} rows='2' onChange={(e) => {
                                                    const update = notes
                                                    update['refer_to_defy_note'] = e.target.value
                                                    this.setState({
                                                        stateNotes: update
                                                    })
                                                }} />
                                            </Col>)}

                                            <Col md='12' className='form-group'>
                                                <label htmlFor='notes'>Job Completion Description</label>
                                                <FormTextarea id='notes' disabled={isDisabled || disabledNotCompleted} placeholder='Add Completion Note' value={notes['complete_note']} rows='2' onChange={(e) => {
                                                    const update = notes
                                                    update['complete_note'] = e.target.value
                                                    this.setState({
                                                        stateNotes: update
                                                    })
                                                }} />
                                            </Col>

                                            {(this.state.state === 'completed') && this.props.user && this.props.user.sponsor_name === 'ARC' && (
                                                <Row form>
                                                    <Col md='5' className='form-group'>
                                                        <label htmlFor='hours'>Hours</label>
                                                        <FormInput id='notes'
                                                            type='number'
                                                            min='0'
                                                            max='24'
                                                            placeholder='HH'
                                                            required
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    hours: e.target.value
                                                                })
                                                            }} />
                                                        <strong>{data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[0] !== '0' && data['no_of_hours'].split(':')[0] !== 'undefined' ? data['no_of_hours'].split(':')[0] : ''}</strong>
                                                    </Col>
                                                    :
                                                    <Col md='5' className='form-group'>
                                                        <label htmlFor='minutes'>Minutes</label>
                                                        <FormInput id='minutes'
                                                            type='number'
                                                            min='0'
                                                            max='60'
                                                            required
                                                            placeholder='MM'
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    minutes: e.target.value
                                                                })
                                                            }} />
                                                        <strong>{data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[1] !== '0' && data['no_of_hours'].split(':')[1] !== 'undefined' ? data['no_of_hours'].split(':')[1] : ''}</strong>
                                                    </Col>
                                                </Row>)
                                            }

                                            {data['state'] === 'completed' && this.props.user && this.props.user.sponsor_name === 'ARC' && (
                                                <Row form>
                                                    <Col md='5' className='form-group'>
                                                        <label htmlFor='hours'>Hours</label>
                                                        <FormInput id='notes'
                                                            type='number'
                                                            min='0'
                                                            max='24'
                                                            value={data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[0] !== '0' && data['no_of_hours'].split(':')[0] !== 'undefined' ? data['no_of_hours'].split(':')[0] : ''}
                                                            disabled
                                                            placeholder='HH'
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    hours: e.target.value
                                                                })
                                                            }} />
                                                    </Col>
                                                    :
                                                    <Col md='5' className='form-group'>
                                                        <label htmlFor='minutes'>Minutes</label>
                                                        <FormInput id='minutes'
                                                            type='number'
                                                            min='0'
                                                            max='60'
                                                            value={data['no_of_hours'] !== null && data['no_of_hours'] !== 0 && data['no_of_hours'].split(':')[1] !== '0' && data['no_of_hours'].split(':')[1] !== 'undefined' ? data['no_of_hours'].split(':')[1] : ''}
                                                            disabled
                                                            placeholder='MM'
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    minutes: e.target.value
                                                                })
                                                            }} />
                                                    </Col>
                                                </Row>)
                                            }
                                        </Row>
                                        <Row>
                                            <Col md='12' className='form-group'>
                                                {this.renderHistory()}
                                            </Col>
                                        </Row>
                                        {this.state.message && (
                                            <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : this.state.warn ? colors.ORANGE : colors.RED, color: '#ffffff' }}>
                                                {this.state.message}
                                            </Alert>
                                        )}
                                        <ButtonGroup className='mb-4'>
                                            <Button theme='light' type='button' style={{ float: 'right' }} onClick={() => this.props.onBack()}> Go Back</Button>

                                            {!isArchived && <Button theme='accent' disabled={this.state.disabled || this.state.submitting}>{btnSubmitLabel}</Button>}

                                        </ButtonGroup>
                                        <hr />
                                        <Row form className='mt-4'>
                                            <Col md='12' className='form-group'>
                                                <label htmlFor='notes'>(Optional) Internal Notes</label>
                                                {this.renderNotes()}
                                                <FormTextarea id='notes' placeholder='Add internal note/comment that can be viewed by admins and agents' value={this.state.note || ''} rows='5' onChange={(e) => this.setState({ note: e.target.value })} />
                                            </Col>
                                        </Row>
                                        {this.state.messageNote && (
                                            <Alert style={{ backgroundColor: this.state.passed ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                                {this.state.messageNote}
                                            </Alert>
                                        )}
                                        <ButtonGroup>
                                            <Button theme='info' type='button' onClick={() => this.onAddNote()}>Add Note</Button>
                                        </ButtonGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card >
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        agents: state.agents
    }
}

export default connect(mapStateToProps)(JobDetails);

